import React from 'react';
import {Notification} from 'tmna-types';
import {COLUMNS as NOTIFICATION_TABLE_COLUMNS, NOTIFICATIONS_CELL_PLACEHOLDER} from '../NotificationPage/ui/NotificationTable/columns';
import {Box, Typography} from '@material-ui/core';
import { TableColumn } from '@backstage/core-components';

export const COLUMNS: TableColumn<Notification>[] = [
    {
        ...NOTIFICATION_TABLE_COLUMNS[0],
        cellStyle: {
            width: '80px',
        },
    },
    NOTIFICATION_TABLE_COLUMNS[1],
    NOTIFICATION_TABLE_COLUMNS[2],
    NOTIFICATION_TABLE_COLUMNS[3],
    {
        field: 'message',
        type: 'string',
        title: 'Description',
        emptyValue: NOTIFICATIONS_CELL_PLACEHOLDER,
        cellStyle: {
            width: '400px',
        },
        render: ({message}) => (
            <Box width={400}>
                <Typography noWrap variant="body2">
                    {message}
                </Typography>
            </Box>
        )
    },
    NOTIFICATION_TABLE_COLUMNS[4],
];
import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles({

    accountDetailsContainer:{
        paddingLeft: '5px',
        marginTop : '30px',
        maxWidth : '90%',
        marginLeft: '30px'

    },
    alerts: {
        width: 'fit-content'
    },
    alertHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        fontWeight: 400,
        fontSize: '16px'
    },
    root: {
        flexGrow: 1,
        position:'sticky',
        top:0,
        zIndex:10,
        margin: '0px 0 0 0',
        "&.Mui-selected": {
            color: "red"
            }
    },
    hHeading  : {
        margin:'40px',
        marginLeft:'100px'
    },
    header :{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px'
    },
    headerCustom:{
        display: 'flex',
        marginBottom: '8px'
    },
    delButton :{
        marginLeft:"5px",
        //backgroundColor:"red"
        // backgroundColor:"red"
    },
    cardDiv: {
        marginLeft:'100px',
        marginBottom:'100px'
    },
    gridItem:{
        width:'340px', 
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: 'gray'       
    },
    grid:{
        marginBottom:'20px'
    },    
    spinner: {
        display:"flex",
        justifyContent: "center"
    },
    addFieldButton:{
        left:'140px',
        top:'-40px',
        padding:'0px',        
        color:'black',
        '&:hover':{
            color:'#EB0A1E'
        }
    },
    input:{
        top:'5px',
        right:'3px'        
    },
    accountId:{
        marginTop: '-38px',
        //marginLeft: '110px'
    },
    dataLabel:{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 'initial',
        fontSize: '14px',
        textOverflow: 'ellipsis',
        display: 'block',
        paddingLeft:'10px',
    },
    dataLabelContact:{
        maxHeight: "150px",
        overflowY: 'auto',
        whiteSpace: 'pre-wrap',
        scrollbarWidth: 'thin',
        paddingLeft:'5px',
    },
    accountType:{
        marginTop: '-38px',
        //marginLeft: '110px'
    },
    parentSupport:{
        '& .MuiButtonBase-root':{
            padding:'3px !important',
            minWidth:'unset !important',
            height:'unset !important'
        }
    },
    gridDivider: {
        //marginTop: '-100px',
      },
      divider:{

      },
      bulkIcon: {
        color: '#EB0A1E',
      },
      iconContainer: {
        width: 28,
        display: 'flex',
        color: 'red',
        alignItems: 'center',
        justifyContent: 'top',
        float: 'right',
        padding:'12px',
        marginBottom: '5px'
    },
    popper: {
        position: 'absolute',
        //top: '10%', // Adjust this value to move it down
        //right: '50%', // Move it to the center horizontally (you can adjust this value)
        //left: "50%",
        transform: 'translateX(50%)',
      },
      popperContainer: {
        bottom:'1em',
        left: '80%',
        position: 'relative',
        display: 'block', // Ensure it takes the width of its contents
      },
      downloadIcon: {
        // transform: 'Translate(-125px, 125px)',
        color: 'red',
      },
      iconAlignment: {
        marginTop: '10px'
      },
      paper: {
        backgroundColor: '#fff',
        borderRadius:4,
        marginTop: '13px',
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        '& ul':{
            listStyle:'none',
            padding: 5,
            margin: 0,
            paddingTop: 8,
            paddingBottom: 8,
            cursor: 'pointer',
            '& li':{
                fontSize: '15px',
                paddingLeft: 16,
                paddingRight: 16,
                fontWeight: 400,
                lineHeight: 1.5,
                paddingTop: 6,
                whiteSpace: 'nowrap',
                paddingBottom: 6,
                '&:hover':{
                    color: '#CC0000',
                    background: '#F7F7F7',
                }
            }
        }
      }
});

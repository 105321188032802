import { useCallback, useEffect } from 'react';
import { techdocsPlugin } from '@backstage/plugin-techdocs';
import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
  useShadowRootElements,
} from '@backstage/plugin-techdocs-react';
import { useTheme } from '@material-ui/core';

const TechDocsStyleAddon = () => {
  const heads = useShadowRootElements<HTMLHeadElement>(['head']);
  const theme = useTheme();
  const updateDOM = useCallback(async () => {
    const style = document.createElement('style');
    style.innerText = `
          /*==================  Reset  ==================*/

          body {
            --md-text-color: var(--md-default-fg-color);
            --md-text-link-color: var(--md-accent-fg-color);
            --md-text-font-family: ${theme.typography.fontFamily};
            font-family: var(--md-text-font-family);
            background-color: unset;
          }
          `;
    heads.forEach(head => head.appendChild(style));
  }, []);

  useEffect(() => {
    updateDOM();
  }, [updateDOM]);

  return null;
};

export const TechDocsStyle = techdocsPlugin.provide(
  createTechDocsAddonExtension({
    name: 'TechDocsStyle',
    location: TechDocsAddonLocations.Content,
    component: TechDocsStyleAddon,
  }),
);

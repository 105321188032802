import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
        marginLeft: 0,
    },
    input: {
        '& .MuiInputBase-root': {
            display: 'flex',
            flexWrap: 'wrap',
            padding: theme.spacing(0.5, 1.5),

            '&.Mui-disabled': {
                pointerEvents: 'auto',
            }
        },

        '& input': {
            flexGrow: 1,
            width: 0,
            minWidth: '30px',
            height: '24px',
            padding: theme.spacing(0.5, 0),
        },
    }
}));

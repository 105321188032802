import React, {PropsWithChildren} from 'react';
import {Grid} from '@material-ui/core';
//import GitHubIcon from '@material-ui/icons/GitHub';
//import {SupportLinkCard} from '../SupportLinkCard';

{/*
const BASE_LINK = {
    icon: <GitHubIcon />,
    to: '/docs/default/Component/ACE-FAQ',
    title: 'Q&A',
    subtitle: 'Explore frequently asked questions'
}; */}

export const SupportLinks = ({
    children,
}: PropsWithChildren<{}>) => (
    <Grid container direction="column" spacing={3}>
        {React.Children.map(children, (child, i) => (
            <Grid key={i} item>
                {child}
            </Grid>
        ))}

        {/*
        <Grid item>
            <SupportLinkCard
                to={BASE_LINK.to}
                title={BASE_LINK.title}
                subtitle={BASE_LINK.subtitle}
                icon={BASE_LINK.icon}
            />
        </Grid> */}
    </Grid>
);

import { Config } from '@backstage/config';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import {NotFoundError} from '@backstage/errors';
import {MarkdownCategory} from 'tmna-types';

export const newsLetterApiRef = createApiRef<INewsLetterApi>({
    id: 'plugin.news-letter',
});

type NewsLetterApiOptions = {
    configApi: Config;
    identityApi: IdentityApi;
}

type newsletterMetaDataType = {
    "id": string;
    "fileName": string;
    "title": string;
    "description": string;
    "date": string;
}

interface INewsLetterApi {
    getLatestNewsLetterNew(id: string): Promise<any>;
    getNewsletterMetadata(): Promise<newsletterMetaDataType[]>;
}

export class NewsLetterApi implements INewsLetterApi {
    private configApi: Config;
    private identityApi: IdentityApi;
    private markdownCategory: MarkdownCategory.NEWLETTER = MarkdownCategory.NEWLETTER;

    constructor(options: NewsLetterApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
    }

    async getNewsletterMetadata() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const token = await this.identityApi.getCredentials();
        const requestUrl = `${backendUrl}/api/markdown/${this.markdownCategory}/metadata`;
        const response = await fetch(
          requestUrl,
          {
                  credentials: 'include',
                  headers: { Authorization: `Bearer ${token.token}` }
              },
        );
        const data = await response.json();

        if (data && data.error) {
            throw new NotFoundError(data.error.message);
        }

        if (!response.ok) {
            throw new NotFoundError(response.statusText);
        }

        return data;
    }

    public async getLatestNewsLetterNew(id: string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const token = await this.identityApi.getCredentials();
        const requestUrl = `${backendUrl}/api/markdown/${this.markdownCategory}/blob/${id}`;
        const response = await fetch(
            requestUrl,
            {
                credentials: 'include',
                headers: { 
                    Authorization: `Bearer ${token.token}`,
                    responseType: 'blob' 
                }
            },
        );

        if (!response.ok) {
            throw new NotFoundError(response.statusText);
        }

        return response.blob();
    }
}
import React from 'react';
import dayjs from 'dayjs';
import {NOTIFICATION_TYPES_MAP} from '../../constants';
import {NotificationIcon} from '../NotificationIcon';
import {Notification} from 'tmna-types';
import { TableColumn } from '@backstage/core-components';

export const NOTIFICATIONS_CELL_PLACEHOLDER = 'N/A';

export const COLUMNS: TableColumn<Notification>[] = [
    {
        field: 'type',
        type: 'string',
        title: 'Type',
        render: ({type}) => {
            const status = NOTIFICATION_TYPES_MAP[type]?.status;

            return status
                ? <NotificationIcon status={status}/>
                : NOTIFICATIONS_CELL_PLACEHOLDER
        },
        width: '60px',
        align: 'center',
    },
    {
        field: 'serviceName',
        type: 'string',
        title: 'Service',
        render: ({type}) => (
            NOTIFICATION_TYPES_MAP[type]?.category || NOTIFICATIONS_CELL_PLACEHOLDER
        ),
    },
    {
        field: 'topicName',
        type: 'string',
        title: 'Name',
        render: ({type}) => (
            NOTIFICATION_TYPES_MAP[type]?.subcategory || NOTIFICATIONS_CELL_PLACEHOLDER
        ),
    },
    {
        field: 'accountId',
        type: 'string',
        title: 'Account ID',
        emptyValue: NOTIFICATIONS_CELL_PLACEHOLDER,
    },
    {
        field: 'date',
        type: 'date',
        title: 'date',
        render: ({date}) => {
            return date
                ? dayjs(date).format("MMM DD, YYYY HH:MM")
                : NOTIFICATIONS_CELL_PLACEHOLDER
        },
    },
];

import {plugin} from './plugin';

import {
    trainingsMainPageRouteRef,
    trainingPageRouteRef,
} from './routeRefs';
import { createRoutableExtension } from '@backstage/core-plugin-api';

export const TrainingsMainPage = plugin.provide(
    createRoutableExtension({
        component: () => import('./pages').then(m => m.TrainingsMainPage),
        mountPoint: trainingsMainPageRouteRef,
    }),
);

export const TrainingPage = plugin.provide(
    createRoutableExtension({
        component: () => import('./pages').then(m => m.TrainingPage),
        mountPoint: trainingPageRouteRef,
    }),
);

import {yellow} from '@material-ui/core/colors';
import {TMNA_COLOR_SCHEME} from '../colorScheme';

export const TMNAPalette = {
    background: {
        default: TMNA_COLOR_SCHEME.secondary.lightGray,
    },
    status: {
        ok: '#1DB954',
        warning: '#FF9800',
        error: TMNA_COLOR_SCHEME.primary.red,
        running: '#2E77D0',
        pending: '#FFED51',
        aborted: '#757575',
    },
    /*
    Deprecated will be removed in future release

    bursts: {
        fontColor: '#FEFEFE',
        slackChannelText: '#ddd',
        backgroundColor: {
            default: '#7C3699',
        }
    },
    */
    primary: {
        main: '#2E77D0',
    },
    banner: {
        info: '#2E77D0',
        error: TMNA_COLOR_SCHEME.primary.red,
        text: TMNA_COLOR_SCHEME.primary.white,
        link: TMNA_COLOR_SCHEME.primary.black,
    },
    border: TMNA_COLOR_SCHEME.secondary.silverGray,
    textContrast: TMNA_COLOR_SCHEME.primary.black,
    textVerySubtle: '#DDD',
    textSubtle: TMNA_COLOR_SCHEME.secondary.slateGray,
    highlight: '#FFFBCC',
    errorBackground: '#FFEBEE',
    warningBackground: '#F59B23',
    infoBackground: '#ebf5ff',
    errorText: TMNA_COLOR_SCHEME.primary.red,
    infoText: '#004e8a',
    warningText: TMNA_COLOR_SCHEME.primary.black,
    linkHover: TMNA_COLOR_SCHEME.secondary.darkRed,
    link: TMNA_COLOR_SCHEME.secondary.darkRed,
    gold: yellow.A700,
    navigation: {
        background: TMNA_COLOR_SCHEME.primary.black,
        indicator: '#9BF0E1',
        color: TMNA_COLOR_SCHEME.secondary.slateGray,
        selectedColor: TMNA_COLOR_SCHEME.primary.white,
    },
    pinSidebarButton: {
        icon: '#181818',
        background: '#BDBDBD',
    },
    tabbar: {
        indicator: '#9BF0E1',
    },
    tmnaColors: TMNA_COLOR_SCHEME,
};

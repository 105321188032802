export const LOCATIONS = [
    {
        label: 'ET',
        timeZone: 'America/New_York',
    },
    {
        label: 'CT',
        timeZone: 'America/Chicago',
    },
    {
        label: 'PT',
        timeZone: 'America/Los_Angeles',
    },
    {
        label: 'JST',
        timeZone: 'Asia/Tokyo',
    }
];

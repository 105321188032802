import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    container: {
        maxWidth: '100%',

        '& > div > button': {
            backgroundColor: 'rgba(196, 196, 196, 0.2)',
            '& svg': {
                fill: theme.palette.tmnaColors.secondary.darkRed,
            },

            '&:hover': {
                backgroundColor: theme.palette.tmnaColors.secondary.darkRed,
                '& svg': {
                    fill: theme.palette.tmnaColors.primary.white,
                },
            },

            '&:focus': {
                border: `2px solid ${theme.palette.tmnaColors.secondary.blue}`
            }
        },
    },
    dark: {
        '& > div > div:nth-child(2)': {
            backgroundImage: 'linear-gradient(to left, rgba(4, 24, 30, 0), #04181e)'
        },
        '& > div > div:nth-child(3)': {
            backgroundImage: 'linear-gradient(to right, rgba(4, 24, 30, 0), #04181e)'
        }
    },
}));

import React from 'react';
import {
    Typography,
    Box,
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/NavigateNext';
import {Link} from '@backstage/core-components';
import {useStyles} from './styles';

type Props = {
    icon?: JSX.Element;
    to: string;
    title: string;
    subtitle?: string;
};

export const SupportLinkCard = ({
    icon,
    to,
    title,
    subtitle,
}: Props) => {
    const classes = useStyles();

    return (
        <Link
            to={to}
            target="_blank"
            color="inherit"
            underline="none"
            className={classes.linkCard}
        >
            {icon}
            <Box mx={2} flexGrow={1}>
                <Typography variant="h4">
                    {title}
                </Typography>
                {subtitle && (
                    <Box mt={1}>
                        <Typography variant="body2">
                            {subtitle}
                        </Typography>
                    </Box>
                )}
            </Box>
            <ArrowIcon color="error" className={classes.linkArrowIcon} />
        </Link>
    );
};

import {TMNAThemeMixins} from '../types';

import { tmnaTheme } from './../theme';
import { TMNAPalette } from './../types';

export const tmnaLinkMixins = (p: TMNAPalette) => ({
    color: p.tmnaColors.secondary.darkRed,
    '&:hover': {
        textDecoration: 'underline',
    },
    '&:visited': {
        color: p.tmnaColors.secondary.redberry
    },
    '&:focus': {
        outline: `2px solid ${p.tmnaColors.secondary.blue}`
    },
})

export const TMNAMixins: Omit<TMNAThemeMixins, 'gutters' | 'toolbar'> = {
    lineClamp: (maxLinesNumber: number) => ({
        display: '-webkit-box',
        WebkitLineClamp: maxLinesNumber,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    }),
    tmnaLink: () => tmnaLinkMixins(tmnaTheme.palette),
};

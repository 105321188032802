import { useElementFilter } from '@backstage/core-plugin-api';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  value: {
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
    margin:'0px'
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  linkedField:{
    marginBottom:"12px",
  }
}));

/**
 * Props for LinkedField
 *
 * @public
 */
export interface LinkedFieldProps {
  label: string;
  value?: string;
  gridSizes?: Record<string, number>;
  children?: React.ReactNode;
}

/** @public */
export function LinkedField(props: LinkedFieldProps) {
  const { label, value, gridSizes, children } = props;
  const classes = useStyles();

  const childElements = useElementFilter(children, c => c.getElements());

  const content =
    childElements.length > 0 ? (
      childElements
    ) : (
      <Typography variant="body2" paragraph className={classes.value}>
        {value || `unknown`}
      </Typography>
    );
  return (
    <Grid item {...gridSizes} className={classes.linkedField}>
      <Typography variant="h2" className={classes.label}>
        {label}
      </Typography>
      {content}
    </Grid>
  );
}

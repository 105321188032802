import React from 'react';
import { Typography, IconButton, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from '../Link';
import { useStyles } from './styles';

type Props = {
  title?: string;
  severity?: 'warning' | 'error' | 'info';
  message?: React.ReactNode;
  children?: React.ReactNode;
  closeContents: VoidFunction;
};

export const AlphaDisclaimerInfo = (props: Props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.Alerts}>
      <div>
        <Alert severity="info">
          <div className={classes.header2}>
            What is a Blueprint?
            <div>
              <IconButton onClick={props.closeContents}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <Box style={{ marginTop: '0px' }} mt={2}>
            <Typography>
              A Blueprint is a proposal from Cloud & DevEx team to encapsulate the
              complexities of provisioning cloud resources aligned with
              corporate standards and policies according to cloud pillars:
              security, resilience, performance, operational excellence and cost
              optimization. Every single blueprint will expose a module or a
              collection of modules as the base artifacts to be used from other
              Terraform modules or Terragrunt configurations.
            </Typography>
            <Box mt={3}>
              <Typography style={{ marginBottom: '0px' }} variant="body1" >
                For Infrastructure as Code (IaC), we provide 3 levels of
                blueprints: Infrastructure Blueprints, Architecture Blueprints
                and Reference Architectures &nbsp;
                <Link
                  className="tmna-style-link"
                  type="text"
                  to="https://chofer.cloud.toyota.com/docs/default/Component/ACE-TECH-DOCS/blueprints/definition/"
                  target="_blank"
                >
                  Learn More
                </Link>
                .
              </Typography>
            </Box>
          </Box>
        </Alert>
      </div>
    </div>
  );
};

interface CloudAccount {
  accountId: string;
  //accountName?: string; // not implemented yet
  //accountOwner?: string; // not implemented yet
  costCenterId?: string;
  BUL2?: string;
  BUL3?: string;
  //WBSID?: string; // not implemented yet
}

export interface CloudAccountChargeback extends CloudAccount {
  period: {
    [key: string]: string;
    },
}

export interface ChargebacksPayload {
  accountsData: CloudAccountChargeback[];
  months: any;
}

export interface CloudAccountChargebackDetails{
  accountId?: string;
    Account_Name?:string;
    estimated_budget?:string;
  // bu_l2?:string;
  // bu_l3?:string;
}

export interface CloudAccountChargebackDetailsPeriod extends CloudAccountChargebackDetails {
  period: {
    [key: string]: string;
    },
}
export interface CloudAccountChargebackDetailsPeriodCommon {
  budget_overrun: number;
  actual_spent: number;
  estimated_budget: string;
  monthly_avg: number;
  annual_budget: number;
    start_year_month:string;
  end_year_month: string;
  budget_remaining?: string;
  budget_utilization_rate?: string;
}
export interface ChargebacksDetailsPayload{
  dataCommon: CloudAccountChargebackDetailsPeriodCommon[];
  accountsData: CloudAccountChargebackDetailsPeriod[];
  months: string[];
}

export enum OrgBusinessUnitLevels {
  L2 = 'L2',
  L3 = 'L3',
}

export type BusinessUnit = string;

export type ICostLocationsRes = {
  id: string;
  name: string;
  opSys: string[];
  instances: {
    name: string;
    id: string;
    baseCost: number;
    minCpu: number;
    maxCpu: number;
    minMemory: number;
    maxMemory: number;
    totalCost: number;
  }[];
  compute: {
    cpuMin: number;
    cpuMax: number;
    memoryMin: number;
    memoryMax: number;
    storageMin: number;
    storageMax: number;
    vmMin: number;
    vmMax: number;
  };
};

export type ICostEstimateReq = {
  instances: {
    id: string;
    count: number;
  }[];
  opSys: string;
  storage: number;
};

export type ICostEstimateRes = {
  id: string;
  instancesCost: {
    instances: {
      id: string;
      count: number;
      totalCost: number;
    }[];
    totalInstancesCost: number;
  };
  totalCost: number;
  totalStorageCost: number;
};

import React from 'react';
import {useAsync} from 'react-use';

import Alert from '@material-ui/lab/Alert';
import DocumentLinkIcon from '@material-ui/icons/DescriptionOutlined';
import {LinkList} from 'tmna-ui-kit';
import {FAQMetadata} from 'tmna-types';
import {FAQApiRef} from '../../api';

import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';

const mapFaqsToLinks = (faqs: FAQMetadata[]) => (
    faqs.map(elem => ({
        to: elem.id,
        title: elem.title,
    }))
);

export const Faq = () => {
    const faqApi = useApi(FAQApiRef);

    const { value: faqsLinks, loading, error } = useAsync(async () => {
        const data = await faqApi.getFAQsMetadata();

        return mapFaqsToLinks(data);
    }, []);


    if (loading) {
        return <Progress />;
    } else if (error) {
        return <Alert severity="error">{error.message}</Alert>;
    } else if (!faqsLinks) {
        // TODO: add placeholder
        return null;
    }

    return (
        <LinkList
            title="FAQ"
            icon={<DocumentLinkIcon color="disabled" />}
            iconPosition="left"
            items={faqsLinks}
        />
    );
}

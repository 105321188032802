import React from 'react';
import ExtensionIcon from '@material-ui/icons/Extension';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
    Typography,
    Grid,
    Box,
} from '@material-ui/core';
import {Link} from 'tmna-ui-kit';
import {RoundIcon} from '../RoundIcon';

import {TRAINING_CONTRIBUTION_LINK} from "../../constants";

export const TrainingContribution = () => (
    <Grid container direction="column" spacing={3}>
        <Grid item>
            <RoundIcon icon={<ExtensionIcon fontSize="large"/>}/>
            <Box mt={3}>
                <Typography variant="h3" paragraph>
                    Want to Contribute?
                </Typography>
                <Typography variant="body2">
                    If you work on a component, which can be added to our Training Catalog
                    or you want to share your insights and tips how to use the Portal, click the link below.
                </Typography>
            </Box>
        </Grid>
        <Box flexGrow={1}/>
        <Grid item>
            <Link
                to={TRAINING_CONTRIBUTION_LINK}
                target="_blank"
                icon={<NavigateNextIcon />}
                spacing={0}
            >
                <Typography variant="button">
                    SHARE EXPERTISE
                </Typography>
            </Link>
        </Grid>
    </Grid>
);

import {Overrides} from '@material-ui/core/styles/overrides';

import { tmnaLinkMixins } from './mixins';
import {TMNATheme} from '../types';

export const createTMNAThemeOverrides = (theme: TMNATheme): Overrides => ({
    MuiCssBaseline: {
        '@global': {
            html: {
                height: '100%',
                fontFamily: theme.typography.fontFamily,
            },
            body: {
                height: '100%',
                fontFamily: theme.typography.fontFamily,
                'overscroll-behavior-y': 'none',
            },
            a: {
                color: 'inherit',
                textDecoration: 'none',
            },
            'a.tmna-style-link': tmnaLinkMixins(theme.palette),
        },
    },
    MuiToolbar: {
        root: {
            padding: `${theme.spacing(2, 3)} !important`,
            paddingBottom: '20px !important',

            '& .MuiTypography-h5': {
                fontWeight: theme.typography.fontWeightMedium as any,
            }
        }
    },
    MuiTableRow: {
        // Alternating row backgrounds
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
        // Use pointer for hoverable rows
        hover: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        // Alternating head backgrounds
        head: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.paper,
            },
        },
        footer: {
            backgroundColor: `${theme.palette.background.paper} !important`,
            borderTop: `1px solid ${theme.palette.tmnaColors.secondary.silverGray}`,
        }
    },
    // Tables are more dense than default mui tables
    MuiTableCell: {
        root: {
            wordBreak: 'break-word',
            overflow: 'hidden',
            verticalAlign: 'middle',
            lineHeight: 1.44,
            margin: 0,
            padding: theme.spacing(1.25, 3),
            borderBottom: 0,
        },
        sizeSmall: {
            padding: `10px ${theme.spacing(3)}px`,
        },
        head: {
            borderTop: `1px solid ${theme.palette.tmnaColors.secondary.silverGray}`,
            borderBottom: `1px solid ${theme.palette.tmnaColors.secondary.silverGray}`,
            wordBreak: 'break-word',
            overflow: 'hidden',
            color: 'rgb(179, 179, 179)',
            lineHeight: 1.44,
            minHeight: '34px',
            padding: `10px ${theme.spacing(3)}px !important`,

            '& .MuiTableSortLabel-root': {
                fontWeight: theme.typography.fontWeightBold as any,
            },
            '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
                '&.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
                    color: theme.palette.tmnaColors.primary.red,
                },
            },
        },
    },
    MuiTabs: {
        // Tabs are smaller than default mui tab rows
        root: {
            minHeight: 24,
        },
        indicator: {
            height: '3px',
            backgroundImage: 'linear-gradient(to right, #f71840 0%, #ff9265 100%)'
        },
    },
    MuiTab: {
        // Tabs are smaller and have a hover background
        root: {
            ...theme.typography.button,
            // overriding backstage styles
            fontSize: '14px !important',
            padding: `${theme.spacing(3, 2)} !important`,
            minWidth: 'auto !important',
            letterSpacing: '1px !important',
            '&.Mui-focusVisible': {
                '&::after': {
                    content: '""',
                    width: '100%',
                    border: `2px solid ${theme.palette.tmnaColors.secondary.blue}`,
                    height: '100%',
                    position: 'absolute',
                    padding: '2px',
                },
            },
            '&.MuiTab-textColorInherit': {
                color: theme.palette.tmnaColors.secondary.slateGray,
                opacity: 1,
                '&:hover': {
                    color: theme.palette.tmnaColors.primary.black,
                    opacity: 1,
                },
            },
            '&.MuiTab-textColorInherit.Mui-selected': {
                color: theme.palette.tmnaColors.primary.black,
            },
        },
    },
    MuiTableSortLabel: {
        // No color change on hover, just rely on the arrow showing up instead.
        root: {
            fontWeight: theme.typography.fontWeightBold as any,
            color: 'inherit',
            '&:hover': {
                color: 'inherit',
            },
            '&:focus': {
                color: 'inherit',
            },
        },
        // Bold font for highlighting selected column
        active: {
            fontWeight: theme.typography.fontWeightBold as any,
            color: 'inherit',
        },
    },
    MuiTablePagination: {
        root: {
            '& .MuiTypography-caption': {
                fontWeight: theme.typography.fontWeightRegular as any,
            }
        },
        spacer: {
            flex: 0,
        },
        toolbar: {
            padding: '0 !important',
        },
        select: {
            paddingLeft: theme.spacing(2.5),
            background: 'none !important',
        }
    },
    MuiListItemText: {
        dense: {
            // Default dense list items to adding ellipsis for really long str...
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    MuiIconButton: {
        root: {
            color: theme.palette.tmnaColors.secondary.slateGray,
        }
    },
    MuiButton: {
        root: {
            fontWeight: theme.typography.fontWeightBold as any,
            borderRadius: '50px',
            boxShadow: 'none',
            height: '40px',
            padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
            '&.Mui-disabled': {
                color: theme.palette.tmnaColors.secondary.mediumGray,
            },
            '&.Mui-focusVisible': {
                '&::after': {
                    content: '""',
                    width: 'calc(100% + 8px)',
                    border: `2px solid ${theme.palette.tmnaColors.secondary.blue}`,
                    height: 'calc(100% + 8px)',
                    position: 'absolute',
                    padding: '2px',
                    borderRadius: '50px',
                },
            },
        },
        text: {
            padding: theme.spacing(1.5, 2),
            color: theme.palette.tmnaColors.primary.black,
            '&:hover': {
                backgroundColor: theme.palette.tmnaColors.secondary.silverGray,
            },
        },
        textPrimary: {
            color: theme.palette.tmnaColors.primary.black,
            '&:hover': {
                backgroundColor: theme.palette.tmnaColors.secondary.silverGray,
            },
        },
        textSecondary: {
            color: theme.palette.tmnaColors.secondary.darkRed,
            '&:hover': {
                backgroundColor: theme.palette.tmnaColors.secondary.silverGray,
            },
            '&.Mui-focusVisible': {
                backgroundColor: theme.palette.tmnaColors.secondary.darkGray,
            },
        },
        outlined: {
            backgroundColor: theme.palette.tmnaColors.primary.white,
            color: theme.palette.tmnaColors.primary.black,
            boxShadow: `0 0 0 2px ${theme.palette.tmnaColors.primary.black} inset`,
            padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
            border: 'none',
            '&:hover': {
                backgroundColor: '#e0e0e0',
            },
            '&.Mui-disabled': {
                border: 'none',
                boxShadow: 'none',
                backgroundColor: theme.palette.tmnaColors.secondary.silverGray,
            },
        },
        outlinedPrimary: {
            color: theme.palette.tmnaColors.primary.black,
            border: 'none',
            '&:hover': {
                border: 'none',
                backgroundColor: '#e0e0e0',
            },
        },
        outlinedSecondary: {
            color: theme.palette.tmnaColors.primary.black,
            border: 'none',
            '&:hover': {
                border: 'none',
                backgroundColor: '#e0e0e0',
            },
        },
        contained: {
            backgroundColor: theme.palette.tmnaColors.primary.black,
            color: theme.palette.tmnaColors.primary.white,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: theme.palette.tmnaColors.primary.gray,
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&.Mui-disabled': {
                backgroundColor: theme.palette.tmnaColors.secondary.silverGray,
            },
        },
        containedPrimary: {
            backgroundColor: theme.palette.tmnaColors.primary.black,
            '&:hover': {
                backgroundColor: theme.palette.tmnaColors.primary.gray,
            },
        },
        containedSecondary: {
            backgroundColor: theme.palette.tmnaColors.secondary.darkRed,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: '#8c0000',
            },
        },
        sizeSmall: {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            height: '32px',
        },
        sizeLarge: {
            padding: `${theme.spacing(1.5)}px ${theme.spacing(5.5)}px`,
            height: '48px',
        },
        label: {
            lineHeight: 'normal',
        },
    },
    MuiChip: {
        root: {
            backgroundColor: 'rgba(88, 89, 91, 0.1)',
            // By default there's no margin, but it's usually wanted, so we add some trailing margin
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            color: theme.palette.grey[900],
        },
        outlined: {
            color: theme.palette.text.primary,
        },
        label: {
            lineHeight: `${theme.spacing(2.5)}px`,
            fontWeight: theme.typography.fontWeightRegular as any,
            fontSize: `${theme.spacing(1.75)}px`,
            textTransform: 'capitalize',
        },
        labelSmall: {
            fontSize: `${theme.spacing(1.5)}px`,
        },
        deleteIcon: {
            color: theme.palette.tmnaColors.primary.red,
            width: `${theme.spacing(3)}px`,
            height: `${theme.spacing(3)}px`,
            margin: `0 ${theme.spacing(0.75)}px 0 -${theme.spacing(0.75)}px`,
            '&:hover': {
                color: theme.palette.tmnaColors.primary.red,
            }
        },
        deleteIconSmall: {
            color: theme.palette.tmnaColors.primary.red,
            width: `${theme.spacing(2)}px`,
            height: `${theme.spacing(2)}px`,
            margin: `0 ${theme.spacing(0.5)}px 0 -${theme.spacing(0.5)}px`,
            '&:hover': {
                color: theme.palette.tmnaColors.primary.red,
            }
        },
        clickable: {
            backgroundColor: 'rgba(235, 10, 30, 0.05)',
            color: theme.palette.tmnaColors.primary.red,

            '&:focus': {
                backgroundColor: 'rgba(235, 10, 30, 0.05)',
            },
            '&:hover': {
                backgroundColor: 'rgba(235, 10, 30, 0.1)',
            },
        },
        colorSecondary: {
            backgroundColor: theme.palette.tmnaColors.primary.red,

            '&:hover': {
                backgroundColor: 'rgba(235, 10, 30, 0.6) !important',
            }
        },
    },
    MuiCard: {
        root: {
            // When cards have a forced size, such as when they are arranged in a
            // CSS grid, the content needs to flex such that the actions (buttons
            // etc) end up at the bottom of the card instead of just below the body
            // contents.
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 20%)',
        },
    },
    MuiCardHeader: {
        root: {
            padding: `${theme.spacing(2, 3)} !important`,
            paddingBottom: '20px !important',
        },
    },
    MuiCardContent: {
        root: {
            // When cards have a forced size, such as when they are arranged in a
            // CSS grid, the content needs to flex such that the actions (buttons
            // etc) end up at the bottom of the card instead of just below the body
            // contents.
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    },
    MuiCardActions: {
        root: {
            borderTop: `1px solid ${theme.palette.tmnaColors.secondary.silverGray}`,
            padding: theme.spacing(2),
            paddingLeft: theme.spacing(3),
        },
        spacing: {
            '& > :not(:first-child)': {
                marginLeft: 0,
            }
        }
    },
    MuiInputBase: {
        root: {
            '& fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
                borderColor: theme.palette.tmnaColors.secondary.mediumGray,
                top: 0,
            },
            '&:hover fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
                borderColor: `${theme.palette.tmnaColors.secondary.slateGray}`,
            },
            '&.Mui-focused fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
                borderColor: `${theme.palette.tmnaColors.primary.black}`,
                borderWidth: '1px',
            },
            '& .MuiInputAdornment-root': {
                color: theme.palette.tmnaColors.secondary.slateGray,
            },
            '&.Mui-focused .MuiInputAdornment-root': {
                color: 'inherit',
            },
            '& .MuiInputAdornment-root .MuiIconButton-root': {
                color: theme.palette.tmnaColors.secondary.slateGray,
                background: 'none',
                padding: 0,

                '&:hover': {
                    color: 'inherit',
                }
            },

            '&.Mui-error': {
                '& fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
                    borderColor: `${theme.palette.tmnaColors.primary.red}`,
                },
                '&:hover fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
                    borderColor: theme.palette.tmnaColors.secondary.darkRed,
                },
                '&.Mui-focused fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
                    borderColor: '#8c0000',
                    borderWidth: '1px',
                },
            },

            '&.Mui-disabled': {
                pointerEvents: 'none',

                '& fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
                    borderColor: '#e0e0e0',
                    top: 0,
                },
                '&:hover fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
                    borderColor: '#e0e0e0',
                },
            },

            '& legend': {
                display: 'none !important',
            },
        },
        input: {
            lineHeight: 1.5
        },
    },
    MuiOutlinedInput: {
        root: {
            background: theme.palette.tmnaColors.primary.white,

            '&.Mui-disabled': {
                background: 'transparent',
            },
        },
    },
    MuiInputLabel: {
        root: {
            position: 'static',
            fontSize: '12px',
            transform: 'none !important',
            paddingBottom: '12px',
            color: `${theme.palette.tmnaColors.primary.black} !important`,
            fontWeight: theme.typography.fontWeightBold as any,
        },
        asterisk: {
            color: `${theme.palette.tmnaColors.primary.black} !important`,
        },
        formControl: {
            position: 'static',
        },
    },
    MuiInput: {
        underline: {
            '&:after': {
                borderBottom: `2px solid ${theme.palette.tmnaColors.secondary.blue}`,
            }
        }
    },
    MuiCheckbox: {
        root: {
            color: theme.palette.tmnaColors.secondary.slateGray,

            '&:hover': {
                color: theme.palette.tmnaColors.primary.gray,
                backgroundColor: 'transparent',
            },
        },
        colorPrimary: {
            '&.Mui-checked': {
                color: theme.palette.tmnaColors.primary.black,

                '&:hover': {
                    color: theme.palette.tmnaColors.primary.gray,
                    backgroundColor: 'transparent !important',
                }
            }
        },
        colorSecondary: {
            '&.Mui-checked': {
                color: theme.palette.tmnaColors.primary.black,

                '&:hover': {
                    color: theme.palette.tmnaColors.primary.gray,
                    backgroundColor: 'transparent !important',
                }
            }
        }
    },
    MuiRadio: {
        colorPrimary: {
            color: theme.palette.tmnaColors.secondary.slateGray,
            backgroundColor: 'transparent !important',
            '&:hover': {
                color: theme.palette.tmnaColors.primary.gray,
            },

            '&.Mui-checked': {
                color: theme.palette.tmnaColors.primary.black,

                '&:hover': {
                    color: theme.palette.tmnaColors.primary.gray,
                },
            },
        },
    },
    // TODO: remove ts-ignore
    // @ts-ignore
    MuiAutocomplete: {
        clearIndicator: {
            marginRight: theme.spacing(1.5),
            '&::after': {
                content: '""',
                position: 'absolute',
                right: '-6px',
                width: '1px',
                height: theme.spacing(3),
                background: theme.palette.tmnaColors.secondary.mediumGray,
            }
        },
        option: {
            '&[aria-selected="true"]': {
                backgroundColor: theme.palette.tmnaColors.secondary.lightGray,
            },
            '&[aria-focus="true"]': {
                backgroundColor: theme.palette.tmnaColors.secondary.lightGray,
            }
        },
    },
    MuiSelect: {
        select: {
            padding: `${theme.spacing(1, 3, 1, 1.5)} !important`,

            '&.Mui-disabled': {
                backgroundColor: theme.palette.tmnaColors.secondary.lightGray,
                borderColor: theme.palette.tmnaColors.secondary.silverGray,
                color: theme.palette.tmnaColors.secondary.mediumGray,
            }
        },
    },
    MuiFormLabel: {
        root: {
            '&.Mui-disabled': {
                color: `${theme.palette.tmnaColors.secondary.mediumGray} !important`,
            },
        },
    },
    MuiFormHelperText: {
        root: {
            marginLeft: 0,
            color: theme.palette.tmnaColors.primary.black,
            fontWeight: theme.typography.fontWeightRegular as any,

            '&.Mui-error': {
                color: theme.palette.tmnaColors.secondary.darkRed,
            },
            '&.Mui-disabled': {
                color: theme.palette.tmnaColors.secondary.mediumGray,
            },
        },
        marginDense: {
            marginTop: '10px',
        },
        contained: {
            marginLeft: 0,
        },
    },
    MuiFormControl: {
        marginNormal: {
            marginTop: 0,
        }
    },
    MuiPopover: {
        paper: {
            '&.MuiMenu-paper': {
                '& .MuiListItem-root': {
                    background: 'inherit',

                    '&.Mui-selected': {
                        color: theme.palette.tmnaColors.secondary.darkRed,
                        background: 'inherit',
                    },
                    '&:hover': {
                        color: theme.palette.tmnaColors.secondary.darkRed,
                        background: theme.palette.tmnaColors.secondary.lightGray
                    }
                }
            }
        }
    },
    MuiDivider: {
        light: {
            backgroundColor: 'rgba(255, 255, 255, .2)',
        },
        middle: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        }
    },
    MuiSvgIcon: {
        root: {
            display: 'block',
        },
        colorSecondary: {
            color: theme.palette.tmnaColors.primary.red,
        }
    },
    MuiStepper: {
        root: {
            padding: 0,

            '&.MuiPaper-root': {
                backgroundColor: 'inherit',
            },
            '& .MuiPaper-elevation2': {
                backgroundColor: 'inherit',
                boxShadow: 'none',
            },
            '& form': {
                maxWidth: '600px',
            },
            '& .MuiBox-root': {
                padding: theme.spacing(0),
            },
            '& .MuiGrid-container': {
                backgroundColor: 'inherit',
                paddingLeft: theme.spacing(0.5),
                alignItems: 'baseline',
                justifyContent: 'flex-start',
            },
            '& h5': {
                fontSize: '16px',
                fontWeight: theme.typography.fontWeightMedium as any,
                marginBottom: theme.spacing(2),
                paddingTop: theme.spacing(1),
            },
            '& h6': {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(3),
                fontWeight: 'normal',
            },
            '& .MuiButton-textSecondary': {
                color: theme.palette.tmnaColors.primary.black,
                border: `2px solid ${theme.palette.tmnaColors.primary.black}`,
                backgroundColor: theme.palette.tmnaColors.primary.white,
                marginBottom: theme.spacing(3),
            },
            '& hr': {
                display: 'none',
            },
            '& .MuiFormControlLabel-root': {
                '& .MuiCheckbox-colorSecondary': {
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, .1)',
                    },
                },
                '& .MuiCheckbox-colorSecondary.Mui-checked': {
                    color: theme.palette.tmnaColors.primary.black,
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, .1)',
                    },
                },
            },
        },
    },
    MuiStepContent: {
        root: {
            borderColor: theme.palette.tmnaColors.secondary.silverGray,
        },
        transition: {
            paddingTop: theme.spacing(4),
        }
    },
    MuiStepConnector: {
        line: {
            borderColor: theme.palette.tmnaColors.secondary.silverGray,
        },
    },
    MuiStepLabel: {
        root: {
            alignItems: 'flex-start',
        },
        iconContainer: {
            paddingRight: theme.spacing(1.5),
        },
        label: {
            fontSize: '16px',
            fontWeight: theme.typography.fontWeightBold as any,
            color: theme.palette.tmnaColors.primary.black,
            '&[class*=MuiStepLabel-active]': {
                fontWeight: theme.typography.fontWeightBold as any,
            },
            '&[class*=MuiStepLabel-completed]': {
                fontWeight: theme.typography.fontWeightBold as any,
            },
        }
    },
    MuiCircularProgress: {
        colorPrimary: {
            color: theme.palette.tmnaColors.secondary.blue,
        },
        colorSecondary: {
            color: theme.palette.tmnaColors.primary.red
        },
    },
    MuiLinearProgress: {
        colorPrimary: {
            backgroundColor: '#C6E4F9',
        },
        barColorPrimary: {
            backgroundColor: theme.palette.tmnaColors.secondary.blue,
        },
    },
    // TODO: remove ts-ignore
    // @ts-ignore
    MuiAlert: {
        root: {
            padding: theme.spacing(3),
        },
        icon: {
            marginRight: theme.spacing(2),
            padding: '6px',
            borderRadius: '50%',
            width: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        standardSuccess: {
            backgroundColor: '#ebf7ec',
            '& $icon': {
                color: '#009c0d',
                backgroundColor: 'rgba(0, 156, 13, 0.1)',
            }
        },
        standardInfo: {
            backgroundColor: '#ebf7ff',
            '& $icon': {
                color: theme.palette.tmnaColors.secondary.blue,
                backgroundColor: 'rgba(0, 154, 255, 0.1)',
            }
        },
        standardWarning: {
            backgroundColor: '#fef6eb',
            '& $icon': {
                color: '#F48B00',
                backgroundColor: 'rgba(244, 139, 0, 0.1)',
            }
        },
        standardError: {
            backgroundColor: '#fdebed',
            '& $icon': {
                color: theme.palette.tmnaColors.primary.red,
                backgroundColor: 'rgba(235, 10, 30, 0.1)',
            }
        },
        message: {
            fontSize: 18,
            fontWeight: theme.typography.fontWeightBold as any,
            lineHeight: 1.44,
            color: theme.palette.tmnaColors.primary.black,
            padding: 0,
            marginTop: theme.spacing(0.5),
            flexGrow: 1,
            justifyContent: 'unset',
        },
    },
    MuiTypography: {
        gutterBottom: {
            marginBottom: theme.spacing(1),
        },
        paragraph: {
            marginBottom: theme.spacing(2),
        }
    },
    MuiBadge: {
        colorPrimary: {
            backgroundColor: theme.palette.tmnaColors.secondary.blue,
        },
        colorSecondary: {
            backgroundColor: '#F42045',
        },
    }
});

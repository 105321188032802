import { Config } from '@backstage/config';
import { createApiRef, DiscoveryApi, IdentityApi} from '@backstage/core-plugin-api';
import { stringifyQueryString } from 'tmna-utils';

//import { stringifyQueryString } from 'tmna-utils';

export const appCatalogApiRef = createApiRef<IAppCatalogApi>({
    id: 'plugin.app-catalog.service',
});

interface IAppCatalogApi {
    searchRepo(params:any): Promise<any>;
    linkRepo(repo: any, toyotaSysId: any, githubToken: string): Promise<any>; 
    unLinkRepo(repoName: string,uid:string,githubToken:string): Promise<any>;    
}

export class AppCatalogApi implements IAppCatalogApi {
    private configApi: Config;
    private identityApi: IdentityApi;

    constructor(options: {
        configApi: Config;
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
      }) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
      }
    
    public async searchRepo(params:any) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url: any = new URL(`${backendUrl}/api/app-catalog/searchRepo`);
        if (params !=="")
          url.search = stringifyQueryString({params: params}); 
        const token = await this.identityApi.getCredentials();        
        const response = await fetch(url.toString(), {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              ...(token && { Authorization: `Bearer ${token.token}` }),
            },
          });
          return await response.json();
    }

    public async linkRepo(repo: any, toyotaSysId: string, githubToken: string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url: any = new URL(`${backendUrl}/api/app-catalog/linkRepo`);
        const token = await this.identityApi.getCredentials();
        // if (githubToken !=="")
        //   url.search = stringifyQueryString({githubToken: githubToken});         
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              ...(token && { Authorization: `Bearer ${token.token}` }),
            },
            body: JSON.stringify({repo:repo, toyotaSysId:toyotaSysId, githubToken:githubToken}),
          });
          return await response.json();
          //return response;
    }

    public async unLinkRepo(repoName: string,uid:string,githubToken:any) {
      const backendUrl = this.configApi.getString('backend.baseUrl');
      const url: any = new URL(`${backendUrl}/api/app-catalog/unLinkRepo`);
      const token = await this.identityApi.getCredentials();        
      const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token.token}` }),
          },
          body: JSON.stringify({ repo: repoName, uid:uid,githubToken:githubToken }),
        });
        return await response.json();
  }
    
}



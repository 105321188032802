import {TMNAColorScheme} from './types';

export const TMNA_COLOR_SCHEME: TMNAColorScheme = {
    primary: {
        red: '#EB0A1E',
        white: '#FFFFFF',
        black: '#000000',
        gray: '#58595B',
    },
    secondary: {
        darkGray: '#1A2122',
        slateGray: '#9B9C9D',
        mediumGray: '#C4C4C4',
        silverGray: '#E6E6E6',
        lightGray: '#F7F7F7',
        blue: '#009AFF',
        lightBlue: '#EDF0F5',
        darkRed: '#CC0000',
        redberry: '#8C0000',
        green: '#00a40e',
        orange: '#f48b00',
    }
};

import React from 'react';


import { useStyles } from './styles';

import {  Popover, Link, Typography } from '@material-ui/core';


import SchoolIcon from '@material-ui/icons/School';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';

export const HeaderIconButton = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <a
                className={classes.buttonCustom}
                aria-describedby={id}
                onClick={handleClick}
            >
                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill='#FFFFFF'><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M21,12.22C21,6.73,16.74,3,12,3c-4.69,0-9,3.65-9,9.28C2.4,12.62,2,13.26,2,14v2c0,1.1,0.9,2,2,2h1v-6.1 c0-3.87,3.13-7,7-7s7,3.13,7,7V19h-8v2h8c1.1,0,2-0.9,2-2v-1.22c0.59-0.31,1-0.92,1-1.64v-2.3C22,13.14,21.59,12.53,21,12.22z" /><circle cx="9" cy="13" r="1" /><circle cx="15" cy="13" r="1" /><path d="M18,11.03C17.52,8.18,15.04,6,12.05,6c-3.03,0-6.29,2.51-6.03,6.45c2.47-1.01,4.33-3.21,4.86-5.89 C12.19,9.19,14.88,11,18,11.03z" /></g></g></svg> <span style={{paddingLeft:'4px'}}> Contact Us </span>  
            </a>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ width: '350px', padding: '15px' }}>
                <Typography style={{ display: 'flex', gap: '15px', fontSize: '14px' }} > <AddToQueueIcon style={{ color: 'black' }} /> Request for a New Feature </Typography>
                    <div style={{ marginLeft: '40px', marginBottom: '10px' }}><Link className={classes.Links} href='https://forms.office.com/pages/responsepage.aspx?id=HS1kjAnXsEerEAgK8QeY-yU8rf44V9FGpWalO9NJA5NUN1VQVTlDQ0I0TFFCRkNMVTIyWFZHOVJEWCQlQCN0PWcu' target="_blank" style={{ color: '' }}>Feature Request Form</Link> </div>
                    <Typography style={{ display: 'flex', gap: '15px', fontSize: '14px' }} > <SchoolIcon style={{ color: 'black' }} /> Explore Chofer Knowledge Base</Typography>
                    <div style={{ marginLeft: '40px', marginBottom: '10px' }}><Link className={classes.Links} href='/docs/default/Component/ACE-FAQ' style={{ color: '' }}>FAQs</Link> | <Link className={classes.Links} href='/docs'>TechDocs </Link> | <Link className={classes.Links} href='/training'>Training </Link> | <Link className={classes.Links} href='/blogs'>Blogs </Link></div>
                    <Typography style={{ display: 'flex', alignItems: 'center', gap: '15px', fontSize: '14px' }}>  <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /><g><path d="M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3L19,3z" /></g><path d="M14,17H7v-2h7V17z M17,13H7v-2h10V13z M17,9H7V7h10V9z" /></g></svg>
                        Raise 1TS Ticket </Typography>
                    <Link className={classes.Links}
                        href="/docs/default/Component/ACE-FAQ/1ts-request-for-azure-and-aws/1ts-ticket-guide/"
                        style={{ marginLeft: '40px', marginBottom: '10px' }}>Resource Provisioning</Link> | <Link className={classes.Links}
                        href="/docs/default/Component/developer-platform-guides/support/"
                        style={{ marginBottom: '10px' }}>Pipeline Support</Link>
                    <Typography style={{ display: 'flex', flexDirection: 'row', gap: '15px', marginTop: '2%', fontSize: '14px' }}>
                        <div>
                            <ContactSupportIcon style={{ color: 'black', display: 'inline', marginTop: '2%' }} />
                        </div>
                        <div>
                            <span>Post a question on </span>
                            <Link style={{ display: 'block', fontSize: '14px' }} className={classes.Links}
                                href='https://teams.microsoft.com/l/channel/19%3a5_ymJwJxEJhMuJL-7nDZIwzFmaHA2UV7dM1GUxtLbNs1%40thread.tacv2/General?groupId=4ae2a04b-2ce1-499a-8d31-9aee65ec87b5&tenantId=8c642d1d-d709-47b0-ab10-080af10798fb'>
                                Chofer-Care Teams Channel </Link>
                        </div>
                    </Typography>
                </div>
            </Popover>
           
        </div>
    );
};
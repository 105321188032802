import React from 'react';
import {TMNA_COLOR_SCHEME} from 'tmna-theme';
import { sidebarConfig, Avatar } from '@backstage/core-components';

type Props = {
    picture?: string;
    displayName?: string;
}

export const SidebarAvatar = ({
    picture,
    displayName,
}: Props) => {
    const {iconSize} = sidebarConfig;

    const avatarStyles = React.useMemo(() => ({
        height: iconSize,
        width: iconSize,
        fontSize: '10px',
        fontWeight: 600,
        background: TMNA_COLOR_SCHEME.primary.gray,
    }), [iconSize]);

    return (
        <Avatar
            picture={picture}
            customStyles={avatarStyles}
            displayName={displayName}
        />
    );
};

/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, {  KeyboardEventHandler, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContent } from '@backstage/core-components';
import { TextField, InputAdornment,makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';

/**
 * Props for {@link SidebarSearch}.
 *
 * @public
 */
export const useStyles = makeStyles({
  searchInput: {
    "& div": {
      paddingRight: "0px !important"
    }
  }
});
/**
 * @public
 */
export const SearchText = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const queryAvailable = queryParams.get('query') || '';
  const [searchQuery, setSearchQuery] = useState('');
  const [showHelperText, setShowHelperText] = useState(false);
  const classes = useStyles();
  const handleMouseEnter = () => setShowHelperText(true);
  const handleMouseLeave = () => setShowHelperText(false);

  const { focusContent } = useContent();
  const navigate = useNavigate();
  const handleSearch = () =>{
      focusContent();
      navigate(`/search?query=${searchQuery}`);
  };
  const handleInputChange = (event:any) => {
    setSearchQuery(event.target.value);
  };
  const handleEnter: KeyboardEventHandler = ev => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSearch();
    }
  };

  React.useEffect(() => {
    if (queryAvailable == '') {
      setSearchQuery("");
    }
  }, [queryAvailable]);

  return (
    //<Tooltip title="Use quotes around the term for exact matches" placement="top">
    <TextField
      variant="outlined"
      placeholder="Search"
      className={classes.searchInput}
      style={{color: 'white',
        font: 'inherit'}}
      value={searchQuery}
      onChange={handleInputChange}
      onKeyDown={handleEnter}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{
            background: '#D93B3B',
            color: 'white',
            margin: '0px 0px 0px 0px',
            borderRadius: '5px 0px 0px 5px',
            zIndex: 1,
            paddingLeft:'16px',
            height: '42px',
            maxHeight:'39px'}}>
            <Search onClick={handleSearch}  style={{ cursor: 'pointer',margin: '0px 3px 1px -11px' }} />
          </InputAdornment>
        ),
        endAdornment: (
          showHelperText && (
            <InputAdornment position="end" style={{ display: 'block', position: 'absolute', bottom: '-2px', left: '0',paddingRight:'0px !important' }}>
              <span style={{
                  fontSize: '14px',
                  fontFamily: 'ToyotaType',
                  fontWeight: '400',
                  color: 'black',
                  position: "fixed",
                  backgroundColor: '#eee', 
                  padding: '5px',
                  borderRadius: '3px',
                  transition: 'background-color 0.2s ease-in-out',
                }}>
                Use quotes for exact match, for e.g. 'Savings Plan' or "Savings Plan". 
              </span>
            </InputAdornment>
          )
        ),
      }}
    />
   // </Tooltip>
  );
};

import React from 'react';

import {Grid, Typography} from '@material-ui/core';

import {Link} from '../Link';

type Props = {
    title: string;
    icon?: JSX.Element;
    iconPosition?: 'left' | 'right';
    items: {
        to: string;
        title: string;
        icon?: JSX.Element;
        iconPosition?: 'left' | 'right';
        onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    }[];
}

export const LinkList = ({
    title,
    icon,
    iconPosition = 'right',
    items,
}: Props) => (
    <Grid container spacing={3} direction="column">
        <Grid item>
            <Typography variant="h6">
                {title}
            </Typography>
        </Grid>
        <Grid item container direction="column" spacing={2}>
            {items.map(item => (
                <Grid item key={item.title}>
                    <Link
                        to={item.to}
                        icon={item.icon || icon}
                        iconPosition={item.iconPosition || iconPosition}
                    >
                        {item.title}
                    </Link>
                </Grid>
            ))}
        </Grid>
    </Grid>
);

import {TMNA_COLOR_SCHEME} from 'tmna-theme';
import {NotificationStatus} from '../NotificationPage/types';
import {getNotificationRowColor} from '../NotificationPage/ui/NotificationTable/utils';

export const getRowStyles = (status: NotificationStatus, index: number, isRead: boolean) => {
    const isOddRow = (index + 1) % 2 === 1;
    const tintColor = getNotificationRowColor(status, 0.05);

    if (isRead) {
        return {};
    }

    return {
        background: isOddRow
            ? `linear-gradient(${tintColor}, ${tintColor}), ${TMNA_COLOR_SCHEME.secondary.lightGray}`
            : tintColor,
        boxShadow: `inset 2px 0 ${getNotificationRowColor(status)}`
    };
};
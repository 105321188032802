import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>((theme) => ({
    tag: {
        color: theme.palette.tmnaColors.secondary.green,
        fontWeight: theme.typography.fontWeightRegular as any,
        backgroundColor: 'rgba(0, 164, 14, 0.1)',
    },
    description: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
    },
    content: {
        '& h1, h2, h3, h4, h5, h6': {
            fontWeight: theme.typography.fontWeightMedium as any,
        }
    },
    button: {
        cursor: 'pointer',

        '&:hover': {
            color: theme.palette.tmnaColors.secondary.darkRed
        },
    },
    icon: {
        color: theme.palette.tmnaColors.primary.red,
    },
    expandedIcon: {
        transform: 'rotate(180deg)',
    }
}));

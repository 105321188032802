import React from 'react';
import {
    Typography,
    Button,
} from '@material-ui/core';
import {
    useApi,
    errorApiRef
} from '@backstage/core-plugin-api';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
    ProviderComponent,
    ProviderLoader,
    SignInProvider,
    SignInProviderConfig,    
} from './types';

import {useStyles} from './styles';
import { UserIdentity } from '@backstage/core-components';
import {settingsApiRef} from '@internal/plugin-user-settings-fork/src/api';

const Component: ProviderComponent = ({ config, onSignInSuccess}) => {
    const { apiRef, title, message} = config as SignInProviderConfig;
    const authApi = useApi(apiRef);
    const errorApi = useApi(errorApiRef);
    const userServiceApi = useApi(settingsApiRef);
    const classes = useStyles();

    const handleLogin = async () => {
        try {
            const identityResponse = await authApi.getBackstageIdentity({
                instantPopup: true,
            });
            if (!identityResponse) {
              throw new Error(
                `The ${title} provider is not configured to support sign-in`,
              );
            }
          

            const profile = await authApi.getProfile();
            onSignInSuccess(
              UserIdentity.create({
                identity: identityResponse.identity,
                profile,
                authApi,
              }),
            );
            await userServiceApi.insertUserPreferenceFields();
        } catch (error:any) {
            errorApi.post(error);
        }
    };

    return (
        <Button
            color="inherit"
            onClick={handleLogin}
            className={classes.button}
        >
            <img src="assets/microsoft.svg" className={classes.authIcon} alt='icon' />
            <div className={classes.panel}>
                <Typography variant="subtitle1">{message}</Typography>
                <ArrowForwardIcon className={classes.arrowIcon} />
            </div>
        </Button>
    );
};

const loader: ProviderLoader = async (apis, apiRef) => {
    const authApi = apis.get(apiRef)!;

    const identityResponse = await authApi.getBackstageIdentity({
        optional: true,
    });

    if (!identityResponse) {
        return undefined;
    }

    const profile = await authApi.getProfile();

    return UserIdentity.create({
      identity: identityResponse.identity,
      profile,
      authApi,
    });
};

export const commonProvider: SignInProvider = { Component, loader };
import {trainingsMainPageRouteRef} from './routeRefs';

import {TutorialsApi, tutorialsApiRef, FAQApiRef, FAQApi} from './api';
import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRouteRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  // path: '/training',
  id: 'Training',
});

export const plugin = createPlugin({
  id: 'training',
  routes: {
    trainingPage: trainingsMainPageRouteRef
  },
  apis: [
    createApiFactory({
      api: tutorialsApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({configApi, identityApi}) =>
          new TutorialsApi({ configApi, identityApi }),
    }),
    createApiFactory({
      api: FAQApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({configApi, identityApi}) =>
          new FAQApi({ configApi, identityApi }),
    }),
  ],
});

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    container: {
        padding: theme.spacing(5),
        gridArea: 'pageContent',
    },
    light: {
        backgroundColor: theme.palette.tmnaColors?.primary.white,
    }
}));

import React from 'react';
import {CardContent, CardMedia, Grid, Typography} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import VideocamIcon from '@material-ui/icons/Videocam';
import {useStyles} from './styles';

interface Props {
    title: string;
    subtitle: string;
    link: string;
    image?: string;
}

export const VideoCard = ({
    title,
    subtitle,
    link,
    image
}: Props) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpenModal(false);
    };

    return (
        <CardContent
            className={classes.videoCard}
            onClick={handleOpen}
            style={{
                backgroundImage: `linear-gradient(to bottom, rgba(39, 39, 39, 0) 1%, #272727 72%), url(${image})`,
                backgroundSize: 'cover'
            }}
        >
            <div className={classes.overlay} />
            <Grid
                container
                alignItems="flex-end"
                justifyContent="space-between"
                className={classes.container}
                wrap="nowrap"
                spacing={2}
            >
                <Grid item>
                    <Typography variant='button' className={classes.title} gutterBottom>
                        {title}
                    </Typography>
                    <Typography className={classes.subtitle} variant='subtitle2'>
                        {subtitle}
                    </Typography>
                </Grid>
                <Grid item>
                    <div className={classes.timeContainer}>
                        <VideocamIcon className={classes.videocamIcon} />
                    </div>
                </Grid>
            </Grid>
            <Modal
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <CardMedia
                    className={classes.videoSize}
                    component='iframe'
                    src={link}
                />
            </Modal>
        </CardContent>
    );
};

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    markdown: {
        '& h1': {
            ...theme.typography.h3,
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(3),
            fontWeight: theme.typography.fontWeightBold as any,
        },
        '& h2': {
            ...theme.typography.h4,
            margin: theme.spacing(5, 0, 3),
        },
        '& h5': {
            ...theme.typography.body1,
            fontWeight: theme.typography.fontWeightBold as any,
        },
        '& p': {
            ...theme.typography.body1,
        },
        '& p, img': {
            display: 'block',
            margin: theme.spacing(2, 0),
            width: '100%',
        },
        '& ol, li': {
            ...theme.typography.body1,
            marginBottom: theme.spacing(2),
        },
        '& table': {
            borderCollapse: 'collapse',
            border: `1px solid ${theme.palette.border}`,
        },
        '& th, & td': {
            border: `1px solid ${theme.palette.border}`,
            padding: theme.spacing(1),
        },
        '& td': {
            wordBreak: 'break-word',
            overflow: 'hidden',
            verticalAlign: 'middle',
            lineHeight: '1',
            margin: 0,
            padding: theme.spacing(3, 2, 3, 2.5),
            borderBottom: 0,
        },
        '& th': {
            backgroundColor: theme.palette.background.paper,
        },
        '& tr': {
            backgroundColor: theme.palette.background.paper,
        },
        '& tr:nth-child(odd)': {
            backgroundColor: theme.palette.background.default,
        },

        '& a': theme.mixins.tmnaLink(),
        '& img': {
            maxWidth: '100%',
        },
    },
}));
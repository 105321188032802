import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
        background: theme.palette.tmnaColors.secondary.silverGray,
        borderRadius: '4px',
    },
    item: {
        padding: theme.spacing(5),
        display: 'flex',
        flex: 1,
        alignItems: 'stretch',
},
divider: {
    height: 'auto',
    backgroundColor: theme.palette.tmnaColors.secondary.mediumGray,
    margin: theme.spacing(5, 0)
}
}));

import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '260px',
        height: '260px'
    },
    card__label: {
        fontSize: '12px',
        fontWeight: 500,
    },
    card__title: {
        fontWeight: 500,
        marginBottom: '10px',
    },
    card__description: {
        maxHeight: '85px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

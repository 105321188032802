import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    link: {
        display: 'inline-block',

        '&:hover': {
            textDecoration: 'none',
        },
    },
    text: {
        fontWeight: theme.typography.fontWeightRegular as any,
    }
}));

import {costCenterPageRouteRef} from './routeRefs';
import {costCenterApiRef, CostCenterApi } from './api';
import { createPlugin, createApiFactory, configApiRef, identityApiRef } from '@backstage/core-plugin-api';

export const plugin = createPlugin({
  id: 'cost-center',
  routes: {
    costCenterPage: costCenterPageRouteRef,
  },
  apis: [
    createApiFactory({
      api: costCenterApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({configApi, identityApi}) =>
          new CostCenterApi({ configApi, identityApi }),
    }),
  ],
});

import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link} from '@material-ui/core';
import {VideoCard} from 'tmna-ui-kit';
import {TutorialMetadata} from 'tmna-types';
import {trainingPageRouteRef} from '../../routeRefs';
import {TextTrainingCard} from './TextTrainingCard';
import {isTextTutorial} from '../../typeGuards';

import { useRouteRef } from '@backstage/core-plugin-api';

type Props = {
    data: TutorialMetadata;
}

export const TrainingCard = ({
    data,
}: Props) => {
    const trainingPageRoute = useRouteRef(trainingPageRouteRef);

    return (
        <Link
            to={trainingPageRoute({trainingId: data.id})}
            component={RouterLink}
            underline="none"
        >
            {
                isTextTutorial(data)
                    ? <TextTrainingCard
                        title={data.title}
                        subtitle={data.description}
                        image={data.previewImage}
                    />
                    :  <VideoCard
                        title={data.title}
                        subtitle={data.description}
                        link={data.link}
                        image={data.previewImage}
                    />
            }
        </Link>
    );
};
import {TMNA_COLOR_SCHEME} from 'tmna-theme';

import {NotificationStatus} from '../../types';

export const getNotificationRowColor = (status: NotificationStatus, opacity: number = 1) => {
    switch (status) {
        case NotificationStatus.INFO: {
            return `rgba(0, 154, 255, ${opacity})`
        }
        case NotificationStatus.WARNING: {
            return `rgba(244, 139, 0, ${opacity})`
        }
        case NotificationStatus.URGENT: {
            return `rgba(235, 10, 30, ${opacity})`
        }
        default: return 'inherit'
    }
}

export const getRowStyles = (status: NotificationStatus, index: number, isRead: boolean) => {
    const isOddRow = (index + 1) % 2 === 1;
    const tintColor = getNotificationRowColor(status, 0.05);

    if (isRead) {
        return {
            background: isOddRow
                ? TMNA_COLOR_SCHEME.secondary.lightGray
                : TMNA_COLOR_SCHEME.primary.white,
        };
    }

    return {
        background: isOddRow
            ? `linear-gradient(${tintColor}, ${tintColor}), ${TMNA_COLOR_SCHEME.secondary.lightGray}`
            : tintColor,
        boxShadow: `inset 3px 0 ${getNotificationRowColor(status)}`
    };
};
import {
    TextTutorialMetadata,
    TutorialContentType,
    VideoTutorialMetadata,
} from 'tmna-types';

export const isVideoTutorial = (
    value:
        VideoTutorialMetadata |
        TextTutorialMetadata
): value is VideoTutorialMetadata => (
    value.contentType === TutorialContentType.VIDEO
);

export const isTextTutorial = (
    value:
        VideoTutorialMetadata |
        TextTutorialMetadata
): value is TextTutorialMetadata => (
    value.contentType === TutorialContentType.TEXT
);

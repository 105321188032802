import React from 'react';
import {Divider} from '@material-ui/core';

import {TrainingRequest} from '../TrainigRequest';
import {TrainingContribution} from '../TrainingContribution';

import {useStyles} from './styles';

export const RequestingAndContribution = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.item}>
                <TrainingRequest />
            </div>
            <Divider orientation="vertical" className={classes.divider}/>
            <div className={classes.item}>
                <TrainingContribution />
            </div>
        </div>
    );
};

import { catalogPlugin } from '@backstage/plugin-catalog';
import { techdocsPlugin } from '@backstage/plugin-techdocs';
import * as plugins from '../../plugins';

// For EntityLayout
export const rootRouteRef = catalogPlugin.routes.catalogIndex;
export const unregisterRedirectRouteRef = catalogPlugin.routes.catalogIndex;

// For AboutCard
export const createFromTemplateRouteRef =
  plugins.ScaffolderPlugin.routes.selectedTemplate;
export const viewTechDocRouteRef = techdocsPlugin.routes.docRoot;

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    textList: {
        padding: theme.spacing(2, 0),
    },
    listIcon: {
        width: '6px',
        height: '6px',
        backgroundColor: theme.palette.tmnaColors.primary.red,
        borderRadius: '50%',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        flexShrink: 0,
    },
}));
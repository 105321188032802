import React from 'react';

import {useStyles} from './styles';

type Props = {
    icon: React.ReactElement;
}

export const RoundIcon = ({
    icon,
}: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {icon}
        </div>
    );
};

import {
  blogsArticlePageRouteRef,
  blogsMainPageRouteRef,
} from './routeRefs';

import {BlogsApi, blogsApiRef} from './api';
import { identityApiRef, configApiRef, createApiFactory, createPlugin } from '@backstage/core-plugin-api';

export const plugin = createPlugin({
  id: 'blogs',
  routes: {
    mainPage: blogsMainPageRouteRef,
    articlePage: blogsArticlePageRouteRef,
  },
  apis: [
    createApiFactory({
      api: blogsApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({configApi, identityApi}) =>
          new BlogsApi({ configApi, identityApi}),
    }),
  ],
});

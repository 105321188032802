import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InfoCard, Link } from '@backstage/core-components';
import { useStyles } from './styles';

type EdgeQuickLinksProps = {
  title: string;
  links: {
    id: string;
    title: string;
    link: string;
    target: string;
  }[];
};

export const EdgeQuickLinks: FC<EdgeQuickLinksProps> = ({ title, links }) => {
  const classes = useStyles();

  return (
    <InfoCard title={title} className={classes.cardContainer}>
      <Grid container direction="column">
        {links.map(item => (
          <Grid
            container
            item
            key={item.link}
            spacing={2}
            xs={12}
            wrap="nowrap"
            component={Link}
            data-testid={item.id}
            to={item.link}
            target={item.target}
          >
            <Grid item xs >
              <Typography variant="body1" >
                {item.title}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </InfoCard>
  );
};

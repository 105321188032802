/*
 * Copyright 2021 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useNavigate} from 'react-router';

import {Entity} from '@backstage/catalog-model';
import {Box, makeStyles} from '@material-ui/core';
import {getTechdocUrl, mapTechDocsToTableData} from './utils';

import {TechDocsTableRow} from './types';
import GithubIcon from '@material-ui/icons/GitHub';
import EditIcon from '@material-ui/icons/Edit';
import {EmptyDataMessage} from 'tmna-ui-kit';
import {PAGE_SIZE_OPTIONS} from './constants';
import { Table, TableColumn, TableProps } from '@backstage/core-components';

const useStyles = makeStyles({
    iconContainer: {
        width: 28,
        height: 28,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

const columns: TableColumn<TechDocsTableRow>[] = [
    {
        title: 'name',
        field: 'name',
        width: '270',
        cellStyle: {fontWeight: 600, minWidth: '270px'},
    },
    {
        title: 'description',
        field: 'description',
    },
    {
        title: 'owner',
        field: 'owner',
        width: '290px',
        cellStyle: {minWidth: '290px'},
    },
];

export const DocsCardGrid = ({
  entities,
}: {
  entities: Entity[] | undefined;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  if (!entities) return null;

    const actions: TableProps<TechDocsTableRow>['actions'] = [
        (techdoc) => {
            const url = techdoc.sourceUrl;
            return {
                icon: () => <Box className={classes.iconContainer}><GithubIcon fontSize="small"/></Box>,
                tooltip: 'Source',
                disabled: !url,
                onClick: () => {
                    if (!url) return;
                    window.open(url, '_blank');
                },
                iconProps: {
                    fontSize: 'small',
                    style: {color: 'red'}
                }
            };
        },
        (techdoc) => {
            const url = techdoc.editUrl;
            return {
                icon: () => <Box className={classes.iconContainer}><EditIcon fontSize="small" /></Box>,
                tooltip: 'Edit',
                disabled: !url,
                onClick: () => {
                    if (!url) return;
                    window.open(url, '_blank');
                },
            };
        },
    ];

  return (
      <Table
          data={mapTechDocsToTableData(entities)}
          columns={columns}
          title="Documentation"
          onRowClick={(_, rowData) => {
            if (rowData) {
                navigate(getTechdocUrl(rowData.namespace, rowData.kind, rowData.name));
            }
          }}
          options={{
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            pageSize: PAGE_SIZE_OPTIONS[1],
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            actionsCellStyle: {padding: '10px 16px'},
            padding: 'dense',
          }}
          localization={
            {
                body: {
                    emptyDataSourceMessage: <EmptyDataMessage message="No documents to display"/>
                }
            }
        }
          actions={actions}
      />
  );
};

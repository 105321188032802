import React from 'react';
import {
    Typography,
    CardContent,
    Card,
    CardActions,
} from '@material-ui/core';
import {useStyles} from './styles';

import { Button } from '@backstage/core-components';

type Props = {
    label: string;
    title: string;
    description: string;
    link: string;
}

export const InfoCard = ({
    label,
    title,
    description,
    link,
}: Props) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.card__label} variant="button" color="textSecondary" paragraph>
                    {label}
                </Typography>
                <Typography className={classes.card__title} variant="body1" component="h2">
                    {title}
                </Typography>
                <Typography className={classes.card__description} variant="body2">
                    {description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" to={link}>
                    Learn
                </Button>
            </CardActions>
        </Card>
    );
};

import React from 'react';
import { useAsync } from 'react-use';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {Link} from 'tmna-ui-kit';
import { TutorialMetadata} from 'tmna-types';
import {_shuffle} from 'tmna-utils';

import {TrainingCard} from '../TrainingCard';
import {useStyles} from './styles';
import {tutorialsApiRef} from '../../api';

import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';

type Props = {
    items: TutorialMetadata[];
}

export const FeaturedTraining = ({
    items,
}: Props) => {
    const classes = useStyles();

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.container}
            >
                <Grid item>
                    <Typography variant="h3">CloudEd</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" wrap="nowrap">
                <Grid item className={classes.textContainer}>
                    <Typography variant="body1" className={classes.text}>
                        Along with the trainings featured on our page, check out our Public Cloud – CloudEd site for the latest CloudHour schedule of immersive and experiential events and various recordings from past cloud demonstrations.
                    </Typography>

                    <Link
                        to="https://clouded.cloud.toyota.com"
                        target="_blank"
                        icon={<ChevronRightIcon />}
                        className={classes.link}
                    >
                        <Typography variant="button">
                            go to cloud.ed
                        </Typography>
                    </Link>
                </Grid>
                <Grid
                    container
                    wrap="nowrap"
                    className={classes.cardContainer}
                >
                    {items.map((item) => (
                        <Grid item xs key={item.id}>
                            <TrainingCard data={item} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export const FeaturedTrainigContainer = () => {
    const tutorialsApi = useApi(tutorialsApiRef);

    const { value: filteredData, loading, error } = useAsync(async () => {
        const data = await tutorialsApi.getTutorialsMetadata();

        const shuffled = _shuffle(data.filter((item: any) => item.isFeatured));

        return shuffled.slice(0, 3);
    }, []);


    if (loading) {
        return <Progress />;
    } else if (error) {
        return <Alert severity="error">{error.message}</Alert>;
    } else if (!filteredData) {
        // TODO: add placeholder
        return null;
    }

    return <FeaturedTraining items={filteredData} />
};

import React from 'react';
import cn from 'classnames';
import {
    Typography,
    Box,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {Link} from '../../../Link';

import {useStyles} from './styles';

type Props = {
    to: string;
    title: string;
    label: string;
    position?: 'left' | 'right';
}

export const ArticlePaginationLink = ({
    to,
    title,
    label,
    position = 'left',
}: Props) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Link to={to}>
                <Box className={position === 'left' ? classes.left : classes.right}>
                    <Typography variant="caption" className={classes.label}>
                        {label}
                    </Typography>
                    <Typography variant="body1" className={classes.title}>
                        {title}
                    </Typography>
                    <ArrowForwardIcon
                        color="secondary"
                        className={cn(
                            classes.icon,
                            {
                                [classes.iconLeft]: position === 'left',
                                [classes.iconRight]: position === 'right'
                            }
                        )}
                    />
                </Box>
            </Link>
        </Box>
    );
};

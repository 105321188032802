import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    header2:{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
    },
    toggleButton: {
        margin: theme.spacing(-1.5),
    },
    toggleButtonActive: {
        transform: 'rotate(180deg)',
    },
    Close: {
        alignItems: 'start'
    },
    // toggleButtons: {
    //     margin: theme.spacing(-1.5),
    // },
    // toggleButtonActives: {
    //     transform: 'rotate(180deg)',
    // },
}));
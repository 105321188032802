import {plugin} from './plugin';
import {newsPageRouteRef} from './routeRefs';
import { createRoutableExtension } from '@backstage/core-plugin-api';

export const NewsPage = plugin.provide(
    createRoutableExtension({
        component: () => import('./pages').then(m => m.NewsPage),
        mountPoint: newsPageRouteRef,
    }),
);

import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import createMixins from '@material-ui/core/styles/createMixins';
import {
    pageTheme as defaultPageThemes,
} from "@backstage/theme";
import {
    SimpleThemeOptions,
    TMNATheme,
    TMNAThemeMixins,
    TMNAThemeOptions,
} from './types';
import {
    createTMNAThemeOverrides,
    TMNAMixins,
    TMNAThemeProps,
    TMNATypography,
} from './themeOverrides';
import {Breakpoints} from '@material-ui/core/styles/createBreakpoints';
import {Spacing} from '@material-ui/core/styles/createSpacing';

export function createThemeOptions(
    options: SimpleThemeOptions,
): TMNAThemeOptions {
    const {
        palette,
        fontFamily,
        defaultPageTheme,
        pageTheme = defaultPageThemes,
    } = options;

    if (!pageTheme[defaultPageTheme]) {
        throw new Error(`${defaultPageTheme} is not defined in pageTheme.`);
    }

    return {
        palette,
        props: TMNAThemeProps,
        typography: {
            fontFamily,
            ...TMNATypography,
        },
        page: pageTheme[defaultPageTheme],
        getPageTheme: ({ themeId }) =>
            pageTheme[themeId] ?? pageTheme[defaultPageTheme],
    };
}

const createThemeMixins = (
    breakpoints: Breakpoints,
    spacing: Spacing,
): TMNAThemeMixins => {
    return {
        ...createMixins(breakpoints, spacing, {}),
        ...TMNAMixins
    }
}

export function createTheme(options: SimpleThemeOptions): TMNATheme {
    const themeOptions = createThemeOptions(options);
    const baseTheme = createMuiTheme(themeOptions) as TMNATheme;
    const mixins = createThemeMixins(baseTheme.breakpoints, baseTheme.spacing)
    const overrides = createTMNAThemeOverrides(baseTheme);
    const theme = { ...baseTheme, overrides, mixins };
    return theme;
};

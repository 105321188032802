/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { useUserProfile } from '../useUserProfileInfo';
import { Avatar } from '@backstage/core-components';

type Props = { size?: number };

export const UserSettingsSignInAvatar = ({ size }: Props) => {
  
  const { profile, displayName } = useUserProfile();

  const customStyles = React.useMemo(() => ({
    width: size,
    height: size,
    fontSize: '14px',
    background: 'linear-gradient(180deg, #F71840 0%, #FF9265 100%)',
  }), [size]);

  return (
    <Avatar
      picture={profile.picture}
      displayName={displayName}
      customStyles={customStyles}
    />
  );
};

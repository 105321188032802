import React from 'react';
import { Header } from '../Header';

interface PageLayoutProps {
    // TODO: remove prop drilling
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    type?: 'catalog' | 'entity';
    headerAdditionalControls?: JSX.Element;
    headerExtendedContent?: JSX.Element;
    backToLink?: {
        to: string;
        label: string;
    };
    headerBackgroundImg?: string;
    children: React.ReactNode;
    displayButton?: boolean
}

export const PageLayout = ({
    title,
    subtitle,
    type = 'catalog',
    headerAdditionalControls,
    headerExtendedContent,
    backToLink,
    headerBackgroundImg,
    children,
    displayButton,
}: PageLayoutProps) => {

    return (
        <>
            <Header
                type={type}
                title={title}
                subtitle={subtitle}
                additionalControls={headerAdditionalControls}
                extendedContent={headerExtendedContent}
                backToLink={backToLink}
                backgroundImg={headerBackgroundImg}
                displayButton={displayButton}

            />
            {children}
        </>
    )
};
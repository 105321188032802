import React from 'react';

import {notificationApiRef} from '../../api';
import { useApi } from '@backstage/core-plugin-api';

type NotificationsData = {
    unreadCount?: number;
}

export const NotificationsContext = React.createContext<NotificationsData>({});

const TIME_INTERVAL_DELAY_MS = 120000;

type Props = {
    children: React.ReactNode;
}

// TODO: find better solution to share notifications metadata between different modules
export const NotificationsProvider = ({
    children
}: Props) => {
    const notificationApi = useApi(notificationApiRef);
    const [notificationsData, setNotificationsData] = React.useState<NotificationsData>({});

    React.useEffect(() => {
        const fetchUnreadNotificationsCount = async () => {
            const result = await notificationApi.fetchUnreadNotificationsCount()

            setNotificationsData({
                unreadCount: result,
            });
        }
        const interval = setInterval(fetchUnreadNotificationsCount, TIME_INTERVAL_DELAY_MS);
        fetchUnreadNotificationsCount();

        return () => clearInterval(interval);
    }, []);

    return (
        <NotificationsContext.Provider value={notificationsData}>
            {children}
        </NotificationsContext.Provider>
    );
};

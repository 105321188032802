import {CloudAccountChargebackDetailsPeriod} from 'tmna-types';
import {DatePeriod} from './types';

export const mapMonthsToDatePeriods = (months: string[]): DatePeriod[] => (
    months
        .map((month) => ({
                value: month,
                label: (month.length === 7) ? month : `${month.slice(0,5)}0${month.slice(-1)}`,
                // label: month,
            }
        ))
        .sort((a, b) => a.label < b.label ? 1 : -1)
      // .sort((a: any, b: any) => new Date(b.metadata?.date ? b.metadata?.date :new Date() ).valueOf() - new Date(a.metadata?.date ? a.metadata?.date : new Date() ).valueOf()
);

export const mapChargebacksToTableData = (chargebacks: CloudAccountChargebackDetailsPeriod[]) => {
    if (chargebacks?.length === 0) return [];

    return chargebacks.map(chargeback => ({
        ...chargeback,
        ...Object.keys(chargeback.period).reduce(
            (acc, periodName) => ({
                ...acc,
                [periodName]: chargeback.period[periodName]
            }),{}
        )
    }))
};
export const getTotal = (months : string[], chargebacks: CloudAccountChargebackDetailsPeriod[]): IDictionary => {
    let totals: IDictionary = {}
    months.map(month => {
        let total = chargebacks.map(item => item.period[month]).map(Number).reduce((x,y) => x+y, 0);
        let roundedTotal = total.toFixed(2);
        let USDollar = new Intl.NumberFormat('en-US');
        totals[month] = USDollar.format(Number(roundedTotal.toString()));
    })
    return totals;
};

export interface IDictionary {
    [key: string]: string;
}

import React from 'react';
import {Card, CardProps} from '@material-ui/core';
import cn from 'classnames';
import {useNavigate} from 'react-router-dom';
import {getLinkTarget} from 'tmna-utils';

import {useStyles} from './styles';

type Props = {
    to?: string;
    onClick?: Function;
    target?: '_blank' | '_self';
    color?: 'light' | 'dark';
    children?: React.ReactNode;
    className?: string;
}

export const CardLink = ({
    to,
    onClick,
    target,
    color = 'light',
    children,
    className,
}: Props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const handleCardClick: CardProps['onClick'] = React.useCallback((e) => {
        if (to) {
            const linkTarget = target ? target : getLinkTarget(to);

            if (linkTarget === '_blank') {
                window.open(to, linkTarget);
            } else {
                navigate(to);
            }
        }
        if (onClick) {
            onClick(e);
        }
    }, [navigate, to, onClick, target]);

    return (
        <Card
            className={cn(classes.container, className, {
                [classes.containerLight]: color === 'light',
                [classes.containerDark]: color === 'dark',
            })}
            onClick={handleCardClick}
        >
            {children}
        </Card>
    )
};

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    author__name: {
        fontWeight: theme.typography.fontWeightBold as any,
    },
    author__jobTitle: {
        fontSize: '14px',
    },
    title: {
        fontWeight: theme.typography.fontWeightBold as any,
    },
    date: {
        fontWeight: theme.typography.fontWeightMedium as any,
    },
    link: {
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: 500,
    },
    chipsContainer: {
        marginBottom: theme.spacing(-1),

        
    },
    customChip:{
        backgroundColor:'darkgray',
    },
    dateContainer: {
        marginBottom: theme.spacing(2),
    },
}));

import { makeStyles } from '@material-ui/core';
import { TMNATheme } from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>({
  buttonCustom: {
    color: 'white',
    fontSize: '17px',
    marginRight: '10px',
    cursor: 'pointer',
    display: 'flex',
    '&:hover': {
      color: 'red !important'
    },
    '&:hover svg:hover': {
      fill: 'red !important'
    }
  },
  MenuIcon:{
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '-35px',
  },
  filterIcon:{
    marginRight: '4px'
  }

});
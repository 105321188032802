import React from 'react';
import {useAsyncFn} from 'react-use';
import cn from 'classnames';
import {
    Chip,
    Typography,
    Box,
    Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {formatDate} from 'tmna-utils';
import {MarkdownContent} from 'tmna-ui-kit';

import {newsApiRef} from '../../api';
import {useStyles} from './styles';
import Alert from '@material-ui/lab/Alert';

import { useApi } from '@backstage/core-plugin-api';
import { Progress } from '@backstage/core-components';

type Props = {
    id: string;
    tags?: string[];
    title: string;
    description: string;
    date: string;
    isInitiallyActive?: boolean;
}

export const NewsCard = ({
    id,
    tags,
    title,
    description,
    date,
    isInitiallyActive = false,
}: Props) => {
    const classes = useStyles();
    const elementRef = React.useRef<HTMLDivElement>(null);
    const newsApi = useApi(newsApiRef);
    const [isContentVisible, setIsContentVisible] = React.useState<boolean>(false);

    const [{value: content, loading: isContentLoading, error}, fetchContent] = useAsyncFn(async () => {
        return await newsApi.getNewsContent(id);
    }, [id]);

    React.useEffect(() => {
        if (isInitiallyActive) {
            setIsContentVisible(true);
            fetchContent();

            if (elementRef.current) {
                elementRef.current.scrollIntoView();
            }
        }
    }, []);

    const toggleContent = React.useCallback(async () => {
        const isContentVisibleNextValue = !isContentVisible;
        setIsContentVisible(isContentVisibleNextValue);

        if (!content && isContentVisibleNextValue) {
            fetchContent();
        }
    }, [content, fetchContent, isContentVisible]);

    const contentBlock = React.useMemo(() => {
        if (isContentLoading) {
            return <Progress />;
        }
        if (error) {
            return <Alert severity="error">{error.message}</Alert>;
        }
        if (content) {
            return (
                <div className={classes.content}>
                    <MarkdownContent content={content} />
                </div>
            );
        }
        return <Typography variant="body2">No content</Typography>;
    }, [content, isContentLoading, error]);

    return (
        <div ref={elementRef}>
            {tags && <Box mb={2}>
                {tags.map((tag) => (
                    <Chip
                        key={tag}
                        size="small"
                        label={tag}
                        className={classes.tag}
                    />
                ))}
            </Box>}
            <Box mb={2}>
                <Typography variant="h3">
                    {title}
                </Typography>
            </Box>
            {description && (
                <Box mb={3}>
                    <Typography className={classes.description} variant="body1">
                        {description}
                    </Typography>
                </Box>
            )}
            <Typography variant="caption">
                {formatDate(date, false)}
            </Typography>

            <Box mt={3}>
                <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    onClick={toggleContent}
                    spacing={0}
                    className={classes.button}
                >
                    <Grid item>
                        <Typography variant="button">
                            Read more
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ExpandMoreIcon className={cn(classes.icon, {[classes.expandedIcon]: isContentVisible})}/>
                    </Grid>
                </Grid>
            </Box>
            {isContentVisible && (
                <Box mt={3}>
                    {contentBlock}
                </Box>
            )}
        </div>
    );
};

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    container: {
        '& > svg': {
            display: 'inline',
            fontSize: '16px',
        }
    },
    warning: {
        color: theme.palette.tmnaColors.secondary.orange,
    },
}));
import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

const useStyles = makeStyles((theme: TMNATheme) => ({
    label: {
        fontWeight: theme.typography.fontWeightBold as any,
        marginBottom: theme.spacing(0.5),
    }
}));

type Props = {
    label: string;
    value: React.ReactNode;
}

export const HeaderLabel = ({
    label,
    value,
}: Props) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant='body2' className={classes.label}>
                {label}
            </Typography>
            <Typography variant='body2' component="div">
                {value}
            </Typography>
        </>
    );
};

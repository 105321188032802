import {plugin} from './plugin';

import {costCenterPageRouteRef} from './routeRefs';
import { createRoutableExtension } from '@backstage/core-plugin-api';

export const CostCenterPage = plugin.provide(
    createRoutableExtension({
        component: () => import('./pages').then(m => m.CostCenterPage),
        mountPoint: costCenterPageRouteRef,
    }),
);

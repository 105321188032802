/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {UserSettingsGeneral} from './General';
import { UserSettingsDefault } from './General/UserSettingDefault';
import {Header} from './Header';
import {useUserProfile} from './useUserProfileInfo';
import {Box} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && 
        (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )
      }
    </div>
  );
} 
function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}
export const SettingsPage = () => {  
  const { displayName } = useUserProfile(); 
  const location: any = useLocation();
  const { state: routervalues } = location;
  const [value, setValue] = React.useState(routervalues?.tabId ? routervalues?.tabId :'one');

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Header
        title={`Welcome, ${displayName}`}
        subtitle="Manage your settings to make the Chofer Cloud Portal work better for you"
      />
      <Tabs  value={value} onChange={handleChange} aria-label="" >
          <Tab value="one" label="Profile" {...a11yProps('one')} />
          <Tab value="two"  label="Set Chofer Defaults" {...a11yProps('two')}/>
        </Tabs>
      <TabPanel value={value} index="one">
        <Box m={2}>
            <UserSettingsGeneral />
          </Box>
      </TabPanel>
      <TabPanel value={value} index="two">
          <Box m={2}>
            <UserSettingsDefault /> 
          </Box>
      </TabPanel>
    </>
  );
};

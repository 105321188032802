import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    feedbackSection: {
    },
    feedbackButton: {
        margin: theme.spacing(),
        marginTop: '-5px',
        marginBottom: '0px',
        whiteSpace: 'nowrap',
        backgroundColor: 'white',
        color: '#CC0000',
        '&:hover': {
            backgroundColor: 'white',
            color: '#8c0000'
        }
    },
    buttonCustom:{
      color:'white',
      fontSize: '17px',
      marginRight: '20px',
      cursor: 'pointer',
      display: 'flex',
      '&:hover': {
        color: 'red !important'
      },
      '&:hover svg':{
        fill: 'red !important'
      }
    },
    button: {
        margin: theme.spacing(),
        marginTop: '-5px',
        marginBottom:'0px',
        whiteSpace:'nowrap',
        width: '230px'
      },
      PaperProps: {
      },
      typography: {
        marginBottom:'10px' 
      },
      Links:{
        //fontWeight: 'bold' ,
        fontSize: '14px'
      }
}));
/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  Box,
  Checkbox,
  Chip,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  Select as MuiSelect,
  InputLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: 'Helvetica Neue',
      '&:focus': {
        background: theme.palette.background.paper,
        borderRadius: 4,
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      transform: 'initial',
      fontWeight: 'bold',
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      '&.Mui-focused': {
        color: theme.palette.text.primary,
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(-1),
      // maxHeight:'50px',
      // overflowY:'scroll',
    },
    chip: {
      overflow: 'hidden',
    },
    checkbox: {},
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    dropdownIcon: {
      position: 'absolute',
      right: theme.spacing(0.5),
    },
    dropdownIconOpened: {
      transform: 'rotate(180deg)',
    },
    inputValue: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

type Item = {
  label: string;
  value: string | number;
};

type Selection = string | string[] | number | number[];

export type SelectProps = {
  isMultiple?: boolean;
  items: Item[];
  label: string;
  helperText?: string;
  placeholder?: string;
  selected?: Selection;
  onChange: (arg: Selection) => void;
  triggerReset?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  getEmptyValue?: (isMultiple: boolean) => any;
};

export const Select = ({
  isMultiple = false,
  items,
  label,
  helperText,
  placeholder,
  selected,
  onChange,
  triggerReset,
  isLoading = false,
  isDisabled = false,
  getEmptyValue = (isMultiple: boolean) => isMultiple ? [] : '',
}: SelectProps) => {
  const classes = useStyles();
  const [value, setValue] = useState<Selection>(
    selected || getEmptyValue(isMultiple),
  );
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setValue(getEmptyValue(isMultiple));
  }, [triggerReset, isMultiple]);

  useEffect(() => {
    if (selected !== undefined) {
      setValue(selected);
    }
  }, [selected]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as Selection);
    onChange(event.target.value as Selection);
  };

  const handleClick = (event: React.ChangeEvent<any>) => {
    setOpen(previous => {
      if (isMultiple && !(event.target instanceof HTMLElement)) {
        return true;
      }
      return !previous;
    });
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleDelete = (selectedValue: string | number) => () => {
    const newValue = (value as any[]).filter(chip => chip !== selectedValue);
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={classes.root}>
      <InputLabel disabled={isDisabled}>
        {label}
      </InputLabel>
      <ClickAwayListener onClickAway={handleClickAway}>
        <FormControl>
          <MuiSelect
            label={"label"}
            value={value}
            data-testid="select"
            displayEmpty
            multiple={isMultiple}
            onChange={handleChange}
            onClick={handleClick}
            open={isOpen}
            input={<BootstrapInput />}
            disabled={isDisabled}
            renderValue={s =>
              isMultiple && (value as any[]).length !== 0 ? (
                <div className={classes.chips} >
                  {(s as string[]).map(selectedValue => (
                    <Chip
                      key={items.find(el => el.value === selectedValue)?.value}
                      label={
                        items.find(el => el.value === selectedValue)?.label
                      }
                      clickable
                      onDelete={handleDelete(selectedValue)}
                      className={classes.chip}
                      size="small"
                    />
                  ))}
                </div>
              ) : (
                <Typography className={classes.inputValue}>
                  {(value as any[]).length === 0
                    ? placeholder || ''
                    : items.find(el => el.value === s)?.label}
                </Typography>
              )
            }
            IconComponent={() => (
                <ExpandMoreIcon
                    className={cn(classes.dropdownIcon, {
                      [classes.dropdownIconOpened]: isOpen
                    })}
                />
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {isLoading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {placeholder && !isMultiple && (
              <MenuItem value={getEmptyValue(false)}>{placeholder}</MenuItem>
            )}
            {items &&
              items.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {isMultiple && (
                    <Checkbox
                      color="primary"
                      checked={(value as any[]).includes(item.value) || false}
                      className={classes.checkbox}
                    />
                  )}
                  {item.label}
                </MenuItem>
              ))}
          </MuiSelect>
        </FormControl>
      </ClickAwayListener>
      <FormHelperText disabled={isDisabled}>
        {helperText}
      </FormHelperText>
    </div>
  );
};

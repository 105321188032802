import { useTranslationRef } from '@backstage/core-plugin-api/alpha';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect } from 'react';
import { createTranslationRef } from '@backstage/core-plugin-api/alpha';

export const coreComponentsTranslationRef = createTranslationRef({
  id: 'core-components',
  messages: {
    alertDisplay: {
      message_one: '({{ count }} older message)',
      message_other: '({{ count }} older messages)',
    },
  },
});

export type CustomAlertDisplayProps = {
  anchorOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  transientTimeoutMs?: number;
  setMessages: React.Dispatch<React.SetStateAction<CustomAlertMessage[]>>;
  messages: CustomAlertMessage[];
};

export type CustomAlertMessage = {
  message: string | JSX.Element;
  severity?: 'success' | 'info' | 'warning' | 'error';
  display?: 'permanent' | 'transient';
  autoHideDuration?: number | null;
};

export function CustomAlertDisplay(props: CustomAlertDisplayProps) {
  const { t } = useTranslationRef(coreComponentsTranslationRef);

  const {
    anchorOrigin = { vertical: 'top', horizontal: 'center' },
    transientTimeoutMs,
    messages,
    setMessages,
  } = props;
  const [firstMessage] = messages;

  const timeoutMs =
    firstMessage?.autoHideDuration ?? transientTimeoutMs ?? 5000;

  useEffect(() => {
    if (firstMessage && firstMessage.display === 'transient') {
      const timeout = setTimeout(() => {
        setMessages(msgs => {
          const newMsgs = msgs.filter(msg => msg !== firstMessage);
          return newMsgs.length === msgs.length ? msgs : newMsgs;
        });
      }, timeoutMs);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [firstMessage, setMessages, timeoutMs]);

  if (messages.length === 0) {
    return null;
  }

  const handleClose = () => {
    setMessages(msgs => msgs.filter(msg => msg !== firstMessage));
  };

  return (
    <Snackbar open anchorOrigin={anchorOrigin}>
      <Alert
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={handleClose}
            data-testid="error-button-close"
          >
            <CloseIcon />
          </IconButton>
        }
        severity={firstMessage.severity}
      >
        <Typography component="span">
          {firstMessage.message}
          {messages.length > 1 && (
            <em>
              {t('alertDisplay.message', {
                count: messages.length - 1,
              })}
            </em>
          )}
        </Typography>
      </Alert>
    </Snackbar>
  );
}

/*
 * Copyright 2021 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { useAsync } from 'react-use';
import { Typography, Box } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import {
  CATALOG_FILTER_EXISTS,
  catalogApiRef,
  CatalogApi,
} from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { DocsCardGrid } from './DocsCardGrid';
import { FeaturedTechDocs } from './FeaturedTechDocs';
import { getFeaturedTechdocs } from './utils';
import {
  PageLayout,
  Content,
  SupportButton,
  SupportTextList,
  SupportLinks,
  SupportLinkCard,
} from 'tmna-ui-kit';
// import { createComponentExternalRouteRef } from './routes';
import VideocamIcon from '@material-ui/icons/Videocam';
import  * as plugins  from '../../plugins'

import { Button, CodeSnippet, ContentHeader, Progress, WarningPanel } from '@backstage/core-components';
import {  useApi, useRouteRef } from '@backstage/core-plugin-api';

export type PanelType = 'DocsCardGrid' | 'DocsTable';

export const createComponentExternalRouteRef = plugins.ScaffolderPlugin.routes.root;

const TECHDOCS_SUPPORT_LINK = 'https://web.microsoftstream.com/video/9b3b7c56-71bb-44e7-b529-871437ca6785';

export interface PanelConfig {
  title: string;
  description: string;
  panelType: PanelType;
  panelCSS?: CSSProperties;
  filterPredicate: ((entity: Entity) => boolean) | string;
}

export interface TabConfig {
  label: string;
  panels: PanelConfig[];
}

export type TabsConfig = TabConfig[];

const CustomPanel = ({
  entities,
}: {
  entities: Entity[];
}) => {
  const createComponentLink = useRouteRef(createComponentExternalRouteRef)();

  const titleComponent = React.useCallback(() => (
    <Typography variant="h2">Available Docs</Typography>
  ), []);

  return (
    <Content>
      <Box mb={4}>
        <ContentHeader titleComponent={titleComponent}>
          <Button
            variant="contained"
            color="primary"
            to={createComponentLink}
          >
            Create Tech Docs Component
          </Button>
          <SupportButton>
            <SupportTextList>
              <Typography variant="body2">
                A centralized library of all documentation related to TMNA Cloud Platform.
              </Typography>

            </SupportTextList>
            <SupportLinks>
              <SupportLinkCard
                icon={<VideocamIcon />}
                to={TECHDOCS_SUPPORT_LINK}
                title="Chofer TechDocs"
                subtitle="Watch training"
              />
            </SupportLinks>
          </SupportButton>
        </ContentHeader>
      </Box>
      <DocsCardGrid entities={entities} />
    </Content>
  );
};

export const TechDocsCustomHome = () => {
  const catalogApi: CatalogApi = useApi(catalogApiRef);

  const { value: entities, loading, error } = useAsync(async () => {
    const response = await catalogApi.getEntities({
      filter: {
        'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS,
      },
      fields: [
        'apiVersion',
        'kind',
        'metadata',
        'relations',
        'spec.owner',
        'spec.type',
      ],
    });
    return response.items.filter((entity: Entity) => {
      return !!entity.metadata.annotations?.['backstage.io/techdocs-ref'];
    });
  });

  const content = React.useMemo(() => {
    if (loading) {
      return (
        <Content>
          <Progress />
        </Content>
      );
    }

    if (error) {
      return (
        <Content>
          <WarningPanel
            severity="error"
            title="Could not load available documentation."
          >
            <CodeSnippet language="text" text={error.toString()} />
          </WarningPanel>
        </Content>
      );
    }

    return <CustomPanel entities={!!entities ? entities : []} />;
  }, [loading, error, entities]);

  const headerPromoContent = React.useMemo(() => (
    <FeaturedTechDocs techdocs={entities ? getFeaturedTechdocs(entities) : undefined} />
  ), [entities]);

  return (
    <PageLayout
      title="Documentation"
      subtitle="Explore the Chofer Portal documentation"
      headerExtendedContent={headerPromoContent}
      headerBackgroundImg="assets/docs_bg.png"
      displayButton={true}
    >
      {content}
    </PageLayout>
  );
};

import { NotificationApi, notificationApiRef } from './api';

import {homeExternalRouteRef, notificationRouteRef} from './routeRefs';
import {
  createPlugin,
  createApiFactory,
  configApiRef,
  identityApiRef,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

export const plugin = createPlugin({
  id: 'notifications',
  routes: {
    root: notificationRouteRef,
  },
  externalRoutes: {
    home: homeExternalRouteRef,
  },
  apis: [
    createApiFactory({
      api: notificationApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ configApi, identityApi }) =>
        new NotificationApi({ identityApi, configApi  }),
    }),
  ],
});

export const NotificationsPage = plugin.provide(
  createRoutableExtension({
    component: () =>
      import('./components/NotificationPage').then(m => m.NotificationPage),
    mountPoint: notificationRouteRef,
  }),
);

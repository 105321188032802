import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    button: {
        margin: theme.spacing(),
        marginTop: '-5px',
        marginBottom:'0px',
        whiteSpace:'nowrap',
        width: '230px'
      },
      buttonCustom:{
        color:'white',
        fontSize: '17px',
        marginRight: '20px',
        cursor: 'pointer',
        display: 'flex',
        '&:hover': {
          color: 'red !important'
        },
        '&:hover svg:hover':{
          fill: 'red !important'
        }
      },
      PaperProps: {
      },
      typography: {
        marginBottom:'10px' 
      },
      Links:{
        //fontWeight: 'bold' ,
        fontSize: '14px'
      }
}));
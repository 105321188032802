import React from 'react';
import cn from 'classnames';
import {IconButton} from '@material-ui/core';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';

import {useStyles} from './styles';

type Props = {
    onClick: () => void;
}

export const BackToTopButton = ({
    onClick,
}: Props) => {
    const classes = useStyles();

    return (
        <IconButton
            aria-label="upload picture"
            component="span"
            className={cn(classes.container)}
            onClick={onClick}
        >
            <KeyboardTabIcon />
        </IconButton>
    );
};
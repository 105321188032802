import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { Config } from '@backstage/config';

type InsightsApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
};

interface IInsightsApi {
  fetchGuestToken(): Promise<{ token: string }>;
}

export const insightsApiRef = createApiRef<IInsightsApi>({
  id: 'plugin.insights',
});

export class InsightsApi implements IInsightsApi {
  private configApi: Config;
  private identityApi: IdentityApi;

  constructor(options: InsightsApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
  }

  async fetchGuestToken() {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/insights/fetchGuestToken`;
    const response = await fetch(requestUrl, {
      method: 'GET',
      credentials: 'include',
      headers: { Authorization: `Bearer ${token.token}` },
    });
    return response.json();
  }
}

import { makeStyles } from '@material-ui/core';
import { TMNATheme } from 'tmna-theme';

const removeNumberSpin = {
  '& .MuiFormControl-root input': {
    textAlign: 'unset !important',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      display: 'none',
    },
  },
};

const cellBorderTop = '3px';
export const useStyles = makeStyles<TMNATheme>(theme => ({
  edgeTableDiv: {
    '& .MuiTable-root': {
      '& .MuiTableCell-root': {
        paddingLeft: '16px !important',
        paddingRight: '16px !important',
      },

      '& > .MuiTableBody-root': {
        ...removeNumberSpin,
        '& .MuiFormHelperText-root': {
          marginRight: 0,
          marginTop: '5px',
        },
      },
    },
  },
  tableHeaderStyle: {
    '& > .MuiTableRow-root': {
      '& > .MuiTableCell-root': {
        color: theme.palette.tmnaColors.primary.black,
      },
    },
  },
  tableHeader: {
    '& > .MuiTableRow-root': {
      '&.borderTop': {
        borderTop: '3px solid white',
      },
      '& > .MuiTableCell-root': {
        backgroundColor: theme.palette.tmnaColors.secondary.slateGray,
        color: theme.palette.tmnaColors.primary.black,
        padding: `${theme.spacing(0.7)}px !important`,
        borderRight: '1px solid white',
        '&.noBorderRight': {
          borderBottom: 'unset',
        },
        '&.borderRight': {
          borderRight: '3px solid white',
        },
        '&.rowSpanBottom': {
          borderBottom: '3px solid white',
          overflow: 'unset',
        },
      },
    },
  },
  selectFormControl: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
    '& > .MuiFormLabel-root': {
      padding: 'unset',
      whiteSpace: 'nowrap',
      fontSize: theme.spacing(2),
      marginTop: theme.spacing(1.5),
    },
    '& > div': {
      width: '100%',
      '& > .MuiFormLabel-root': {
        display: 'none',
      },
      '& > .MuiFormControl-root > .MuiInputBase-root': {
        width: '100%',
        '& > .MuiSelect-root': {
          minHeight: theme.spacing(3),
        },
      },
    },
  },
  totalCostRow: {
    '& > .MuiTableCell-root': {
      backgroundColor: theme.palette.tmnaColors.secondary.slateGray,
      color: theme.palette.tmnaColors.primary.black,
    },
  },
  totalStorageRow: {
    '& > .MuiTableCell-root': {
      '&.borderTopGray': {
        borderTop: `${cellBorderTop} solid ${theme.palette.tmnaColors.primary.black}`,
      },
      '&.borderTopDynamic': {
        borderTop: `${cellBorderTop} solid #f7f7f7`,
      },
    },
  },
  editableCell: {
    padding: theme.spacing(0.375, 2),
    cursor: 'pointer',
    '& > .borderDiv': {
      minWidth: 'inherit',
      padding: theme.spacing(0.625, 1),
      background: 'white',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.tmnaColors.secondary.mediumGray}`,
      textAlign: 'end',
      '&:hover': {
        border: `1px solid ${theme.palette.tmnaColors.secondary.slateGray}`,
      },
    },
    '& > .customEditContainer': {
      display: 'flex',
      alignItems: 'center',
      '& > .customEditDiv': {
        flex: '1 1 0%',
        marginRight: '4px',
        ...removeNumberSpin,
      },
    },
  },
  customMuiTableBody: {
    '&.even': {
      '& > .MuiTableRow-root:nth-of-type(odd)': {
        backgroundColor: theme.palette.tmnaColors.primary.white,
        '& > .MuiTableCell-root.borderTopDynamic': {
          borderTop: `${cellBorderTop} solid ${theme.palette.tmnaColors.primary.white}`,
        },
      },
      '& > .MuiTableRow-root:nth-of-type(even)': {
        backgroundColor: '#F7F7F7',
      },
    },
  },
  calculateButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > .calculateButton': {
      height: 'auto',
    },
  },
  disclaimerText: {
    display: 'flex',
    flexDirection: 'row',
  },
  quickLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > .MuiPaper-root': {
      width: '100%',
    },
  },
  totalStorageError: {
    color: theme.palette.tmnaColors.primary.red,
  },
  topItem: {
    paddingBottom: '0px !important',
    '& > .topContainer': { alignItems: 'center' },
  },
  downloadIcon: {
    padding: '12px 10px 8px 12px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.75rem',
    },
  },
}));

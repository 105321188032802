import React from 'react';
import {TextField, TextFieldProps, Chip} from '@material-ui/core';
import {useStyles} from './styles';

type Props = TextFieldProps & {
    tags?: string[];
    onChange: (values: string[]) => void;
    value: string[];
    maxValuesNumber?: number;
}

enum KeyboardKeys {
    ENTER = 'Enter',
    SPACE = ' ',
    BACKSPACE = 'Backspace',
}

export const MultiInput = ({
    tags = [],
    onChange,
    value: values,
    maxValuesNumber,
    ...restProps
}: Props) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = React.useState("");

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            inputValue.length &&
            (event.key === KeyboardKeys.ENTER || event.key === KeyboardKeys.SPACE)
        ) {
            const duplicatedValues = values.indexOf(inputValue.trim());

            if (duplicatedValues !== -1) {
                setInputValue("");
                return;
            }
            if (!inputValue.replace(/\s/g, "").length) {
                return;
            }

            onChange([...values, inputValue.trim()]);
            setInputValue("");
        }
        if (
            event.key === KeyboardKeys.BACKSPACE &&
            values.length &&
            !inputValue.length
        ) {
            onChange(values.slice(0, values.length - 1));
        }
    }
    const handleBlur = () => {
        if (inputValue && values.indexOf(inputValue) === -1) {
            onChange([...values, inputValue]);
        }
        setInputValue("");
    }

    const handleDelete = (value: string) => {
        onChange(values.filter(v => v !== value));
    };

    const handleInputChange = (event: React.ChangeEvent<{ value: string }>) => {
        setInputValue(event.target.value);
    }

    return (
        <TextField
            {...restProps}
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={maxValuesNumber ? values.length >= maxValuesNumber : false}
            className={classes.input}
            fullWidth
            InputProps={{
                startAdornment: (
                    <>
                        {values.map((value) => (
                            <Chip
                                key={value}
                                label={value}
                                tabIndex={-1}
                                className={classes.chip}
                                onDelete={() => handleDelete(value)}
                                clickable
                                size="small"
                            />
                        ))}
                    </>
                ),
            }}
        />
    );
}
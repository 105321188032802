import React from 'react';
import { useStyles } from './styles';
import { MenuItem, Menu } from '@material-ui/core';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export const ReinventButton = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMsg, setOpenMsg] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMsg(false);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className={classes.MenuIcon}>
      <a className={classes.buttonCustom} onClick={handleClick}>
        <FilterDramaIcon className={classes.filterIcon} /> AWS Re:Invent 2024
      </a>
      <ReinventMenuOption
        anchorEl={anchorEl}
        handleClose={handleClose}
        setOpenMsg={setOpenMsg}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={classes.alerts}
        open={openMsg}
        autoHideDuration={5000}
        onClose={handleClose2}
      >
        <Alert onClose={handleClose2} severity={'info'}>
          <div className={classes.alertHeader}> Discount code copied</div>
        </Alert>
      </Snackbar>
    </div>
  );
};

export const ReinventMenuOption = ({
  anchorEl,
  handleClose,
  setOpenMsg,
}: any) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          navigator.clipboard.writeText('SALoclsugkeu');
          setOpenMsg(true);
        }}
        style={{ color: 'black' }}
      >
        <strong>Discount Code ($150 off):</strong>{' '}
        <span style={{ fontStyle: 'italic' }}>SALoclsugkeu </span>
      </MenuItem>
      <MenuItem>
        <a
          href="https://reinvent.awsevents.com/?trk=direct"
          onClick={handleClose}
          target="_blank"
        >
          Registration link
        </a>
      </MenuItem>
      <MenuItem>
        <a
          href="https://airtable.com/appUcIU3VBaUeHDEm/pag8Lun7aOn8nk7LK/form"
          onClick={handleClose}
          target="_blank"
        >
          Group ticket discount
        </a>
      </MenuItem>
    </Menu>
  );
};

/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { compact } from 'lodash';
import {
  UserListFilter,
  UserListFilterKind,
  useEntityList,
  useStarredEntities,
  useEntityOwnership,
  EntityFilter,
} from '@backstage/plugin-catalog-react';
import {
  Card,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import CategoryIcon from '@material-ui/icons/Category';
import { TMNATheme } from 'tmna-theme';
import { IconComponent } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';

export type ButtonGroup = {
  name: string;
  items: {
    id: string;
    label: string;
    icon?: IconComponent;
  }[];
};

const useStyles = makeStyles<TMNATheme>(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .11)',
    boxShadow: 'none',
  },
  title: {
    margin: theme.spacing(1, 0, 0, 1),
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
  },
  listIcon: {
    minWidth: 30,
    color: theme.palette.text.primary,
  },
  listIconActive: {
    color: theme.palette.tmnaColors.primary.red,
  },
  menuItem: {
    minHeight: theme.spacing(6),

    '&:hover': {
      backgroundColor: theme.palette.tmnaColors.secondary.lightGray,
    },
  },
  groupWrapper: {
    margin: theme.spacing(1, 1, 2, 1),
  },
  menuTitle: {
    fontWeight: 500,
  },
  menuItemCount: {
    color: theme.palette.tmnaColors.secondary.slateGray,
  },
}));

function getFilterGroups(): ButtonGroup[] {
  return [
    {
      name: 'Personal',
      items: [
        {
          id: 'all',
          label: 'All',
          icon: () => <CategoryIcon fontSize="small" />,
        },
        // TODO unhide when ownership is established
        // {
        //   id: 'owned',
        //   label: 'Owned',
        //   icon: SettingsIcon,
        // },
        {
          id: 'starred',
          label: 'Starred',
          icon: StarIcon,
        },
      ],
    },
  ];
}

type UserListPickerProps = {
  initialFilter?: UserListFilterKind;
  availableFilters?: UserListFilterKind[];
};

/**
 * Sidebar filter type and human readable label for it. owned/starred/all
 */
export type CatalogFilterType = {
  id: string;
  label: string;
};

/**
 * The main filter group in the sidebar, toggling owned/starred/all.
 */
export const CustomUserListPicker = ({
  initialFilter,
  availableFilters,
}: UserListPickerProps) => {
  const classes = useStyles();

  // Remove group items that aren't in availableFilters and exclude
  // any now-empty groups.
  const filterGroups = getFilterGroups()
    .map(filterGroup => ({
      ...filterGroup,
      items: filterGroup.items.filter(
        ({ id }) => !availableFilters || availableFilters.includes(id as any),
      ),
    }))
    .filter(({ items }) => !!items.length);

  const {
    filters,
    updateFilters,
    backendEntities,
    queryParameters,
  } = useEntityList();
  const { isStarredEntity } = useStarredEntities();
  const { isOwnedEntity } = useEntityOwnership();
  const [selectedUserFilter, setSelectedUserFilter] = useState(
    [queryParameters.user].flat()[0] ?? initialFilter,
  );

  const reduceEntityFilters: any = (
    filtersList: EntityFilter[],
  ): ((entity: Entity) => boolean) => {
    return (entity: Entity) =>
      filtersList.every(
        filter => !filter.filterEntity || filter.filterEntity(entity),
      );
  };

  // Static filters; used for generating counts of potentially unselected kinds
  const ownedFilter = useMemo(
    () => new UserListFilter('owned', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );
  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  useEffect(() => {
    updateFilters({
      user: selectedUserFilter
        ? new UserListFilter(
            selectedUserFilter as UserListFilterKind,
            isOwnedEntity,
            isStarredEntity,
          )
        : undefined,
    });
  }, [selectedUserFilter, isOwnedEntity, isStarredEntity, updateFilters]);

  // To show proper counts for each section, apply all other frontend filters _except_ the user
  // filter that's controlled by this picker.
  const [entitiesWithoutUserFilter, setEntitiesWithoutUserFilter] = useState(
    backendEntities,
  );
  useEffect(() => {
    const filterFn = reduceEntityFilters(
      compact(Object.values({ ...filters, user: undefined })),
    );
    setEntitiesWithoutUserFilter(backendEntities.filter(filterFn));
  }, [filters, backendEntities]);

  function getFilterCount(id: UserListFilterKind) {
    switch (id) {
      case 'owned':
        return entitiesWithoutUserFilter.filter((entity: Entity) =>
          ownedFilter.filterEntity(entity),
        ).length;
      case 'starred':
        return entitiesWithoutUserFilter.filter((entity: Entity) =>
          starredFilter.filterEntity(entity),
        ).length;
      default:
        return entitiesWithoutUserFilter.length;
    }
  }

  return (
    <>
      {filterGroups.map(group => (
        <React.Fragment key={group.name}>
          <Card>
            <List disablePadding dense>
              {group.items.map(item => (
                <MenuItem
                  key={item.id}
                  button
                  divider
                  onClick={() => setSelectedUserFilter(item.id as any)}
                  className={classes.menuItem}
                >
                  {item.icon && (
                    <ListItemIcon
                      className={cn(classes.listIcon, {
                        [classes.listIconActive]:
                          item.id === filters.user?.value,
                      })}
                    >
                      <item.icon fontSize="small" />
                    </ListItemIcon>
                  )}
                  <ListItemText>
                    <Typography variant="body1">{item.label}</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Typography
                      variant="body1"
                      className={classes.menuItemCount}
                    >
                      {getFilterCount(item.id as any) ?? '-'}
                    </Typography>
                  </ListItemSecondaryAction>
                </MenuItem>
              ))}
            </List>
          </Card>
        </React.Fragment>
      ))}
    </>
  );
};

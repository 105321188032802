import React from 'react';
import { CatalogPageHeader } from './CatalogPageHeader'
import { EntityPageHeader } from './EntityPageHeader';

interface HeaderProps {
    type?: 'catalog' | 'entity';
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    additionalControls?: JSX.Element;
    extendedContent?: JSX.Element;
    backToLink?: {
        to: string;
        label: string;
    };
    backgroundImg?: string;
    displayButton?: boolean
}

export const Header = ({
    type,
    title,
    subtitle,
    additionalControls,
    extendedContent,
    backToLink,
    backgroundImg,
    displayButton
}: HeaderProps) => {
    if (type === 'entity') {
        return (
            <EntityPageHeader
                title={title}
                subtitle={subtitle}
                additionalControls={additionalControls}
                backToLink={backToLink}
                extendedContent={extendedContent}
            />
        );
    }

    return (
        <CatalogPageHeader
            title={title}
            subtitle={subtitle}
            additionalControls={additionalControls}
            extendedContent={extendedContent}
            backToLink={backToLink}
            backgroundImg={backgroundImg}
            displayButton={displayButton}
        />
    );
};

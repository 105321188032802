import React from 'react';
import { PageLayout, Content, Link } from 'tmna-ui-kit';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useApi,useRouteRef } from '@backstage/core-plugin-api';
import { newsLetterApiRef } from '@internal/plugin-news-letter/src/api';
import { Progress } from '@backstage/core-components';
import { useAsync } from 'react-use';
import {useStyles} from './styles';
import {formatDate} from 'tmna-utils';
import {rootRouteRef} from '../../routes';

export const ViewAllNewsLetterComponent = () => {
  const classes = useStyles();
  const newsLetter = useApi(newsLetterApiRef);
  const getNewsletterPageRoute = useRouteRef(rootRouteRef);

  const backToLink = React.useMemo(() => ({
    label: 'Back to Home',
    to: '/',
  }), []);

  const { value: newsList, loading: isLoading, error } = useAsync(async () => {
    return await newsLetter.getNewsletterMetadata();
  }, []);

  if (isLoading) {
    return <Progress />;
  } else if (error) {
      return <Alert severity="error">{error.message}</Alert>;
  }

  return(
    <PageLayout
      title="Newsletter"
      subtitle="Quarterly issue that helps you keep up the latest happenings in the Cloud and DevEx space."
      headerBackgroundImg="/assets/welcome_bg.jpg"
      displayButton={true}
      backToLink={backToLink}
    >
      <Content>
        <Grid container direction="column" spacing={3}>
          <Grid item className={classes.content}>
              {newsList && newsList.map((item: any) => (
                  <React.Fragment key={item.id}>
                    <div>
                      <Box mb={2}>
                        <Link
                          to={`${getNewsletterPageRoute()}?id=${item.id}`}
                          spacing={0}
                        >
                          <Typography variant="h3" data-testid="fileTitle">
                            {item.title}
                          </Typography>
                        </Link>
                      </Box>
                      {item.description && (
                          <Box mb={3}>
                              <Typography variant="body1">
                                  {item.description}
                              </Typography>
                          </Box>
                      )}
                      <Typography variant="caption">
                        {formatDate(item.date, false)}
                      </Typography>
                    </div>
                    <Box my={4}>
                      <Divider />
                    </Box>
                  </React.Fragment>
              ))}
          </Grid>
        </Grid>
      </Content>
    </PageLayout>
  )
};

import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import  LibraryIcon from '@material-ui/icons/Description';

import {useStyles} from './styles';

interface Props {
    title: string;
    subtitle: string;
    image?: string;
}

export const TextTrainingCard = ({
    title,
    subtitle,
    image
}: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.card}>
            <img src={image} className={classes.image} alt="image"/>
            <div className={classes.overlay} />
            <div className={classes.content}>
                <Grid
                    container
                    wrap="nowrap"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    spacing={2}
                >
                    <Grid item>
                        <Typography variant='button' className={classes.title} gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subtitle}>
                            {subtitle}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div className={classes.iconContainer}>
                            <LibraryIcon className={classes.icon} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>((theme) => ({
    content: {
        '& h3': {
            fontWeight: theme.typography.fontWeightMedium as any,
        }
    }
}));

import { Config } from '@backstage/config';
//import { UrlPatternDiscovery } from '@backstage/core-app-api';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
//import { GuidV4 } from 'tmna-backend-common';
//import { IUserService } from '@internal/plugin-users-backend';
//import {stringifyQueryString} from 'tmna-utils';

export const settingsApiRef = createApiRef<IUserServiceApi>({
    id: 'plugin.user-settings-fork',
});


type UserServiceApiOptions = {
    configApi: Config;
    identityApi: IdentityApi;
}
             
interface IUserServiceApi {
    saveUserPreferenceData(params: any): Promise<any>;
    getUserDefaultValueForInsights(params: any): Promise<any>;
    insertUserPreferenceFields():Promise<any>;
}

export class UserServiceApi implements IUserServiceApi {
    static saveUserPreferenceData() {
      throw new Error('Method not implemented.');
    }
    private configApi: Config;
    private identityApi: IdentityApi;

    constructor(options: UserServiceApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
    }
    static getUserDefaultValueForInsights() {
        throw new Error('Method not implemented.');
    }


    public async saveUserPreferenceData(params: any) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/users/save`);
        const token = await this.identityApi.getCredentials();

        //url.search = stringifyQueryString(params);
       
        console.log("saveUserPreferenceData params are-->", params)

        return fetch(
            url.toString(),
        {
                method: 'PUT',
                credentials: 'include',
                headers: {

                 'Content-Type': 'application/json',
                    
                'Authorization': `Bearer ${token}`
                    
                },
                body: JSON.stringify(params)
            },
        ).then(d => d.json());
    }

    public async insertUserPreferenceFields(){
        try{
            const backendUrl = this.configApi.getString('backend.baseUrl');
            const url = new URL(`${backendUrl}/api/users/insertFields`);
            const token = await this.identityApi.getCredentials();
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                'Content-Type': 'application/json',
                ...(token && { Authorization: `Bearer ${token.token}` }),
                },
                body: JSON.stringify([])
            });
            console.log("response", response)
            return await response.json();
        }catch(e){
            throw new Error(`insertUserPreferenceFields definitions , ${e}`);
        }
    }

    public async getUserDefaultValueForInsights(params:any) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/users/get`);
        const token = await this.identityApi.getCredentials();


        // return fetch(
        //     url.toString(),
        // {
        //         method: 'POST',
        //         credentials: 'include',
        //         headers: {

        //          'Content-Type': 'application/json',
                    
        //         'Authorization': `Bearer ${token}`
                    
        //         },
        //         body: JSON.stringify(params)
        //     },
        // ).then(d => d.json());



        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token.token}` }),
            },
            body: JSON.stringify(params),
        });
        console.log("response", response)
        return await response.json();
    }



}
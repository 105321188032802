/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { configApiRef, createApiFactory, createPlugin, createRoutableExtension, createRouteRef, identityApiRef } from '@backstage/core-plugin-api';
import { UserServiceApi, settingsApiRef } from './api';



export const settingsRouteRef = createRouteRef({  
  id: 'Settings',
  
});

export const userSettingsPlugin = createPlugin({
  id: 'user-settings',
  routes: {
    settingsPage: settingsRouteRef,
  },
  apis: [
    createApiFactory({
        api: settingsApiRef,
        deps: {
            configApi: configApiRef,
            identityApi: identityApiRef,
        },
        factory: ({configApi, identityApi}) =>
            new UserServiceApi({ configApi, identityApi }),
    }),
],
  
});

export const UserSettingsPage = userSettingsPlugin.provide(
  createRoutableExtension({
    component: () =>
      import('./components/SettingsPage').then(m => m.SettingsPage),
    mountPoint: settingsRouteRef,
  }),
);



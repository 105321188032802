import React from 'react';
import {useAsync} from 'react-use';
import {CircularProgress, Grid, Typography,} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/NavigateNext';
import Alert from '@material-ui/lab/Alert';
import {Link} from 'tmna-ui-kit';
import {formatDate} from 'tmna-utils';
import {NewsMetadata} from 'tmna-types';
import {newsApiRef} from '../../api';
import {newsPageRouteRef} from '../../routeRefs';

import {useStyles} from './styles';

import { InfoCard, Link as BackstageLink } from '@backstage/core-components';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';

export const NewsWidget = () => {
  const classes = useStyles();
  const newsApi = useApi(newsApiRef);

  const { value: newsList, loading: isLoading, error } = useAsync(async (): Promise<any> => {
      const news = await newsApi.getNewsMetadata();

      return news.slice(0, 4);
  }, []);

  const getNewsPageRoute = useRouteRef(newsPageRouteRef);

  const header = React.useMemo(() => (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3">
            Release Notes
          </Typography>
        </Grid>
        <Grid item>
          <Link
              to={getNewsPageRoute()}
              icon={<ArrowIcon color="error" className={classes.arrowIcon} />}
              spacing={0}
          >
            <Typography variant="button" component="div">
              VIEW ALL
            </Typography>
          </Link>
        </Grid>
      </Grid>
  ), []);

  if (error) {
      return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <InfoCard
        title={header}
        className={classes.container}
    >
      {
          isLoading
        ? <CircularProgress />
        : <Grid container direction="column" spacing={2}>
          {newsList.map((item: NewsMetadata) => (
            <Grid item className={classes.articleContent} key={item.id}>
              <BackstageLink
                  to={`${getNewsPageRoute()}?${new URLSearchParams({id: item.id})}`}
                  color="inherit"
                  underline="none"
                  key={item.id}
                  className={classes.linkContainer}
              >
                <Typography variant="caption" className={classes.articleDate}>
                  {formatDate(item.date, false)}
                </Typography>
                <Typography className={classes.articleTitle} variant="body1" noWrap>
                  {item.title}
                </Typography>
                <Typography noWrap variant="body1">
                  {item.description}
                </Typography>
              </BackstageLink>
            </Grid>
          ))}
        </Grid>
      }
    </InfoCard>
  );
};

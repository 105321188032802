import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    container: {
        height: '90px',
        width: '90px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.tmnaColors.primary.red,
        background: theme.palette.tmnaColors.primary.white,
        borderRadius: '50%',
    }
}));

import React from 'react';
import {ComponentsProps} from '@material-ui/core/styles/props';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ReportIcon from '@material-ui/icons/Report';
import ClearIcon from '@material-ui/icons/Clear';
import {StepIcon} from '../components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const TMNAThemeProps: Partial<ComponentsProps> = {
    MuiGrid: {
        spacing: 2,
    },
    MuiSwitch: {
        color: 'primary',
    },
    MuiButton: {
        disableFocusRipple: true,
    },
    MuiTextField: {
        variant: 'outlined',
        size: 'small',
    },
    MuiInputLabel: {
        shrink: true,
        disableAnimation: true,
    },
    MuiCheckbox: {
        disableRipple: true,
    },
    MuiStepLabel: {
        StepIconComponent: StepIcon
    },
    MuiChip: {
        deleteIcon: <ClearIcon />,
    },
    // TODO: remove ts-ignore
    // @ts-ignore
    MuiAlert: {
        iconMapping: {
            success: <CheckCircleIcon />,
            info: <InfoIcon />,
            warning: <ReportIcon />,
            error: <WarningIcon fontSize="small" />,
        },
    },
    MuiAutocomplete: {
        popupIcon: <ExpandMoreIcon />,
        ChipProps: {
            clickable: true,
            size: 'small'
        },
    },
};

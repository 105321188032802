import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    
    mainDiv: {
        padding: '10px 0 10px 0px', 
        fontSize: '15px' 
    },
    gitDiv:{
        padding: '0 0 10px 0px', 
        fontSize: '15px'
    },
    infoDiv:{
        margin: '0px 0 20px 0px', 
    },
    description: {
        wordBreak: 'break-word',
    },
    link: {
        color: 'navy',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    container:{
        width: "100%",
        maxHeight: "120px",
        overflow: "auto",
        margin:'10px 0 11px 0px',
        '&::-webkit-scrollbar':{ 
            width: "5px",
            height: "5px",
        },
        '&::-webkit-scrollbar-track': {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
          },
          
          '&::-webkit-scrollbar-thumb': {
            "-webkit-border-radius": "10px",
            "border-radius": "10px",
            "background": "rgba(255, 255, 255, 0.3)",
            "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.5)",
          },
          
          '&::-webkit-scrollbar-thumb:window-inactive': {
            background: "rgba(255, 255, 255, 0.3)"
          }
    }
});
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';
import remarkExternalLinks from 'remark-external-links';
import {useStyles} from './styles';


type Props = {
    content: string;
};

export const MarkdownContent = ({ content }: Props) => {
    const classes = useStyles();
    return (
        <ReactMarkdown
            remarkPlugins={[
                gfm as any,
                [remarkExternalLinks, {target: "_blank", rel: ['noopener', 'noreferrer']}],
            ]}
            rehypePlugins={[rehypeRaw as any]}
            className={classes.markdown}
            children={content}
        />
    );
};

import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const cloudAccountPlugin = createPlugin({
  id: 'cloud-account',
  routes: {
    root: rootRouteRef,
  },
});

export const CloudAccountPage = cloudAccountPlugin.provide(
  createRoutableExtension({
    name: 'CloudAccountPage',
    component: () =>
      import('./components/CloudAccountComponent').then(m => m.CloudAccountComponent),
    mountPoint: rootRouteRef,
  }),
);

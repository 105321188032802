import React from 'react';
import {InputAdornment, TextField} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

type Props = {
    onChange: (value: string) => void;
}

export const FilterField = ({
    onChange,
}: Props) => (
    <TextField
        variant="standard"
        onChange={(event) => onChange(event.target.value)}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <FilterListIcon />
                </InputAdornment>
            ),
        }}
    />
)
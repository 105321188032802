export enum MarkdownCategory {
    BLOGS = 'blogs',
    FAQ = 'faq',
    NEWS = 'news',
    TUTORIALS = 'tutorials',
    NEWLETTER = 'newsletter',
};

export interface MarkdownMetadataCommonPart {
    id: string;
    fileName: string;
}

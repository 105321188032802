import {welcomePageRouteRef} from './routeRefs';
import {plugin} from './plugin';
import { createRoutableExtension } from '@backstage/core-plugin-api';

export const WelcomePage = plugin.provide(
    createRoutableExtension({
        component: () => import('./pages').then(m => m.MainPage),
        mountPoint: welcomePageRouteRef,
    }),
);

/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  Entity,
  CompoundEntityRef ,
  DEFAULT_NAMESPACE,
} from '@backstage/catalog-model';
import {entityRouteRef} from '@backstage/plugin-catalog-react';
import React, { forwardRef } from 'react';
// import { generatePath } from 'react-router';
import {Link, LinkProps} from '../Link'
// import { formatEntityRefTitle } from './format';
import { parseEntityRef } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import { Tooltip } from '@material-ui/core';
import {  
  humanizeEntityRef    
} from '@backstage/plugin-catalog-react';

export type EntityRefLinkProps = {
  entityRef: Entity | CompoundEntityRef | string;
  defaultKind?: string;
  title?: string;
  children?: React.ReactNode;
} & Omit<LinkProps, 'to'>;

export const CatalogEntityRefLink = forwardRef<any, EntityRefLinkProps>(
  (props, ref) => {
  const { entityRef, defaultKind, title, children, ...linkProps } = props;       
 
  const entityRoute = useRouteRef(entityRouteRef);

  let kind;
  let namespace;
  let name;

  if (typeof entityRef === 'string') {
    const parsed = parseEntityRef(entityRef);
    kind = parsed.kind;
    namespace = parsed.namespace;
    name = parsed.name;
  } else if ('metadata' in entityRef) {
    kind = entityRef.kind;
    namespace = entityRef.metadata.namespace;
    name = entityRef.metadata.name;
  } else {
    kind = entityRef.kind;
    namespace = entityRef.namespace;
    name = entityRef.name;
  }

  kind = kind.toLocaleLowerCase('en-US');
  namespace = namespace?.toLocaleLowerCase('en-US') ?? DEFAULT_NAMESPACE;

  const routeParams = { kind, namespace, name };
  const formattedEntityRefTitle = humanizeEntityRef(
    { kind, namespace, name },
    { defaultKind },
  );

  const link = (
    <Link {...linkProps} ref={ref} to={entityRoute(routeParams)}>
      {children}
      {!children && (title ?? formattedEntityRefTitle)}
    </Link>
  );

  return title ? (
    <Tooltip title={formattedEntityRefTitle}>{link}</Tooltip>
  ) : (
    link
  );
  },
) as (props: EntityRefLinkProps) => JSX.Element;

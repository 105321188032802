import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        position:'sticky',
        top:0,
        zIndex:10,
        margin: '0px 0 0 0',
        "&.Mui-selected": {
            color: "red"
            }
    },
    customAlert:{
        "& .MuiAlert-message":{
            fontSize:'14px !important'
        }
    },
    spinner: {
        display:"flex",
        justifyContent: "center"
    },
    errorMsg:{
        "& .MuiAlert-icon": {
            color: "red"
        },
        "& .MuiIconButton-colorInherit": {
            color: "black"
        },
        "& .MuiAlert-message":{
            fontWeight:'400',
            fontSize:'16px'
        },
        "& .MuiAlert-filledError":{
            padding:"5px 15px 5px 15px"
        }
    },
    header :{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px'
    },
    rowMargin : {
        marginTop: '12px'
    },
    tabContent: { position: 'relative', zIndex: 1 },
    customWidth: { maxWidth: 275 },
    actionBtnDiv: {
        marginLeft:10,
        display:'flex',
        alignItems:'center',
    },
    saveBtn:{
        minWidth: 88
    }
});

import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import type { TMNATheme } from 'tmna-theme';

const useStyles = makeStyles<TMNATheme>((theme) => ({
    separator: {
        color: theme.palette.tmnaColors.secondary.mediumGray,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    }
}));

interface Props {
    children: React.ReactNode;
}

export const TimeRanges: React.FC<Props> = ({
    children,
}) => {
    const classes = useStyles();

    return (
        <Box alignItems="center" display="flex">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {React.Children.map(children, (child, index) => (
                    <>
                        {child}
                        {index !== React.Children.count(children) - 1 && (
                            <div className={classes.separator} aria-disabled="true" style={{marginBottom: '32px'}}>—</div>
                        )}
                    </>
                ))}
            </MuiPickersUtilsProvider>
        </Box>
    );
}

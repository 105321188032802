import React from 'react';

import {useStyles} from './styles';
import { HorizontalScrollGrid as BacksatgeHorizontalScrollGrid } from '@backstage/core-components';

type Props = {
    children: JSX.Element | JSX.Element[];
    theme?: 'light' | 'dark';
}

// TODO: backstage HorizontalScrollGrid tech debt.
// taken from backstage repo due to different border gradient styling, not overridable by themes
export const HorizontalScrollGrid = ({
    children,
    theme = 'light',
}: Props) => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} ${
            theme === 'dark' ? classes.dark : ''
        }`}>
            <BacksatgeHorizontalScrollGrid>
                {children}
            </BacksatgeHorizontalScrollGrid>
        </div>
    );
};

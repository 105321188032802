export enum NotificationType {
    FCBN = 'FCBN',
    FORN = 'FORN',
    SPVN = 'SPVN',
    SNN = 'SNN',
    SAWSW = 'SAWSW',
    SAZW = 'SAZW',
    SARN = 'SARN',
    MSEN = 'MSEN'
}

export enum NotificationReadStatus {
    READ = 'read',
    UNREAD = 'unread',
}

export type Notification = {
    notificationId: string;
    type: NotificationType;
    read: boolean;
    message?: string;
    date?: string;
    accountId?: string;
    link?: string;
    month?: string;
    cloud?: string;   
    debug?: string
    debug_recipient?: string
    guardrail?: string;
    resourceId?: string;
    violation?: string;
    action?: string;
}

import isArray from 'lodash/isArray';

export const groupByArrayField = <T extends object>(items: T[], fieldName: keyof T): Record<string, T[]> => (
    items.reduce(
        (result, item) => {
            const fieldValue = item[fieldName] as string[]
 
            if (fieldValue && isArray(fieldValue)) {
                fieldValue.forEach((el:string) => {
                    if (result[el]) {
                        result[el].push(item)
                    }
                    else {
                        result[el] = [item]
                    }
                });
            }

            return result;
        },
        {} as Record<string, T[]>
    )
);

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>((theme) => ({
    Alerts: {
        marginBottom: '24px',
        width: 'fit-content'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        fontWeight: 400
    },
    dangerText: {
        color: 'red'
    },
    accNameLoader: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        '& > .MuiCircularProgress-root': {
          color: 'red',
        },
      },
    tabHeading: {
        fontSize: '22px',
        fontWeight: 'bold',
        paddingLeft:'0px !important',
        display: "flex",
        alignItems: "center"
    },
    iconGrid:{
        display: "flex",
        flexWrap: "inherit",
        alignContent: "center",
        justifyContent: "flex-end"
    },
    tableContainer: {
        margin: theme.spacing(5, -1, -2),
    },
    parentSupport:{
        '& .MuiButtonBase-root':{
            padding:'3px !important',
            minWidth:'unset !important',
            height:'unset !important'
        } 
    },
    iconContainer: {
        width: 28,
        display: 'flex',
        color: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        float: 'right',
    //    / margin: '40px 0 0 0 !important',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius:4,
        marginTop: '13px',
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        '& ul':{
            listStyle:'none',
            padding: 5,
            margin: 0,
            paddingTop: 8,
            paddingBottom: 8,
            cursor: 'pointer',
            '& li':{
                fontSize: '15px',
                paddingLeft: 16,
                paddingRight: 16,
                fontWeight: 400,
                lineHeight: 1.5,
                paddingTop: 6,
                whiteSpace: 'nowrap',
                paddingBottom: 6,
                '&:hover':{
                    color: '#CC0000',
                    background: '#F7F7F7',
                }
            }
        }
      }
}));

/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {CompoundEntityRef} from '@backstage/catalog-model';
import { JsonObject, JsonValue, Observable } from '@backstage/types';
import { ResponseError } from '@backstage/errors';
import {ScmIntegrationRegistry} from '@backstage/integration';
import { Field, FieldValidation } from '@rjsf/core';
import ObservableImpl from 'zen-observable';
import {ListActionsResponse, ScaffolderTask, Status} from './types';
import { createApiRef, DiscoveryApi, IdentityApi, OAuthApi  } from '@backstage/core-plugin-api';
//import { Octokit} from "octokit";

export const scaffolderApiRef = createApiRef<ScaffolderApi>({
  id: 'plugin.scaffolder.service'  
});

type TemplateParameterSchema = {
  title: string;
  steps: Array<{
    title: string;
    schema: JsonObject;
  }>;
};

export type LogEvent = {
  type: 'log' | 'completion';
  body: {
    message: string;
    stepId?: string;
    status?: Status;
  };
  createdAt: string;
  id: string;
  taskId: string;
};

export type CustomField = {
  name: string;
  component: Field;
  validation: (data: JsonValue, field: FieldValidation) => void;
};

export interface ScaffolderScaffoldOptions {
  templateRef: string;
  values: Record<string, JsonValue>;
  secrets?: Record<string, string>;
}

export interface ScaffolderScaffoldResponse {
  taskId: string;
}

export interface ScaffolderApi {
  getTemplateParameterSchema(
    templateName: CompoundEntityRef,
  ): Promise<TemplateParameterSchema>;

  /**
   * Executes the scaffolding of a component, given options which contains a template and its
   * parameter values.   *
   * 
   * @param options 
   */
  scaffold(options: ScaffolderScaffoldOptions): Promise<ScaffolderScaffoldResponse>;
  createGithubIssue(repo:string):Promise<any>;

  mostPopular(templateName: string): Promise<string>;

  getTask(taskId: string): Promise<ScaffolderTask>;

  getIntegrationsList(options: {
    allowedHosts: string[];
  }): Promise<{ type: string; title: string; host: string }[]>;

  // Returns a list of all installed actions.
  listActions(): Promise<ListActionsResponse>;

  getMostPoulartData(): Promise<[]>;

  streamLogs({
    taskId,
    after,
  }: {
    taskId: string;
    after?: number;
  }): Observable<LogEvent>;
}

export class ScaffolderClient implements ScaffolderApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private readonly githubAuthApi: OAuthApi;
  private readonly scmIntegrationsApi: ScmIntegrationRegistry;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
    scmIntegrationsApi: ScmIntegrationRegistry;
    githubAuthApi: OAuthApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
    this.scmIntegrationsApi = options.scmIntegrationsApi;
    this.githubAuthApi = options.githubAuthApi;
  }

  async getIntegrationsList(options: { allowedHosts: string[] }) {
    return [
      ...this.scmIntegrationsApi.azure.list(),
      ...this.scmIntegrationsApi.bitbucket.list(),
      ...this.scmIntegrationsApi.github.list(),
      ...this.scmIntegrationsApi.gitlab.list(),
    ]
      .map(c => ({ type: c.type, title: c.title, host: c.config.host }))
      .filter(c => options.allowedHosts.includes(c.host));
  }

  async getTemplateParameterSchema(
    templateName: CompoundEntityRef,
  ): Promise<TemplateParameterSchema> {
    const { namespace, kind, name } = templateName;

    const token = await this.identityApi.getCredentials();
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const templatePath = [namespace, kind, name]
      .map(s => encodeURIComponent(s))
      .join('/');
    const url = `${baseUrl}/v2/templates/${templatePath}/parameter-schema`;

    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        ...(token && { Authorization: `Bearer ${token.token}` }),
      },
    });

    if (!response.ok) {
      throw ResponseError.fromResponse(response);
    }

    const schema: TemplateParameterSchema = await response.json();
    return schema;
  } 

  async scaffold(
    options: ScaffolderScaffoldOptions,
  ): Promise<ScaffolderScaffoldResponse> {
    const { templateRef, values, secrets = {} } = options;
    const token = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl('scaffolder')}/v2/tasks`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token.token}` }),
      },
      body: JSON.stringify({
        templateRef,
        values: { ...values },
        secrets,
      }),
    });
    if (response.status !== 201) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const { id } = (await response.json()) as { id: string };
    return { taskId: id };
  }

  async mostPopular(
    templateName: string,
  ): Promise<string> {
    const token = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl('blueprints')}/incrementMostPopular`;
    console.log("url", url)

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token.token}` }),
      },
      body: JSON.stringify({ template: templateName }),
    });
    console.log("response", response)
    return await response.json();
  }

  async getMostPoulartData(): Promise<[]> {
    const token = await this.identityApi.getCredentials();
    const baseUrl = await this.discoveryApi.getBaseUrl('blueprints');
    const response = await fetch(`${baseUrl}/selectMostPopular`,{
      credentials: 'include',
      headers: token ? { Authorization: `Bearer ${token.token}` } : {},
    });

    if (!response.ok) {
      throw ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  async getTask(taskId: string) {
    const token = await this.identityApi.getCredentials();
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const url = `${baseUrl}/v2/tasks/${encodeURIComponent(taskId)}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: token ? { Authorization: `Bearer ${token.token}` } : {},
    });

    if (!response.ok) {
      throw ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  streamLogs({
    taskId,
    after,
  }: {
    taskId: string;
    after?: number;
  }): Observable<LogEvent> {
    return new ObservableImpl(subscriber => {
      const params = new URLSearchParams();
      if (after !== undefined) {
        params.set('after', String(Number(after)));
      }

      this.discoveryApi.getBaseUrl('scaffolder').then(
        baseUrl => {
          const url = `${baseUrl}/v2/tasks/${encodeURIComponent(
            taskId,
          )}/eventstream`;
          const eventSource = new EventSource(url, { withCredentials: true });
          eventSource.addEventListener('log', (event: any) => {
            if (event.data) {
              try {
                subscriber.next(JSON.parse(event.data));
              } catch (ex) {
                subscriber.error(ex);
              }
            }
          });
          eventSource.addEventListener('completion', (event: any) => {
            if (event.data) {
              try {
                subscriber.next(JSON.parse(event.data));
              } catch (ex) {
                subscriber.error(ex);
              }
            }
            eventSource.close();
            subscriber.complete();
          });
          eventSource.addEventListener('error', event => {
            subscriber.error(event);
          });
        },
        error => {
          subscriber.error(error);
        },
      );
    });
  }

  /**
   * @returns ListActionsResponse containing all registered actions.
   */
  async listActions(): Promise<ListActionsResponse> {
    const token = await this.identityApi.getCredentials();
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const response = await fetch(`${baseUrl}/v2/actions`,{
      credentials: 'include',
      headers: token ? { Authorization: `Bearer ${token.token}` } : {},
    });

    if (!response.ok) {
      throw ResponseError.fromResponse(response);
    }

    return await response.json();
  }



  //Github Action API

  async createGithubIssue(
    repoName:string,
  ): Promise<any> {
    const repo:string=repoName;  

  const token = await this.identityApi.getCredentials();
  const githubToken = await this.githubAuthApi.getAccessToken(['repo', 'workflow']);
  const url = `${await this.discoveryApi.getBaseUrl('blueprints')}/createGithubIssue`;
  console.log("url", url)

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token.token}` }),
    },
    body: JSON.stringify({ repo: repo, githubToken }),
  });
  console.log("response", response)
  return await response.json();    
  }
}

/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Entity,
  getCompoundEntityRef,
  getEntitySourceLocation,
  RELATION_DEPENDENCY_OF,
  //RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import { JsonArray } from '@backstage/types';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link, MarkdownContent } from '@backstage/core-components';
import React, { FC, useMemo, Fragment } from 'react';
import { AboutField } from './AboutField';
import { LinksGridList } from '../EntityLinksCard/LinksGridList';
import { cloudAccountPlugin } from '@internal/plugin-cloud-account';
import { useRouteRef } from '@backstage/core-plugin-api';

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
  },
  link: {
    color: 'navy',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

/**
 * Props for {@link AboutContent}.
 *
 * @public
 */
export interface AboutContentProps {
  entity: Entity;
}

const EmailLink: FC<{
  name: string;
  email?: string | null;
  prefix?: string;
}> = ({ name, email = null, prefix = ', ' }) => {
  const classes = useStyles();
  return email ? (
    <Fragment>
      {prefix}
      <Link to={`mailto:${email}`} className={classes.link} children={name} />
    </Fragment>
  ) : (
    <Fragment>
      {prefix}
      {name}
    </Fragment>
  );
};

const ContactsField: FC<{
  emptyText?: string;
  contacts: { name: string; email?: string | null }[];
}> = ({ contacts, emptyText = '-' }) => {
  const renderContacts = useMemo(
    () =>
      contacts
        ?.sort((a, b) => a.name.localeCompare(b.name))
        ?.map((data, i) => (
          <EmailLink
            key={data.name}
            name={data?.name}
            // email={data?.email}
            prefix={i > 0 ? undefined : ''}
          />
        )) ?? <Fragment children={emptyText} />,
    [contacts, emptyText],
  );
  if (contacts?.length === 0) return <Fragment children={emptyText} />;
  return <Fragment children={renderContacts} />;
};

const ToyotaSysIdLink: FC<{
  toyotaSysId: string;
  prefix?: string;
}> = ({ toyotaSysId = '', prefix = ', ' }) => {
  const classes = useStyles();
  return (
    <Fragment>
      {prefix}
      <Link
        className={classes.link}
        to={`https://tmna.service-now.com/now/nav/ui/classic/params/target/cmdb_ci_business_app.do%3Fsysparm_query%3Du_toyota_sys_id%3D${toyotaSysId}`}
      >
        {toyotaSysId}
      </Link>
    </Fragment>
  );
};

function getLocationTargetHref(
  target: string,
  type: string,
  entitySourceLocation: {
    type: string;
    target: string;
  },
): string {
  if (type === 'url' || target.includes('://')) {
    return target;
  }

  const srcLocationUrl =
    entitySourceLocation.type === 'file'
      ? `file://${entitySourceLocation.target}`
      : entitySourceLocation.target;

  if (type === 'file' || entitySourceLocation.type === 'file') {
    return new URL(target, srcLocationUrl).href;
  }

  return srcLocationUrl;
}

// const useHasPartOwnedBy = (entity: Entity) => {
//   const { entities } = useRelatedEntities(entity, {
//     type: RELATION_HAS_PART,
//     kind: 'component',
//   });
//   const hasPartOwnedBy = useMemo(() => {
//     const allHasPart =
//       entities?.flatMap(hasPartEntity =>
//         getEntityRelations(hasPartEntity, RELATION_OWNED_BY),
//       ) ?? [];
//     const uniqueHasPart = [
//       ...new Map(allHasPart.map(c => [c.name, c])).values(),
//     ];
//     return uniqueHasPart;
//   }, [entities]);

//   return hasPartOwnedBy;
// };

export const useDependencyOf = (entity: Entity) => {
  const { entities } = useRelatedEntities(entity, {
    type: RELATION_DEPENDENCY_OF,
    kind: 'component',
  });
  const dependencyOf = useMemo(() => {
    return entities?.map(singleEntity => getCompoundEntityRef(singleEntity));
  }, [entities]);

  return dependencyOf;
};

/** @public */
export function AboutContent(props: AboutContentProps) {
  const { entity } = props;
  //const hasPartOwnedBy = useHasPartOwnedBy(entity);
  // const dependencyOf = useDependencyOf(entity);
  const classes = useStyles();
  const cloudAccountRoute = useRouteRef(cloudAccountPlugin.routes.root);
  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const isAPI = entity.kind.toLocaleLowerCase('en-US') === 'api';
  const isTemplate = entity.kind.toLocaleLowerCase('en-US') === 'template';
  const isLocation = entity.kind.toLocaleLowerCase('en-US') === 'location';
  const isGroup = entity.kind.toLocaleLowerCase('en-US') === 'group';

  const isTypeApp = isComponent && entity.spec?.type === 'application';
  const isTypeRepo = isComponent && entity.spec?.type === 'repo';
  const isTypeCloudAccount =
    isComponent && entity.spec?.type === 'cloud account';

  // const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
  //   kind: 'system',
  // });
  const partOfComponentRelations = getEntityRelations(
    entity,
    RELATION_PART_OF,
    {
      kind: 'component',
    },
  );
  const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'domain',
  });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  let entitySourceLocation:
    | {
        type: string;
        target: string;
      }
    | undefined;
  try {
    entitySourceLocation = getEntitySourceLocation(entity);
  } catch (e) {
    entitySourceLocation = undefined;
  }

  return (
    <Grid container>
      {!isTypeCloudAccount && (
        <AboutField label="Description" gridSizes={{ xs: 12 }}>
          <MarkdownContent
            className={classes.description}
            content={entity?.metadata?.description || 'No description'}
          />
        </AboutField>
      )}

      {isTypeCloudAccount && (
        <Fragment>
          <AboutField
            label="Account ID"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.accountNumber as string) || '–'}
          >
            {entity?.metadata?.accountNumber && (
              <Link
                className={classes.link}
                target="_blank"
                to={`${cloudAccountRoute()}/CloudAccountDetails?accountId=${
                  entity?.metadata?.accountNumber
                }`}
              >
                {entity?.metadata?.accountNumber}
              </Link>
            )}
          </AboutField>

          <AboutField
            label="Account Name"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.accountName as string) || '–'}
          />

          <AboutField
            label="Cloud Service Provider"
            value={(entity?.metadata?.csp as string) || '–'}
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          />
        </Fragment>
      )}

      {!(isTypeRepo || isTypeCloudAccount) && (
        <Fragment>
          <AboutField
            label="Toyota Sys ID"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.toyotaSysId as string) || '–'}
          >
            {entity?.metadata?.toyotaSysId && (
              <ToyotaSysIdLink
                toyotaSysId={entity?.metadata?.toyotaSysId as string}
                prefix=""
              />
            )}
          </AboutField>

          <AboutField
            label="App Name"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.appName as string) || '–'}
          />

           {/* <AboutField
            label="Business Owner"
            value={(entity?.metadata?.appOwner as string) || '–'}
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          /> */}
       </Fragment>
      )}
      {/* <AboutField
        label="Owner"
        value="No Owner"
        gridSizes={{ xs: 12, sm: 6 }}
      >
        {ownedByRelations.length > 0 && (
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group" />
        )}
      </AboutField> */}
      {(isTypeApp || isTypeCloudAccount) && (
        <Fragment>
          <AboutField
            label="Portfolio"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.portfolio as string) || '–'}
          />
          <AboutField
            label="Program"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.program as string) || '–'}
          />
        </Fragment>
      )}
      {isTypeApp && (
        <AboutField
          label="Install type"
          value={(entity?.metadata?.installType as string) || '–'}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      )}
      {isTypeCloudAccount && (
        <AboutField
          label="Root"
          value={(entity?.metadata?.root as string) || '–'}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      )}

      {isTypeRepo && (
        <Fragment>
          <AboutField
            label="Repo Name"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.repoName as string) || '–'}
          />
          <AboutField
            label="Repo URL"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.repoUrl as string) || '–'}
          >
            {entity?.metadata?.repoUrl && (
              <Link
                className={classes.link}
                to={entity.metadata.repoUrl as string}
                children={entity?.metadata?.repoName}
              />
            )}
          </AboutField>
          <AboutField
            label="Visibility"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.visibility as string) || '–'}
          />
          <AboutField
            label="Default Branch"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.defaultBranch as string) || '–'}
          />
          <AboutField
            label="Languages"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(entity?.metadata?.language as string) || '–'}
          />
          <AboutField
            label="Open GH Issues"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(
              (entity?.metadata?.openIssuesCount as string) ?? 0
            ).toString()}
          />
          <AboutField
            label="# of Forks"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={((entity?.metadata?.forksCount as string) ?? 0).toString()}
          />
          <AboutField
            label="Archival Status"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={(
              (entity?.metadata?.archived as boolean) ?? false
            ).toString()}
          />
          <AboutField
            label="Disabled Status"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
            value={((entity?.metadata?.disabled as boolean) ?? true).toString()}
          />
          <AboutField
            label="Owner"
            value="No Owner"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          >
            {ownedByRelations.length > 0 && (
              <EntityRefLinks
                entityRefs={ownedByRelations}
                defaultKind="group"
              />
            )}
          </AboutField>
        </Fragment>
      )}

      {/* <AboutField
        label="Repo Owner"
        gridSizes={{ xs: 12, sm: 6 }}
        value={(entity?.metadata?.repoOwner as string) || '–'}
      />
      <AboutField
        label="Team Name"
        gridSizes={{ xs: 12, sm: 6 }}
        value={(entity?.metadata?.teamName as string) || '–'}
      />
      <AboutField
        label="Repo Owner Email"
        gridSizes={{ xs: 12, sm: 6 }}
        value={(entity?.metadata?.repoOwnerEmail as string) || '–'}
      /> */}

      {(isSystem || partOfDomainRelations.length > 0) && (
        <AboutField
          label="Domain"
          value="No Domain"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          {partOfDomainRelations.length > 0 && (
            <EntityRefLinks
              entityRefs={partOfDomainRelations}
              defaultKind="domain"
            />
          )}
        </AboutField>
      )}
      {/* {(isAPI ||
        isComponent ||
        isResource ||
        partOfSystemRelations.length > 0) && (
        <AboutField
          label="System"
          value="No System"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          {partOfSystemRelations.length > 0 && (
            <EntityRefLinks
              entityRefs={partOfSystemRelations}
              defaultKind="system"
            />
          )}
        </AboutField>
      )} */}
      {isComponent && partOfComponentRelations.length > 0 && (
        <AboutField
          label="Parent Component"
          value="No Parent Component"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          <EntityRefLinks
            entityRefs={partOfComponentRelations}
            defaultKind="component"
          />
        </AboutField>
      )}
      {(isAPI ||
        isComponent ||
        isResource ||
        isTemplate ||
        isGroup ||
        isLocation ||
        typeof entity?.spec?.type === 'string') && (
        <AboutField
          label="Type"
          value={entity?.spec?.type as string}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      )}
      {(isAPI ||
        isComponent ||
        typeof entity?.spec?.lifecycle === 'string') && (
        <AboutField
          label="Lifecycle"
          value={entity?.spec?.lifecycle as string}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      )}

      {!isTypeCloudAccount && (
        <AboutField
          label="Tags"
          value="No Tags"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          {(entity?.metadata?.tags || []).map(t => (
            <Chip key={t} size="small" label={t} />
          ))}
        </AboutField>
      )}

      {isTypeCloudAccount && (
        <Fragment>
          <Grid lg={4} />
          <AboutField
            label="Financial Sponsor"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          >
            <ContactsField
              emptyText="No Financial Sponsor"
              contacts={
                entity?.metadata?.financialSponsor as {
                  name: string;
                  email: string;
                }[]
              }
            />
          </AboutField>
          <AboutField
            label="Account Owner"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          >
            <ContactsField
              emptyText="No Account Owner"
              contacts={
                entity?.metadata?.accountOwner as {
                  name: string;
                  email: string;
                }[]
              }
            />
          </AboutField>
          <AboutField
            label="Technical Contact(s)"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          >
            <ContactsField
              emptyText="No Technical Contact(s)"
              contacts={
                entity?.metadata?.technicalContact as {
                  name: string;
                  email: string;
                }[]
              }
            />
          </AboutField>
        </Fragment>
      )}

      {/* {isTypeApp && (
        <AboutField
          label="GitHub Team"
          value="No GitHub Team"
          gridSizes={{ xs: 12 }}
        >
          {hasPartOwnedBy.length > 0 && (
            <EntityRefLinks entityRefs={hasPartOwnedBy} defaultKind="group" />
          )}
        </AboutField>
      )} */}
      {isTypeCloudAccount && (
        <Fragment>
          <AboutField
            label="Linked Application ID"
            value="No Linked Application ID"
            gridSizes={{ xs: 12 }}
          >
            {(entity.metadata?.toyotaSysIds as string[])?.map(
              (toyotaSysId, i) => (
                <ToyotaSysIdLink
                  key={toyotaSysId}
                  toyotaSysId={toyotaSysId}
                  prefix={i === 0 ? '' : undefined}
                />
              ),
            )}
          </AboutField>
          {/* <AboutField
            label="Linked Application Name"
            value="No Linked Application Name"
            gridSizes={{ xs: 12 }}
          >
            {dependencyOf && dependencyOf?.length > 0 && (
              <EntityRefLinks entityRefs={dependencyOf} defaultKind="group" />
            )}
          </AboutField> */}
        </Fragment>
      )}

      {isLocation && (entity?.spec?.targets || entity?.spec?.target) && (
        <AboutField label="Targets" gridSizes={{ xs: 12 }}>
          <LinksGridList
            cols={1}
            items={((entity.spec.targets as JsonArray) || [entity.spec.target])
              .map(target => target as string)
              .map(target => ({
                text: target,
                href: getLocationTargetHref(
                  target,
                  (entity?.spec?.type || 'unknown') as string,
                  entitySourceLocation!,
                ),
              }))}
          />
        </AboutField>
      )}

      {/* <Grid item xs={12} children={<Divider />} /> */}
    </Grid>
  );
}

import {Config} from '@backstage/config';
import {BlogArticleMetadata, BlogArticleExtendedMetadata, BlogFilterParams} from 'tmna-types';
import {stringifyQueryString} from 'tmna-utils';
import {ResponseError} from '@backstage/errors';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

export const blogsApiRef = createApiRef<BlogsApiInterface>({
    id: 'plugin.blogs',
    // description: 'BLogs'
});

type BlogsApiOptions = {
    configApi: Config;
    identityApi: IdentityApi,
}

export type ArticlesMetadataParams = {
    page?: number;
    pageSize?: number;
    search?: string;
    tags?: string[];
}

export type GetArticlesMetadataResponse = {
    data: BlogArticleMetadata[];
    isLastPage: boolean;
}

interface BlogsApiInterface {
    getArticlesMetadata(params?: ArticlesMetadataParams): Promise<GetArticlesMetadataResponse>;
    getArticleExtendedMetadata(articleId: string): Promise<BlogArticleExtendedMetadata>;
    getArticleContent(articleId: string): Promise<string>;
    getLatestPostsMetadata(): Promise<BlogArticleMetadata[]>;
    getSimilarPosts(filterParams: BlogFilterParams, isFiltered: Boolean): Promise<BlogArticleMetadata[]>;
    getFeaturedPostsMetadata(): Promise<BlogArticleMetadata[]>;
    getTags(): Promise<string[]>;
}

export class BlogsApi implements BlogsApiInterface {
    private configApi: Config;
    private identityApi: IdentityApi;

    constructor(options: BlogsApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
    }
    private async fetchAPI(url: string): Promise<Response> {
        const token = await this.identityApi.getCredentials();
        const response = await fetch(
          url,
          {
            credentials: 'include',
            headers: { Authorization: `Bearer ${token.token}` }
          },
        );

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return response
    }

    async getArticlesMetadata(params: ArticlesMetadataParams) {

        const backendUrl = this.configApi.getString('backend.baseUrl');

        const queryParams = stringifyQueryString(params);
        const requestUrl = `${backendUrl}/api/blogs/metadata?${queryParams}`;

        const data = await this.fetchAPI(requestUrl)
            .then(d => d.json());

        return data;
    }

    async getArticleExtendedMetadata(articleId: string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/blogs/${articleId}/metadata`;
        const data = await this.fetchAPI(requestUrl)
            .then(d => d.json());

        return data;
    }

    async getArticleContent(articleId: string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/blogs/${articleId}/content`;
        const data = await this.fetchAPI(requestUrl)
            .then(d => d.text());

        return data;
    }

    async getLatestPostsMetadata() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/blogs/metadata/latest`;
        const data = await this.fetchAPI(requestUrl)
            .then(d => d.json());

        return data;
    }

    async getFeaturedPostsMetadata() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/blogs/metadata/featured`;
        const data = await this.fetchAPI(requestUrl)
            .then(d => d.json());

        return data;
    }

    async getTags() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/blogs/tags`;
        const data = await this.fetchAPI(requestUrl)
            .then(d => d.json());

        return data;
    }

    async getSimilarPosts(filterParams: BlogFilterParams, isFiltered:Boolean = false) {
        const query = stringifyQueryString({
            ...filterParams,
            isFiltered: isFiltered ? '1' : '0',
        })

        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/blogs/info/similar-posts?${query}`;

        const data = await this.fetchAPI(requestUrl)
            .then(d => d.json());

        return data;
    }
}

import React from 'react';
import { useStyles } from './styles';
//import { Button} from '@material-ui/core';
//import { Link } from 'react-router-dom';
import MapIcon from '@material-ui/icons/Map';


export const ProductRoadmapButton = () => {
    const classes = useStyles();


    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-35px'}}>
            <a
                className={classes.buttonCustom}
                href='/docs/default/Component/ACE-TECH-DOCS/product-roadmap/chofer-product-roadmap/'
            >
                <MapIcon  style={{marginRight:"4px"}}/> {' '}Product Roadmap 
            </a> 

        </div>
    );
};
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {TMNATheme} from '../../types';

export const useStyles = makeStyles((theme: TMNATheme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: theme.palette.tmnaColors.secondary.slateGray,
            color: theme.palette.tmnaColors.primary.white,

            fontSize: '12px',
            lineHeight: 1,
            fontWeight: theme.typography.fontWeightBold as any,
        },
        error: {
            backgroundColor: theme.palette.tmnaColors.primary.black,
        },
        coloured: {
            borderRadius: '50%',
            backgroundImage: 'linear-gradient(to bottom, #f71840, #ff9265)',
        }
    }),
);
import {
    Grid,
    Typography,
    Chip,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';

import {Link} from 'tmna-ui-kit';
import {formatDate} from 'tmna-utils';
import {useStyles} from './styles';

import { Avatar } from '@backstage/core-components';

type Props = {
    authorIcon?: string;
    authorFullName?: string;
    authorJobTitle?: string;
    title?: string;
    description?: string;
    date: string;
    link?: string;
    size?: 'small' | 'medium';
    tags?: string[];
}

export const ArticleInfo = ({
    authorIcon,
    authorFullName,
    authorJobTitle,
    title,
    description,
    date,
    link,
    size = 'small',
    tags,
}: Props) => {
    const classes = useStyles();
    const isSmallSize = React.useMemo(() => (
        size === 'small'
    ), [size]);
    const avatarStyles = React.useMemo(() => (
        isSmallSize
            ? { height: 24,
                width: 24,
                fontSize: '10px',
                backgroundImage: 'linear-gradient(to bottom, #f71840, #ff9265)',
                marginRight: '8px' }
            : {height: 50, width: 50, fontSize: '16px', fontWeight: 500}
    ), [isSmallSize]);

    return (
        <Grid container spacing={isSmallSize ? 2 : 3} direction="column">
            {authorFullName &&
                <Grid item container spacing={isSmallSize ? 1 : 2} direction="row" alignItems="center">
                    <Grid item>
                        <Avatar
                            displayName={authorFullName}
                            picture={authorIcon}
                            customStyles={avatarStyles}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} direction="column">
                            <Typography
                                className={classes.author__name}
                                variant={isSmallSize ? 'body2' : 'body1'}
                            >
                                {authorFullName}
                            </Typography>
                            {authorJobTitle &&
                            <Typography className={classes.author__jobTitle}>
                                {authorJobTitle}
                            </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            {title &&
                <Grid item>
                    <Typography
                        variant={isSmallSize ? 'body1' : 'h5'}
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                </Grid>
            }
            {description &&
                <Grid item>
                    <Typography variant="body1">{description}</Typography>
                </Grid>
            }
            <Grid
                item
                container
                wrap="nowrap"
                direction={isSmallSize ? "column" : "row"}
                alignItems={isSmallSize ? "flex-start" : "center"}
            >
                <Grid item className={isSmallSize ? classes.dateContainer : undefined}>
                    <Typography
                        variant={isSmallSize ? "caption" : "body2"}
                        className={isSmallSize ? classes.date : undefined}
                    >
                        {formatDate(date, !isSmallSize)}
                    </Typography>
                </Grid>
                {tags?.length && <Grid item className={classes.chipsContainer}>
                    {tags.map(tag => (
                        <Chip className={classes.customChip} label={tag} size={size} key={tag} />
                    ))}
                </Grid>}
            </Grid>
            {link &&
                <Grid item>
                    <Link
                        icon={<ArrowForwardIcon />}
                        to={link}
                    >
                        READ MORE
                    </Link>
                </Grid>
            }
        </Grid>
    );
};
/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Divider, Grid, Typography} from '@material-ui/core';
import React from 'react';
import {ImportStepper} from './ImportStepper';
import {StepperProviderOpts} from './ImportStepper/defaults';
import {
  Link,
  PageLayout,
  SupportButton,
 
  Content,
  SupportTextList,
 // SupportLinks,
} from 'tmna-ui-kit';
import {makeStyles} from "@material-ui/core/styles";
import {blueprintsExternalRouteRef} from '../plugin';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


import { configApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
import { ContentHeader, InfoCard } from '@backstage/core-components';


const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium as any,
  },
}));

export const ImportComponentPage = (opts: StepperProviderOpts) => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptional('app.title') || 'Backstage';
  const blueprintsLink = useRouteRef(blueprintsExternalRouteRef)();


  const backToLink = React.useMemo(() => ({
    to: blueprintsLink,
    label: 'Back to Blueprints'
  }), [blueprintsLink]);

  const title = React.useCallback(() => (
      <Typography variant="h2" className={classes.title}>
        {`Start tracking your component in ${appTitle} Cloud Portal`}
      </Typography>
  ), [appTitle, classes]);

  const infoCardTitle = React.useMemo(() => (
      <Typography variant="h3" className={classes.title}>
        How to register an existing component
      </Typography>
  ), [classes]);

  return (
    <PageLayout
        title="Register an existing component"
        type="entity"
        backToLink={backToLink}
        displayButton={true}
    >
      <Content>
        <Grid container direction="column" spacing={4}>
          <Grid item>        
            <ContentHeader titleComponent={title}>
              <SupportButton>
                <SupportTextList>
                  <Typography variant="body2">
                    Register your component to add it to the Service Catalog.
                  </Typography>
                </SupportTextList>
              </SupportButton>
            </ContentHeader>
     
          </Grid> 

          <Grid item container spacing={2} direction="row-reverse">
            <Grid item xs={12} md={4} lg={6} xl={8}>
              <InfoCard
                  title={infoCardTitle}
                  actions={
                    <>
                      <Divider />
                      <Link
                          /* TODO: add link to app catalog tutorial */
                          to="/docs/default/component/ace-tech-docs/catalog/introduction/"
                          icon={<NavigateNextIcon color="secondary"/>}
                          target="_blank"
                      >
                        <Typography variant="button">
                          Learn about the App Catalog
                        </Typography>
                      </Link>
                    </>
                  }
              >
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography variant="h6">
                      Repository & Entity File
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" paragraph>
                      If you've already created a Chofer Cloud Portal metadata file and put it in your GitHub repository, you can enter the full URL to that Entity File.
                    </Typography>
                  </Grid>
                </Grid>
              </InfoCard>
            </Grid>

            <Grid item xs={12} md={8} lg={6} xl={4}>
              <ImportStepper opts={opts} />
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </PageLayout>
  );
};

import {homePageExternalRouteRef, newsPageRouteRef} from './routeRefs';
import {NewsApi, newsApiRef} from './api';
import { configApiRef, createApiFactory, createPlugin, identityApiRef } from '@backstage/core-plugin-api';

export const plugin = createPlugin({
    id: 'news',
    routes: {
        mainPage: newsPageRouteRef,
    },
    externalRoutes: {
        home: homePageExternalRouteRef,
    },
    apis: [
        createApiFactory({
            api: newsApiRef,
            deps: {
                configApi: configApiRef,
                identityApi: identityApiRef,
            },
            factory: ({configApi, identityApi}) =>
                new NewsApi({ configApi, identityApi }),
        }),
    ],
});

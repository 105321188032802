import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    container: {
        gridArea: 'pageHeader',
        backgroundColor: theme.palette.tmnaColors.primary.white,
    },
    content: {
        minHeight: '142px',
        paddingTop: theme.spacing(3),
    },
    subtitle: {
        marginTop: theme.spacing(1.5),
    },
}));

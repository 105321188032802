import React from 'react';
import { Page } from '@backstage/core-components';
import {
  TechDocsReaderPageContent,
  TechDocsReaderPageHeader,
  TechDocsReaderPageSubheader,
} from '@backstage/plugin-techdocs';

export const TechDocsCustomReaderPage = () => (
  <Page themeId="documentation">
    <TechDocsReaderPageHeader />
    <TechDocsReaderPageSubheader />
    <TechDocsReaderPageContent withSearch={false}/>
  </Page>
);

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { CircularProgress } from '@material-ui/core';

export type LimitTagProps = {
  businessUnitValues: any;
  setBusinessUnitSelected: any;
  areBUOptionsLoading?: any;
  buSelected: any;
  name: any;
  disable: any;
  //disabled?:boolean;
};

export default function LimitTagsUserDefault({
  businessUnitValues,
  setBusinessUnitSelected,
  areBUOptionsLoading = false,
  buSelected,
  name,
  disable,
}: //disabled=false
LimitTagProps) {
  // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  // const checkedIcon = <CheckBoxIcon fontSize="small" />;
  //const [open, setOpen] = React.useState(false);
  const allSelected = businessUnitValues.length === buSelected.length;
  const handleToggleOption = (data: any) => setBusinessUnitSelected(data);
  const getOptionLabel = (option: any) => `${option}`;
  const handleSelectAll = (isSelected: any) => {
    if (isSelected) {
      setBusinessUnitSelected(businessUnitValues);
    } else {
      setBusinessUnitSelected([]);
    }
  };
  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };
  const filter = createFilterOptions();

  const handleChangeValue = (event: object, value: any) => {
    console.log(event,name);
    if (value.find((option: any) => option === 'Select All')) {
      handleToggleSelectAll();
    } else {
      handleToggleOption && handleToggleOption(value);
    }
  };
  // const disabledFun = React.useMemo(()=>
  //   (disable === 'bul3' && businessUnitValues.length === 0 )
  //     ? true : (disable==='acct' && businessUnitValues.length===0)?true:false
  // ,[disable,businessUnitValues]);

  // React.useEffect(()=>{
  //   if(disabledFun || disabled){
  //     setOpen(false);
  //   }
  // },[disabledFun,disabled])

  return (
    <>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo1"
        options={businessUnitValues}
        //limitTags={1}
        disableCloseOnSelect
        disableClearable
        value={buSelected}
        renderTags={values =>
          'Selected ' + values.length + '' + (values.length > 1 ? '+' : '')
        }
        getOptionLabel={(option: any) => option}
        filterOptions={(options: any, params: any) => {
          const filtered = filter(options, params);
          return ['Select All', ...filtered];
        }}
        onChange={(event: object, value: any) =>
          handleChangeValue(event, value)
        }
        // onOpen={()=>setOpen(true)}
        // open={open}
        // onClose={()=>setOpen(false)}
        //disabled={disabled || disabledFun}
        disabled={
          disable === 'bul3' && businessUnitValues.length === 0
            ? true
            : disable === 'acct' && businessUnitValues.length === 0
            ? true
            : false
        }
        loading={areBUOptionsLoading}
        renderOption={(option, { selected }) => {
          const selectAllProps =
            option === 'Select All' ? { checked: allSelected } : {};
          return (
            <>
              <Checkbox
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
                {...selectAllProps}
              />
              {getOptionLabel(option)}
            </>
          );
        }}
        style={{ width: 300 }}
        renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {' '}
                    {areBUOptionsLoading ? (
                      <CircularProgress  size={20} />
                    ) : params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
        )}
      />
    </>
  );
}

import { makeStyles } from '@material-ui/core';
import { TMNATheme } from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
    },
    clickableCard: {
        '&:hover $title': {
            color: theme.palette.tmnaColors.primary.gray,
        },
        '&:hover $titleComponent': {
            color: theme.palette.tmnaColors.primary.gray,
        }
    },
    title: {
        fontSize: '16px',
        ...theme.mixins.lineClamp(2),
    },
    titleComponent: {}, // need for clickableCard hover effect
    authorName: {
        fontWeight: theme.typography.fontWeightBold as any,
    },
    date: {
        fontWeight: theme.typography.fontWeightBold as any
    },
    description: {
        ...theme.mixins.lineClamp(2),
    },
    tagsContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    restTagsCount: {
        marginLeft: theme.spacing(0.5),
        fontSize: 12,
        marginBottom: theme.spacing(1),
    },
    spacing: {
        flexGrow: 1,
    },
}));
import { RELATION_HAS_PART } from '@backstage/catalog-model';
import {
  InfoCardVariants,
  Link,
  TableOptions,
} from '@backstage/core-components';
import React, { useState, useEffect, FC, Fragment } from 'react';
import {
  asComponentEntities,
  componentEntityColumns,
  RelatedEntitiesCard,
} from '../RelatedEntitiesCard';
import {
  MenuItem,
  Menu,
  CircularProgress,
  CircularProgressProps,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button, { ButtonProps } from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CustomAlertDisplay, CustomAlertMessage } from './CustomAlertDisplay';
import { LinkRepoDialog, UnlinkRepoDialog } from './AssociateRepoDialog';
import { useEntity } from '@backstage/plugin-catalog-react';

const useStyles = makeStyles(theme => ({
  table: {
    position: 'relative',
    '& .MuiTable-root': {
      '& .MuiTableHead-root , .MuiTableBody-root': {
        '& > .MuiTableRow-root .MuiTableCell-root': {
          padding: '10px 12px !important',
          '&:first-child': {
            padding: '10px 12px 10px 24px !important',
          },
          '&:last-child': {
            padding: '10px 24px 10px 12px !important',
          },
        },
      },
    },
  },
  tableButton: {
    position: 'absolute',
    top: theme.spacing(1.25),
    right: theme.spacing(1.25),
  },
  searchContainer: {
    marginBottom: theme.spacing(2),
  },
  dialog: {
    minWidth: '600px',
  },
  link: {
    color: 'navy',
    textDecoration: 'underline',
  },
}));

/** @public */
export interface HasSubcomponentsCardProps {
  variant?: InfoCardVariants;
  tableOptions?: TableOptions;
  title?: string;
}

export const FaqLink = () => (
  <Link to="https://chofer.cloud.toyota.com/docs/default/Component/ACE-FAQ/access-and-onboarding/github-onboarding/#q-how-do-i-find-out-who-can-add-me-to-a-github-team">
    FAQ
  </Link>
);

export const LoadingButton: FC<ButtonProps & {
  loading?: boolean;
  circularProgressProps?: CircularProgressProps;
}> = ({ loading = false, children, circularProgressProps, ...otherProps }) => (
  <Button disabled={loading} {...otherProps}>
    {loading ? (
      <CircularProgress
        size={24}
        color="secondary"
        {...circularProgressProps}
      />
    ) : (
      children
    )}
  </Button>
);

export function HasSubcomponentsCard(props: HasSubcomponentsCardProps) {
  const classes = useStyles();
  const { entity } = useEntity();
  const [isOpen, setIsOpen] = useState<'repo' | 'cloud' | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRepos, setSelectedRepos] = useState<any[]>([]);

  const [isAssociateDisabled, setIsAssociateDisabled] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  // const appCatalogApi = useApi(appCatalogApiRef);
  // const githubAuthApi = useApi(githubAuthApiRef);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [repoConfirmDialog, setRepoConfirmDialog] = useState(false);
  const [repoDeleteData, setRepoDeleteData] = useState<any>({});
  const [messages, setMessages] = useState<Array<CustomAlertMessage>>([]);
  const showAssociate = entity?.spec?.type === 'application';

  const {
    variant = 'gridItem',
    tableOptions = {},
    title = 'Has Subcomponents',
  } = props;

  const handleButtonClick = (type: 'repo' | 'cloud') => {
    setIsOpen(type);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setIsOpen(null);
    setSearchQuery('');
    setSelectedRepos([]);
    setIsAssociateDisabled(true);
    setSelectAll(false);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    setIsAssociateDisabled(selectedRepos.length === 0);
  }, [selectedRepos]);

  // Simulated search results
  const searchResults = [
    {
      name: 'naq-frm-sad',
      application: 'NAQ Test',
      repoLink: 'https://github.com/Toyota-Motor-North-America/naq-frm-sad',
      appLink: '/catalog/default/component/NAQ-Test',
    },
    {
      name: 'naq-iam-library',
      application: 'NAQ Test',
      repoLink: 'https://github.com/Toyota-Motor-North-America/naq-iam-library',
      appLink: '/catalog/default/component/NAQ-Test',
    },
    {
      name: 'naq-iam-scim-common',
      application: 'NAQ Test',
      repoLink:
        'https://github.com/Toyota-Motor-North-America/naq-iam-scim-common',
      appLink: '/catalog/default/component/NAQ-Test',
    },
  ];

  const handleRepoSelect = (repoName: string) => {
    setSelectedRepos(prevSelectedRepos => {
      if (prevSelectedRepos.includes(repoName)) {
        return prevSelectedRepos.filter(repo => repo !== repoName);
      }
      return [...prevSelectedRepos, repoName];
    });
  };

  const triggerAlert = (alert: CustomAlertMessage) =>
    setMessages(alerts => alerts.concat(alert));

  const handleAssociate = async () => {
    handleClose();
  };

  const handleRepoConfirmClose = () => {
    setRepoDeleteData({});
    setRepoConfirmDialog(false);
  };

  const handleDeleteConfirmation = async (repo: any) => {
    setRepoDeleteData(repo);
    setRepoConfirmDialog(true);
  };

  const columnsWithDelete = [
    ...componentEntityColumns,
    {
      title: 'Actions',
      render: (row: any) => (
        <Tooltip title="Remove Association with Application">
          <IconButton
            onClick={() => handleDeleteConfirmation(row.metadata)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Fragment>
      <div className={classes.table}>
        <RelatedEntitiesCard
          variant={variant}
          title={title}
          entityKind="Component"
          relationType={RELATION_HAS_PART}
          columns={columnsWithDelete}
          asRenderableEntities={asComponentEntities}
          emptyMessage="No subcomponent is part of this component"
          emptyHelpLink="https://backstage.io/docs/features/software-catalog/descriptor-format#specsubcomponentof-optional"
          tableOptions={tableOptions}
        />
        {showAssociate && (
          <IconButton
            aria-label="View"
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.tableButton}
            onClick={handleClick}
            color="inherit"
            children={<LinkIcon />}
          />
        )}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleButtonClick('repo')}>
          Link Repos
        </MenuItem>
        {/* <MenuItem disabled onClick={() => handleButtonClick('cloud')}>
              Link Cloud Accounts
          </MenuItem> */}
      </Menu>

      <LinkRepoDialog
        isOpen={isOpen}
        handleClose={handleClose}
        triggerAlert={triggerAlert}
      />
      <UnlinkRepoDialog
        isOpen={repoConfirmDialog}
        repoDeleteData={repoDeleteData}
        handleClose={handleRepoConfirmClose}
        triggerAlert={triggerAlert}
      />

      <Dialog
        open={isOpen === 'cloud'}
        onClose={handleClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>Search, Select And Associate Cloud Accounts</DialogTitle>
        <DialogContent>
          <div className={classes.searchContainer}>
            <TextField
              label="Search Cloud Accounts"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectAll}
                    onChange={event => setSelectAll(event.target.checked)}
                  />
                </TableCell>
                <TableCell>Cloud Account Name</TableCell>
                <TableCell>Associated with Apps</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults
                .filter(repo =>
                  repo.name.toLowerCase().includes(searchQuery.toLowerCase()),
                )
                .map((repo, index) => (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRepos.includes(repo.name)}
                        onChange={() => handleRepoSelect(repo.name)}
                      />
                    </TableCell>
                    <TableCell>
                      <a
                        href={repo.repoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.link}
                      >
                        {repo.name}
                      </a>
                    </TableCell>
                    <TableCell>
                      <a
                        href={repo.appLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.link}
                      >
                        {repo.application}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAssociate}
            color="primary"
            disabled={isAssociateDisabled}
          >
            Associate
          </Button>
        </DialogActions>
      </Dialog>

      <CustomAlertDisplay messages={messages} setMessages={setMessages} />
    </Fragment>
  );
}

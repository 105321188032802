import React from 'react';
import {Tabs as MaterialUiTabs, Tab} from '@material-ui/core';
import {useStyles} from './styles';

interface Tab {
    label: string,
    value: string,
    content: React.ReactNode,
}

interface TabsProps {
    tabs: Tab[];
    onTabChange?: (tabIndex: number) => void;
    initialActiveTabIndex?: number;
}

const tabIndicatorStyle = {
    background: 'linear-gradient(to right, #f71840 0%, #ff9265 100%)',
    height: '3px'
};

export const Tabs = ({
    tabs,
    onTabChange,
    initialActiveTabIndex = 0,
}: TabsProps) => {
    const classes = useStyles();
    const [activeTabIndex, setActiveTabIndex] = React.useState(initialActiveTabIndex);

    const handleChange = (event: React.ChangeEvent<{}>, tabValue: string) => {
        event.preventDefault();
        const activeTabIndex = tabs.findIndex(item => item.value === tabValue);
        setActiveTabIndex(activeTabIndex);

        if (onTabChange) {
            onTabChange(activeTabIndex);
        }
    };

    const renderTabContent = React.useMemo(
        () => tabs[activeTabIndex]?.content, [activeTabIndex]
    );

    return (
        <>
            <MaterialUiTabs
                className={classes.tabsWrapper}
                TabIndicatorProps={{style: tabIndicatorStyle}}
                value={tabs[activeTabIndex].value}
                onChange={handleChange}
            >
                {tabs.map(({label, value}) => (
                    <Tab
                        label={label}
                        value={value}
                        key={value}
                        disableRipple
                        disableFocusRipple
                    />
                ))}
            </MaterialUiTabs>
            {renderTabContent}
        </>
    );
};
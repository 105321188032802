import React from 'react';
import {
    makeStyles,
    Typography
} from '@material-ui/core';

interface HeaderTimeProps {
    label: string;
    value: string;
}

const useStyles = makeStyles({
    label: {
        marginBottom: '10px',
        fontWeight: 600,
    },
    value: {
        height: '16px',
    },
    container: {
        marginRight: '32px',
        '&:last-child': {
            marginRight: 0,
        },
    },
});

export const LocationTime = ({label, value }: HeaderTimeProps) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.container}>
                <Typography variant="body2" className={classes.label}>{label}</Typography>
                <Typography variant="body2" className={classes.value}>{value}</Typography>
            </div>
        </>
    );
};
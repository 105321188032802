export const EDIT_URL_ANNOTATION = 'backstage.io/edit-url';
export const SOURCE_LOCATION_ANNOTATION = 'backstage.io/source-location';
export const TECHDOCS_REF_ANNOTATION = 'backstage.io/techdocs-ref';

export const PAGE_SIZE_OPTIONS = [10, 20, 30];

export const FEATURED_TECHDOCS_NAMES = [
    'ACE-TECH-DOCS',
    'ACE-FAQ',
    'developer-platform-guides'
];

import {Config} from '@backstage/config';
import {MarkdownCategory, FAQMetadata} from 'tmna-types';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

export const FAQApiRef = createApiRef<FAQApiInterface>({
    id: 'plugin.training.faq',
});

type FAQApiOptions = {
    configApi: Config;
    identityApi: IdentityApi;
}

interface FAQApiInterface {
    getFAQsMetadata(): Promise<FAQMetadata[]>;
}

export class FAQApi implements FAQApiInterface {
    private configApi: Config;
    private markdownCategory = MarkdownCategory.FAQ;
    private identityApi: IdentityApi;

    constructor(options: FAQApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
    }

    private async fetchAPI(url: string): Promise<Response> {
        const token = await this.identityApi.getCredentials();
        return fetch(
          url,
          {
              credentials: 'include',
              headers: { Authorization: `Bearer ${token.token}` }
          },
        );
    }

    async getFAQsMetadata() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/markdown/${this.markdownCategory}/metadata`;
        const data = await this.fetchAPI(requestUrl)
            .then(d => d.json());

        return data;
    }
}

import {Config} from '@backstage/config';
import {Notification, NotificationReadStatus, NotificationType} from 'tmna-types';
import {stringifyQueryString} from 'tmna-utils';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

type NotificationsRequestBaseParams = {
    email?: string;
}

type NotificationsRequestParams = NotificationsRequestBaseParams & {
    take?: number;
    from?: number;
    status?: NotificationReadStatus[];
    type?: NotificationType[];
}

type FetchNotificationResponse = {
    data: Notification[];
    totalCount: number;
}

type MarkAsReadRequestParams = NotificationsRequestBaseParams & {
    id?: string;
}

interface INotificationApi {
    fetchNotifications(params?: NotificationsRequestParams): Promise<FetchNotificationResponse>;
    fetchUnreadNotificationsCount(params?: NotificationsRequestBaseParams): Promise<number>;
    markRead(params?: MarkAsReadRequestParams): Promise<any>;
}

export const notificationApiRef = createApiRef<INotificationApi>({
    id: 'plugin.notifications',
});

type NotificationApiOptions = {
    configApi: Config;
    identityApi: IdentityApi;
};

export class NotificationApi implements INotificationApi {
    private configApi: Config;
    private identityApi: IdentityApi;

    constructor(options: NotificationApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
    }

    private async fetchAPI(urlBody: string, query?: NotificationsRequestParams, method?: string) {
        const token = await this.identityApi.getCredentials();
        const enrichedQuery = {
            ...query,
            email: (await this.identityApi.getProfileInfo()).email?.toLowerCase(),
        };
        const queryParams = stringifyQueryString(enrichedQuery);
        const host = this.configApi.getString('backend.baseUrl');
        const url = `${host}${urlBody}${queryParams}`;
        return fetch(url, {
            method: method || 'GET',
            credentials: 'include',
            headers: { Authorization: `Bearer ${token.token}` },
        })
    }

    public async fetchNotifications(params?: NotificationsRequestParams) {

        return this.fetchAPI('/api/notifications?', params).then(d => d.json());
    }

    public async fetchUnreadNotificationsCount(params: NotificationsRequestBaseParams) {

        return this.fetchAPI('/api/notifications/count/unread?', params)
            .then(d => d.json())
            .then(d => d.count);
    }

    public async markRead(params: MarkAsReadRequestParams) {

        return this.fetchAPI('/api/notifications/mark/read?', params, 'PUT')
            .then(d => d.json());
    }
}

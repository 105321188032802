import {Config} from '@backstage/config';
import {RssLink} from './types';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

export const rssApiRef = createApiRef<RSSApiInterface>({
    id: 'plugin.welcome-page.rss',
});

type RSSApiOptions = {
    configApi: Config;
    identityApi: IdentityApi;
}

interface RSSApiInterface {
    getRSAFeedItems(feedLink: string): Promise<RssLink[]>;
}

export class RSSApi implements RSSApiInterface {
    private configApi: Config;
    private identityApi: IdentityApi;

    constructor(options: RSSApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
    }

    async getRSAFeedItems(feedLink: string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/rss-fetch/get-feed?link=${feedLink}`;
        const token = await this.identityApi.getCredentials();
        const data = await fetch(requestUrl, {
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${token.token}`,
            }
        }).then(d => d.json());

        return data.body;
    }
}

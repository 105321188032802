import React from 'react';
import {Typography} from '@material-ui/core';
import {Content} from 'tmna-ui-kit';

import {useStyles} from './styles';

interface HeaderProps {
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
}

export const Header = ({
    title,
    subtitle,
}: HeaderProps) => {
    const classes = useStyles();

    return (
        <header className={`${classes.container} userSettingsPageHeader`}>
            <Content className={classes.content}>
                <Typography variant='h1'>
                    {title}
                </Typography>
                {subtitle && <Typography className={classes.subtitle} variant='subtitle1'>
                    {subtitle}
                </Typography>}
            </Content>
        </header>
    )
};

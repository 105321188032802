import React from 'react';
import cn from 'classnames';
import {Grid, Divider} from '@material-ui/core';

import {ArticlePaginationLink} from './components';

import {useStyles} from './styles';

type ArticlePaginationLink = {
    title: string;
    to: string;
}

type Props = {
    prevLink?: ArticlePaginationLink;
    nextLink?: ArticlePaginationLink;
}

export const ArticlePagination = ({
    prevLink,
    nextLink,
}: Props) => {
    const classes = useStyles();

    return (
        <>
            <Divider />
            <Grid container wrap="nowrap" spacing={4} justifyContent="space-between" className={classes.container}>
                <Grid item className={classes.item}>
                    {prevLink && (
                        <ArticlePaginationLink
                            to={prevLink.to}
                            label="Previous"
                            title={prevLink.title}
                        />
                    )}
                </Grid>
                <Grid item className={cn(classes.item, classes.itemRight)}>
                    {nextLink && (
                        <ArticlePaginationLink
                            to={nextLink.to}
                            label="Next"
                            title={nextLink.title}
                            position="right"
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

import React from 'react';
import { Avatar } from '@backstage/core-components';

export interface Props {
    icon?: string;
    fullName: string;
}
const avatarStyles: React.CSSProperties = {
    width: '24px',
    height: '24px',
    fontSize: '10px',
    letterSpacing: 0,
    background: 'linear-gradient( to bottom, #f71840, #ff9265)',
    textTransform: "uppercase",
};

export const BlogCardAvatar: React.FC<Props> = ({
    icon,
    fullName,
}) => (
    <Avatar
        picture={icon}
        customStyles={avatarStyles}
        displayName={fullName}
    />
);

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    container: {
        height: '100%',
    },
    arrowIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    articleContent: {
        width: '100%',
        margin: '5px 0',
    },
    articleDate: {
        fontWeight: theme.typography.fontWeightMedium as any,
        display: 'block',
        marginBottom: theme.spacing(0.5),
    },
    articleTitle: {
        fontWeight: theme.typography.fontWeightBold as any,
        display: 'block',
        marginBottom: theme.spacing(0.5),
    },
    articleImage: {
        width: '70px',
        height: '70px',
    },
    linkContainer: {
        '&:hover': {
            '& $articleTitle': {
                color: theme.palette.tmnaColors.primary.gray,
            }
        }
    }
}));

import { TableColumn } from '@backstage/core-components';
import { CloudAccountChargebackDetails } from 'tmna-types';
import { DatePeriod } from './types';

const DEFAULT_VALUE = 'N/A';
export const getColumns = (
  datePeriods: Array<DatePeriod>,
): Array<TableColumn<CloudAccountChargebackDetails>> => [ 
  {
    field: 'accountId',
    type: 'string',
    title: 'Account id',
    cellStyle: {
        minWidth: '160px',
    },
    emptyValue: DEFAULT_VALUE,
    filtering: false,
},
{
  field: 'Account_Name',
  type: 'string',
  title: 'Account Name',
  cellStyle: {
      minWidth: '170px',
  },
  emptyValue: DEFAULT_VALUE,
  filtering: false,
},
{
  field: 'estimated_budget',
  type: 'currency',
  title: 'Estimated Spend',
  cellStyle: {
    minWidth: '185px',
    textAlign:'right'
  },
  align: 'right',
  emptyValue: DEFAULT_VALUE,
  filtering: false,
},
  ...datePeriods.map(
    period =>
      ({
        field: period.value,
        title: period.label,
        type: 'currency',
        currencySettings: {
          locale: 'en_US',
        },
        align: 'right',
        cellStyle: {
          minWidth: '140px',
          fontWeight: period.label === 'total' && 'bold',
          whiteSpace:'nowrap',
          textAlign:'right'
        },
        headerStyle: {
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: 'black',
        },
        emptyValue: DEFAULT_VALUE,
        filtering: false,
      } as TableColumn<CloudAccountChargebackDetails>),
  ),
  {
    field: 'total',
    type: 'currency',
    title: 'Total',
    align: 'right',
    cellStyle: {
      minWidth: '140px',
      fontWeight: 'bold',
      whiteSpace:'nowrap',
      textAlign:'right'
    },
    emptyValue: DEFAULT_VALUE,
    filtering: false,
  },
];

import React, { useLayoutEffect, useState, useMemo, useCallback } from 'react';
import {
    SignInPageProps,    
    useApi,
    useApiHolder,
    errorApiRef,
    IdentityApi
} from '@backstage/core-plugin-api';
import { SignInConfig, IdentityProviders, SignInProvider } from './types';
import { commonProvider } from './commonProvider';
import { IdentityApiSignOutProxy } from './IdentityApiSignOutProxy';

const PROVIDER_STORAGE_KEY = '@backstage/core:SignInPage:provider';

export type SignInProviderType = {
    [key: string]: {
        components: SignInProvider;
        id: string;
        config?: SignInConfig;
    };
};

const signInProviders: { [key: string]: SignInProvider } = {
    common: commonProvider,
};

function validateIDs(id: string, providers: SignInProviderType): void {
    if (id in providers)
        throw new Error(
            `"${id}" ID is duplicated. IDs of identity providers have to be unique.`,
        );
}

export function getSignInProviders(
    identityProviders: IdentityProviders,
): SignInProviderType {
    const providers = identityProviders.reduce(
        (acc: SignInProviderType, config) => {
            if (typeof config === 'string') {
                validateIDs(config, acc);
                acc[config] = { components: signInProviders[config], id: config };

                return acc;
            }

            const { id } = config as SignInConfig;
            validateIDs(id, acc);

            acc[id] = { components: signInProviders.common, id, config };

            return acc;
        },
        {},
    );

    return providers;
}

export const useSignInProviders = (
    providers: SignInProviderType,
    onSignInSuccess: SignInPageProps["onSignInSuccess"],
) => {
    const errorApi = useApi(errorApiRef);
    const apiHolder = useApiHolder();
    const [loading, setLoading] = useState(true);

    const handleWrappedResult = useCallback(
        (identityApi: IdentityApi) => {
            onSignInSuccess(
                IdentityApiSignOutProxy.from({
                  identityApi,
                  signOut: async () => {
                    localStorage.removeItem(PROVIDER_STORAGE_KEY);
                    await identityApi.signOut?.();
                  },
                }),
              );
            },
        [onSignInSuccess],
    );

    useLayoutEffect(() => {
        if (!loading) {
            return undefined;
        }

        const selectedProviderId = localStorage.getItem(
            PROVIDER_STORAGE_KEY,
        ) as string;

        if (selectedProviderId === null) {
            setLoading(false);
            return undefined;
        }

        const provider = providers[selectedProviderId];
        if (!provider) {
            setLoading(false);
            return undefined;
        }

        let didCancel = false;

        provider.components
            .loader(apiHolder, provider.config?.apiRef!)
            .then(result => {
                if (didCancel) {
                    return;
                }
                if (result) {
                    handleWrappedResult(result);
                } else {
                    setLoading(false);
                }
            })
            .catch(error => {
                if (didCancel) {
                    return;
                }
                errorApi.post(error);
                setLoading(false);
            });

        return () => {
            didCancel = true;
        };
    }, [loading, errorApi, onSignInSuccess, apiHolder, providers, handleWrappedResult]);

    const elements = useMemo(
        () =>
            Object.keys(providers).map(key => {
                const provider = providers[key];

                const { Component } = provider.components;

                const handleResult = (result: IdentityApi) => {
                    localStorage.setItem(PROVIDER_STORAGE_KEY, provider.id);

                    handleWrappedResult(result);
                };

                return (
                    <Component
                        key={provider.id}
                        config={provider.config!}
                        onSignInSuccess={handleResult}
                    />
                );
            }),
        [providers, handleWrappedResult],
    );

    return [loading, elements];
};

import { makeStyles } from '@material-ui/core';
import { TMNATheme } from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(_theme => ({
  cardContainer: {
    '& > .MuiCardHeader-root > .MuiCardHeader-content > .MuiTypography-root': {
      fontSize: '1.2rem',
    },
  },
}));

import * as routes from './routeRefs';

import {RSSApi, rssApiRef} from './api';
import { configApiRef, createApiFactory, createPlugin, identityApiRef } from '@backstage/core-plugin-api';

export const plugin = createPlugin({
  id: 'welcome',
  routes: {
    welcome: routes.welcomePageRouteRef,
  },
  externalRoutes: {
    portalOverviewLink: routes.portalOverviewExternalRouteRef,
    blueprintsLink: routes.blueprintsExternalRouteRef,
    docsLink: routes.docsExternalRouteRef,
  },
  apis: [
    createApiFactory({
      api: rssApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({configApi, identityApi}) =>
          new RSSApi({ configApi, identityApi }),
    }),
  ],
});

import {NotificationType} from 'tmna-types';
import {NotificationCategory, NotificationStatus, NotificationTypeObject} from './types';

export const NOTIFICATION_TYPES_MAP: Record<NotificationType, NotificationTypeObject> = {
    // TODO: use externalRouteRefs
    [NotificationType.FCBN]: {
        status: NotificationStatus.INFO,
        category: NotificationCategory.FIN_OPS,
        subcategory: 'Chargeback',
        link: '/cost-center',
        template: 'Your ${ cloud } Chargebacks for ${ month } are ready'
    },
    [NotificationType.FORN]: {
        status: NotificationStatus.WARNING,
        category: NotificationCategory.FIN_OPS,
        subcategory: 'Optimization',
        link: 'https://teams.microsoft.com/_#/files/General?threadId=19%3A477e3d85492e4b0b98eb3d641be39037%40thread.tacv2&ctx=channel&context=FY21-%2520Cost%2520Optimization&rootfolder=%252Fsites%252FPublicCloudChargebacks%252FShared%2520Documents%252FGeneral%252FFY21-%2520Cost%2520Optimization',
        template: 'Through the analysis of your cloud workloads on ${ month }, we have identified various money saving opportunities for your account: ${ account }'
    },
    [NotificationType.SPVN]: {
        status: NotificationStatus.WARNING,
        category: NotificationCategory.SECURITY,
        subcategory: 'Security Violation',
        subcategoryShortName: 'Violation',
        link: 'https://app.powerbi.com/groups/me/apps/ebf0ed65-fdee-458e-b0c9-a4f851919f5b/reports/4b89fe13-15ba-4252-8843-a0f4c2cad61c/ReportSection9e45d06cbede82a27c07',
        template: 'Report for ${ month }: You have non-compliant resources in ${ account }, please check them.'
    },
    [NotificationType.SNN]: {
        status: NotificationStatus.URGENT,
        category: NotificationCategory.SECURITY,
        subcategory: 'AWS Nuke',
        template: 'We will remove the resources from the sandbox AWS account (${ account }) on Sunday.',
    },
    [NotificationType.SAWSW]: {
        status: NotificationStatus.WARNING,
        category: NotificationCategory.SECURITY,
        subcategory: 'AWS Security notification',
        subcategoryShortName: 'AWS notification',
    },
    [NotificationType.SAZW]: {
        status: NotificationStatus.WARNING,
        category: NotificationCategory.SECURITY,
        subcategory: 'AZURE Security notification',
        subcategoryShortName: 'AZURE notification',
    },
    [NotificationType.SARN]: {
        status: NotificationStatus.WARNING,
        category: NotificationCategory.SECURITY,
        subcategory: 'Security Autoremediation notification', 
    },
    [NotificationType.MSEN]: {
        status: NotificationStatus.WARNING,
        category: NotificationCategory.FIN_OPS,
        subcategory: 'Monthly Spend Estimate',
        template: 'Your Monthly Estimated Spends are available'
    },
};

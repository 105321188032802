import { createRouteRef } from '@backstage/core-plugin-api';

export const trainingsMainPageRouteRef = createRouteRef({
    id: 'trainings-main-page',
});

export const trainingPageRouteRef = createRouteRef({
    id: 'training-page',
    params: ["trainingId"],
});

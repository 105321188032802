/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { UserSettingsProfileCard } from './UserSettingsProfileCard';
import { UserSettingsAppearanceCard } from './UserSettingsAppearanceCard';
import { UserSettingsAuthProviders } from '../AuthProviders';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles(() => ({
    cardContainer: {
        display: 'flex'
    },
}));

export const UserSettingsGeneral = () => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const providersConfig = configApi.getOptionalConfig('auth.providers');
  const removeProviders = useMemo(() => ['microsoft'], []);
  const staticProviders = useMemo(
    () =>
      providersConfig
        ?.keys()
        .filter(value => !removeProviders.includes(value)) ?? [],
    [providersConfig, removeProviders],
  );

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item sm={12} md={6}>
        <UserSettingsProfileCard />
      </Grid>
      <Grid item sm={12} md={6} className={classes.cardContainer}>
        <UserSettingsAppearanceCard />
      </Grid>
      <Grid item sm={12}>
        <UserSettingsAuthProviders staticProviders={staticProviders} />
      </Grid>
    </Grid>
  );
};

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import { InfoCard } from '@backstage/core-components';
import { LinkedField } from './LinkedField';
import {
  Entity,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
} from '@backstage/catalog-model';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';

const useHasPartOwnedBy = (entity: Entity) => {
  const { entities } = useRelatedEntities(entity, {
    type: RELATION_HAS_PART,
    kind: 'component',
  });
  const hasPartOwnedBy = useMemo(() => {
    const allHasPart =
      entities?.flatMap(hasPartEntity =>
        getEntityRelations(hasPartEntity, RELATION_OWNED_BY),
      ) ?? [];
    const uniqueHasPart = [
      ...new Map(allHasPart.map(c => [c.name, c])).values(),
    ];
    return uniqueHasPart;
  }, [entities]);

  return hasPartOwnedBy;
};

export const EntityLinkedContactsCard = () => {
  const [resultData, setResultData] = useState({} as any);
  const { entity } = useEntity();
  const classes = useStyles();
  const hasPartOwnedBy = useHasPartOwnedBy(entity);
  const data = entity?.metadata;
  const { entities } = useRelatedEntities(entity, {
    type: RELATION_DEPENDS_ON,
    kind: 'component',
  });
  const data1: { [key: string]: any } = {
    financialSponsor: [],
    accountOwner: [],
    technicalContact: [],
  };

  useEffect(() => {
    try {
      let resultData1: any = {};

      entities &&
        entities.forEach((item: any) => {
          data1.financialSponsor.push(...item.metadata.financialSponsor);
          data1.accountOwner.push(...item.metadata.accountOwner);
          data1.technicalContact.push(...item.metadata.technicalContact);
        });

      Object.keys(data1).map((key: any) => {
        data1[key].sort((a: any, b: any) => a.name.localeCompare(b.name));
        resultData1[key] = data1[key].filter(
          (value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t.name === value.name),
        );
      });

      setResultData(resultData1);
    } catch (error) {
      console.log('Error', error);
    }
  }, [entities]);
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const isTypeApp = isComponent && entity.spec?.type === 'application';
  const hasAnyData = (data: any): boolean => {
    return (
      data?.financialSponsor?.length > 0 ||
      data?.accountOwner?.length > 0 ||
      data?.technicalContact?.length > 0
    );
  };
  return (
    <>
      {isTypeApp && (
        <Grid item>
          <InfoCard title="Linked Contacts" className={classes.infoDiv}>
            <div className={classes.gitDiv}>
              <strong>GITHUB TEAM</strong>
            </div>
            <LinkedField label="" value="No GitHub Team" gridSizes={{ xs: 12 }}>
              {hasPartOwnedBy.length > 0 && (
                <EntityRefLinks
                  entityRefs={hasPartOwnedBy}
                  defaultKind="group"
                />
              )}
            </LinkedField>

            <div className={classes.mainDiv}>
              <strong>APP CONTACTS</strong>
            </div>
            <Grid container>
              <Fragment>
                <LinkedField
                  label="Portfolio manager"
                  value={(data?.portfolioManager as string) || '–'}
                  gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                />
                <LinkedField
                  label="Program manager"
                  value={(data?.programManager as string) || '–'}
                  gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                />
                <LinkedField
                  label="Business Owner"
                  value={(data?.appOwner as string) || '–'}
                  gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                />
                <LinkedField
                  label="Product Owner"
                  value={(data?.productOwner as string) || '–'}
                  gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                />
                <LinkedField
                  label="Supported by"
                  value={(data?.supportedBy as string) || '–'}
                  gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                />
              </Fragment>
            </Grid>
            {hasAnyData(resultData) && (
              <>
                <div className={classes.mainDiv}>
                  <strong>CLOUD CONTACTS</strong>
                </div>
                <Grid container>
                  <LinkedField
                    label="Financial Sponsor"
                    gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                  >
                    <div className={classes.container}>
                      {resultData?.financialSponsor?.length
                        ? resultData.financialSponsor
                            .map((u: any) => u.name)
                            .join(', ')
                        : '-'}
                    </div>
                  </LinkedField>
                  <LinkedField
                    label="Account Owner"
                    gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                  >
                    <div className={classes.container}>
                      {resultData?.accountOwner?.length
                        ? resultData.accountOwner
                            .map((u: any) => u.name)
                            .join(', ')
                        : '-'}
                    </div>
                  </LinkedField>
                  <LinkedField
                    label="Technical Contacts"
                    gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                  >
                    <div className={classes.container}>
                      {resultData?.technicalContact?.length
                        ? resultData.technicalContact
                            .map((u: any) => u.name)
                            .join(', ')
                        : '-'}
                    </div>
                  </LinkedField>
                </Grid>
              </>
            )}
          </InfoCard>
        </Grid>
      )}
    </>
  );
};

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

const secondaryButton = {
    margin: '0px 5px 0 0px',
    boxShadow: '0 0 0 2px #00324c inset',
    color: '#00324c',
    '&:hover': {
        boxShadow: '0 0 0 2px #002639 inset',
        color: '#002639',
    },
}

export const useStyles = makeStyles<TMNATheme>(() => ({
    headerLabel: {
        float: 'left',
        margin: '12px'
    },
    buttonHeader:{
        float: 'right',
        margin: '12px'
    },
    primaryButton: {
        backgroundColor: '#00324c',
        '&:hover': {
            backgroundColor: '#002639',
        },
    },
    secondaryButton,
    tertiaryButton: {
        ...secondaryButton,
        textTransform: 'capitalize',
    },
    clearMsg:{
        width: 'fit-content'
    },
    MsgDesc:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        fontWeight: 400,
        fontSize: '16px',
    },
    fieldContainer:{
        marginLeft: '10px',
        marginTop: '10px'
    },
    fieldLabel:{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        wordBreak: "break-word"
    },
    fieldLabelWarn:{
        fontSize: '12px',
        marginTop: '10px',
        color: 'red'
    }
}));

import React from 'react';

import {BlogArticleMetadata} from 'tmna-types';
import {CardContent} from '@material-ui/core';
import {CardLink} from 'tmna-ui-kit';

import {BlogCardContent} from '../BlogCardContent';
import {useBlogLink} from '../../hooks/use-blog-link';
import {useStyles} from './styles';

const VISIBLE_TAGS_COUNT = 2;

interface Props {
    query?: string;
    metadata: BlogArticleMetadata;
    cardClassName?: string;
}

export const BlogCard: React.FC<Props> = ({
    metadata,
    query,
    cardClassName,
}) => {
    const classes = useStyles();
    const blogLink = useBlogLink({
        articleId: metadata.id,
    });

    const link = query ? `${blogLink}?${query}` : blogLink;

    return (
        <CardLink to={link} className={cardClassName}>
            <CardContent>
                <BlogCardContent
                    title={metadata.title}
                    date={metadata.date}
                    author={metadata.author}
                    tags={metadata.tags}
                    visibleTagsCount={VISIBLE_TAGS_COUNT}
                    className={classes.card}
                />
            </CardContent>
        </CardLink>
    );
}


import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    container: {
        position: 'relative',
        '&:hover': {
            '& $title': {
                color: theme.palette.tmnaColors.secondary.darkRed,
            }
        }
    },
    left: {
        paddingLeft: theme.spacing(5),
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingRight: theme.spacing(5),
    },
    label: {
        fontWeight: theme.typography.fontWeightRegular as any,
    },
    title: {
        fontWeight: theme.typography.fontWeightBold as any,
        marginTop: theme.spacing(0.5),
    },
    icon: {
        position: 'absolute',
        top: '25px',
    },
    iconLeft: {
        left: 0,
        transform: 'rotate(180deg)',
    },
    iconRight: {
        right: 0,
    }
}));

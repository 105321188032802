import {Entity, parseLocationRef} from '@backstage/catalog-model';
import {
    EDIT_URL_ANNOTATION,
    FEATURED_TECHDOCS_NAMES,
    SOURCE_LOCATION_ANNOTATION,
    TECHDOCS_REF_ANNOTATION
} from './constants';
import {TechDocsTableRow} from './types';
import {generatePath} from "react-router-dom";
// import {rootDocsRouteRef} from "../routes";

export function getTechdocSourceUrl(techdoc: Entity): string | undefined {
    const location = techdoc.metadata.annotations?.[SOURCE_LOCATION_ANNOTATION] ||
        techdoc.metadata.annotations?.[TECHDOCS_REF_ANNOTATION];
    if (!location) {
        return undefined;
    }

    const { type, target } = parseLocationRef(location);
    if (type === 'url') {
        return target;
    }
    return undefined;
}

export const mapTechDocsToTableData = (techdocs: Entity[]): TechDocsTableRow[] => (
    techdocs.map((techdoc) => ({
        name: techdoc.metadata.name,
        description: techdoc.metadata.description,
        owner: techdoc?.spec?.owner,
        namespace: techdoc.metadata.namespace,
        kind: techdoc.kind,
        editUrl: techdoc.metadata.annotations?.[EDIT_URL_ANNOTATION],
        sourceUrl: getTechdocSourceUrl(techdoc)
    }))
);

export const getTechdocUrl = (
    namespace: string | undefined,
    kind: string,
    name: string,
) => (
    generatePath(':namespace/:kind/:name/*', {
        namespace: namespace ?? 'default',
        kind: kind,
        name: name,
    } as any)
);

export const getFeaturedTechdocs = (techdocs: Entity[]) => (
    techdocs.filter(techdoc => (
        FEATURED_TECHDOCS_NAMES.includes(techdoc.metadata.name)
    ))
);

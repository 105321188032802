import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    button: {
       marginLeft: theme.spacing(0.5),
    },
    lightButton: {
        color: theme.palette.tmnaColors.primary.white,
        '&:hover': {
            backgroundColor: 'rgba(230, 230, 230, 0.3)',
        },
    },
}));
import React from 'react';
import { TMNATheme } from 'tmna-theme';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { LogoutIcon } from './LogoutIcon';
import { SidebarAvatar } from './SidebarAvatar';

import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { SidebarContext, SidebarItem } from '@backstage/core-components';
import { useAsync } from 'react-use';

const useStyles = makeStyles((theme: TMNATheme) => ({
    linkContainer: {
        width: '145px',
        // TODO: remove when customize settings page
        // pointerEvents: 'none',
    },
    logoutIconContainer: {
        height: '100%',
        padding: theme.spacing(0,1),
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.navigation.color,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.navigation.selectedColor,
        }
    }
}));

type Props = {
    to: string;
}

// const profile = await identityApi.getProfileInfo();
export const SidebarSettings = ({
    to,
}: Props) => {
    const classes = useStyles();
    const identityApi = useApi(identityApiRef);
    //     const { value} = useAsync(() =>
    //     identityApi.getProfileInfo();
    //   );
    const { value } = useAsync(() => {
        return identityApi.getProfileInfo();
    })
    const { isOpen } = React.useContext(SidebarContext);

    const signOut = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        identityApi.signOut();
    }, []);

    return (
        <Grid container spacing={0} wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Grid item className={classes.linkContainer}>
                <SidebarItem
                    text={value?.displayName}
                    to={to}
                    icon={() => <SidebarAvatar
                        picture={value?.picture}
                        displayName={value?.displayName}
                    />}
                />
            </Grid>
            {isOpen && (
                <span onClick={signOut} className={classes.logoutIconContainer}>
                    <LogoutIcon />
                </span>
            )}
        </Grid>
    );
};

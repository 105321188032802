/*
 * Copyright 2023 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useState } from 'react';

import { Table, TableProps } from '@backstage/core-components';
import { CatalogTableRow } from '@backstage/plugin-catalog';
import { debounce } from 'lodash';
import { CatalogTableToolbar } from './CatalogTableToolbar';

type PaginatedCatalogTableProps = {
  prev?(): void;
  next?(): void;
} & TableProps<CatalogTableRow>;

/**
 * @internal
 */
export function PaginatedCatalogTable(props: PaginatedCatalogTableProps) {
  const [currentPage, setCurrentPage] = useState(0);

  const {
    columns,
    data,
    next,
    prev,
    title,
    isLoading,
    options,
    ...restProps
  } = props;

  const onPageChange = debounce(page => {
    if (page > currentPage) {
      next?.();
    } else {
      prev?.();
    }
    setCurrentPage(page);
  }, 100);

  useEffect(() => {
    if (!props?.prev) setCurrentPage(0);
  }, [props?.prev]);

  return (
    <Table
      title={title}
      isLoading={isLoading}
      columns={columns}
      data={data}
      options={{
        ...options,
        // These settings are configured to force server side pagination
        // paginationPosition: 'both',
        pageSizeOptions: [],
        showFirstLastPageButtons: false,
        // pageSize: Number.MAX_SAFE_INTEGER,
        // emptyRowsWhenPaging: false,
      }}
      components={{
        Toolbar: CatalogTableToolbar,
      }}
      onPageChange={onPageChange}
      page={currentPage}
      {...restProps}
    />
  );
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
//import CostCenterIcon from '@material-ui/icons/MonetizationOn';
import CatalogIcon from '@material-ui/icons/Widgets';
import BlogIcon from '@material-ui/icons/Sms';
import School from '@material-ui/icons/School';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import DevopsCostsToolsIcon from './devops-costs-icon.png';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import InsightsSVG from './asserts/insights.svg';

import {
  createTheme,
  Link,
  makeStyles,
  Tooltip,
  ThemeProvider,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DocsIcon from '@material-ui/icons/Description';

import { TMNAPalette, TMNATheme } from 'tmna-theme';
import { NotificationsContext } from '@internal/plugin-notifications';
import LogoIcon from './logo/LogoIcon';

import { SidebarSettings } from './components';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarItem,
  SidebarSpace,
} from '@backstage/core-components';
import { useApi, identityApiRef, configApiRef} from '@backstage/core-plugin-api';
import { notificationApiRef } from '@internal/plugin-notifications/src/api';
import { useAsync } from 'react-use';
//import { SidebarSearch } from '@backstage/plugin-search';
import {SearchText}  from './SearchText';



const useSidebarStyles = makeStyles<TMNATheme>(theme => ({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: 'auto',
    marginLeft: 16,
    height: 40,
    display: 'block',
    lineHeight: 1,
  },
  disabled: {
    opacity: 0.7,
  },
  navBar: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
    '& a': {
      '&:hover': {
        color: theme.palette.tmnaColors.primary.white,
      },
      height: '40px',
      '&[aria-current]': {
        borderImage: 'linear-gradient( to bottom, #f71840, #ff9265) 0 0 0 100%',
      },
      '& span.MuiTypography-subtitle2': {
        fontSize: '14px',
        paddingLeft: '10px',
        width: '190px',
      },
    },
  },
  searchContainer: {
    paddingRight:'17px',
    '& input': {
      background: '#585858',
      color: '#E6E6E6',
      borderRadius: '0px 5px 5px 0px',
      textIndent: '10px',
      fontSize:'14px',
      height: '4px',
      fontWeight: '700',
      letterSpacing: 'inherit',
      padding:'17.5px 0px !important',
    },
    '& fieldset':{
      borderWidth:"0px !important"
    }
  },
  divider: {
    backgroundColor: theme.palette.tmnaColors.secondary.darkGray,
  },
  devopsCostsIcon: {
    '& img': {
      opacity: 0.7,
    },
    '&:hover img': {
      opacity: 1,
    },
    '&[aria-current] img': {
      opacity: 1,
    },
  },
}));
const calculateNotifications = (input: any) => {
  if (input === 1) {
    return `${input} Notification`;
  }
  return `${input} Notifications`;
};

const SidebarLogo = () => {
  const classes = useSidebarStyles();
  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        <LogoIcon />
      </Link>
    </div>
  );
};

const sidebarSearchTheme = createTheme({
  palette: TMNAPalette,
});

const InsightsIcon = () => (
  <img src={InsightsSVG} alt="insights" width={20} height={20} />
);

export const AppSidebar = () => {
  const classes = useSidebarStyles();
  const DevopsCostsIcon = () => {
    return <img src={DevopsCostsToolsIcon} />;
  };
  const notificationsApi = useApi(notificationApiRef);
  const identityApi = useApi(identityApiRef);
  //to support feature flag
  const configApi = useApi(configApiRef); 
  const { value: notifications } = useAsync(async () => {
    const response = await notificationsApi.fetchNotifications({
      email: (await identityApi.getProfileInfo()).email,
    });
    return response.data.slice(0, 6);
  }, [notificationsApi, identityApi]);

  const numberOfNotifications = calculateNotifications(notifications?.length);
  const { unreadCount: unreadNotificationsCount = 0 } = React.useContext(
    NotificationsContext,
  );
  return (
    <Sidebar>
      <SidebarLogo />
      <div className={classes.navBar}>
        <ThemeProvider theme={sidebarSearchTheme}>
          <div className={classes.searchContainer}>
            {/* <SidebarSearch /> */}
            <SearchText/>
          </div>
        </ThemeProvider>
        <SidebarDivider className={classes.divider} />
        <SidebarItem icon={HomeIcon} to="welcome" text="Home" />
        <SidebarItem icon={CatalogIcon} to="/catalog" text="App Catalog" />
        <SidebarItem icon={TrackChangesIcon} to="techradar" text="Tech Radar" />
        <SidebarItem icon={DocsIcon} to="/docs" text="TechDocs" end />
        <SidebarItem
          icon={CloudQueueIcon}
          to="cloud-account"
          text="Cloud Account"
        />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Blueprints" />

        <SidebarItem
          className={classes.devopsCostsIcon}
          icon={DevopsCostsIcon}
          to="devops-tools-costs"
          text="DevOps Tools Cost"
        />
        {configApi.getOptionalString('app.insights')==="true" && <SidebarItem icon={InsightsIcon} to="insights" text="Insights" />}
        <SidebarItem icon={BlogIcon} to="blogs" text="Blogs" />
        <SidebarItem icon={School} to="training" text="Training" />
        <SidebarItem
          icon={ContactSupportIcon}
          to="docs/default/Component/ACE-FAQ"
          text="FAQs"
        />
        <SidebarSpace />
        <Tooltip title={numberOfNotifications} placement="top">
          <SidebarItem
            icon={NotificationsIcon}
            to="notifications"
            text="Notifications"
            hasNotifications={unreadNotificationsCount > 0}
          />
        </Tooltip>
        <SidebarSettings to="settings" />
      </div>
    </Sidebar>
  );
};

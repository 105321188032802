import React from 'react';
import {useAsync} from 'react-use';
import {
    Box,
    Grid,
    Typography,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {ContentGuard, Link} from 'tmna-ui-kit';
import {trainingsMainPageRouteRef} from '../../routeRefs';

import {tutorialsApiRef} from '../../api';
import {TrainingCard} from '../TrainingCard';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';

export const LatestTraining = () => {
    const tutorialsApi = useApi(tutorialsApiRef);
    const trainingMainPageRoute = useRouteRef(trainingsMainPageRouteRef)();

    const { value: items, loading, error } = useAsync(async () => {
        return await tutorialsApi.getLatestTrainingMetadata();
    }, []);

    return (
        <ContentGuard isFetching={loading} error={error}>
            {/* TODO: add placeholder*/}
            {items && items.length > 0 && (
                <>
                    <Box mb={4}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h3">
                                    Latest Training
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link
                                    to={trainingMainPageRoute}
                                    icon={<NavigateNextIcon/>}
                                >
                                    <Typography variant="button">
                                        View all
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={2} wrap="nowrap">
                        {items.map((item) => (
                            <Grid item xs key={item.id}>
                                <TrainingCard data={item}/>
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </ContentGuard>
    )
};

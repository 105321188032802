import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    text: {}, // needed for textLink styling
    textLink: {
        color: theme.palette.tmnaColors.secondary.darkRed,
        display: 'inline-block',
        '&:hover': {
            '& $text': {
                textDecoration: 'underline'
            }
        }
    },
    icon: {},
    primaryLink: {
        color: theme.palette.tmnaColors.primary.black,
        fontWeight: theme.typography.fontWeightBold as any,
        '& $icon': {
            color: theme.palette.tmnaColors.primary.red,
        },
        '&:hover': {
            color: theme.palette.tmnaColors.secondary.darkRed,
            '& $icon': {
                color: theme.palette.tmnaColors.primary.red,
            }
        }
    },
    secondaryLink: {
        color: theme.palette.tmnaColors.primary.black,
        fontWeight: theme.typography.fontWeightBold as any,
        '&:hover': {
            color: theme.palette.tmnaColors.primary.gray,
        }
    },
    tertiaryLink: {
        color: theme.palette.tmnaColors.secondary.darkRed,
        fontWeight: theme.typography.fontWeightBold as any,
        '&:hover': {
            color: theme.palette.tmnaColors.primary.red,
        }
    },
    disabled: {
        color: theme.palette.tmnaColors.secondary.mediumGray,
        pointerEvents: 'none',
    }
}));

import {Config} from '@backstage/config';
import {ResponseError} from '@backstage/errors';
import {TutorialMetadata} from 'tmna-types';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

export const tutorialsApiRef = createApiRef<TutorialsApiInterface>({
    id: 'plugin.training.tutorials',
});

type TutorialsApiOptions = {
    configApi: Config;
    identityApi: IdentityApi;
}

interface TutorialsApiInterface {
    getTutorialsMetadata(): Promise<TutorialMetadata[]>;
    getTutorialContent(tutorialId: string): Promise<string>;
    getLatestTrainingMetadata(): Promise<TutorialMetadata[]>;
}

export class TutorialsApi implements TutorialsApiInterface {
    private configApi: Config;
    private identityApi: IdentityApi;

    constructor(options: TutorialsApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
    }

    private async fetchAPI(url: string): Promise<Response> {
        const token = await this.identityApi.getCredentials();
        const response = await fetch(
          url,
          {
              credentials: 'include',
              headers: { Authorization: `Bearer ${token.token}` }
          },
        );

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return response
    }

    async getTutorialsMetadata() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/training/metadata`;
        const data = await this.fetchAPI(requestUrl)
            .then(d => d.json());

        return data;
    }

    async getTutorialContent(tutorialId: string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/training/${tutorialId}/content`;
        const data = await this.fetchAPI(requestUrl)
            .then(d => d.text());

        return data;
    }

    async getLatestTrainingMetadata() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const requestUrl = `${backendUrl}/api/training/metadata/latest`;
        const queryParams = new URLSearchParams({take: '4'}).toString();
        const data = await this.fetchAPI(`${requestUrl}?${queryParams}`)
            .then(d => d.json());

        return data;
    }
}

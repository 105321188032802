import {NotificationReadStatus, NotificationType} from 'tmna-types';

export enum NotificationCategory {
    FIN_OPS = 'FinOps',
    SECURITY = 'Security',
}

export enum NotificationStatus {
    INFO = 'info',
    WARNING = 'warning',
    URGENT = 'urgent',
}

export type NotificationTypeObject = {
    status: NotificationStatus;
    category: NotificationCategory;
    subcategory: string;
    subcategoryShortName?: string;
    link?: string;
    template?: string;
}

export type NotificationFilters = {
    type?: NotificationType[];
    status?: NotificationReadStatus[];
}

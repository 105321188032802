import React, { Fragment } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useEffectOnce } from "react-use";
import { useApi, configApiRef, microsoftAuthApiRef } from '@backstage/core-plugin-api';

const initDataDog = (
  applicationId: string,
  clientToken: string,
  site: string = 'datadoghq.com',
  env: string = 'development'
) => datadogRum.init({
  applicationId,
  clientToken,
  site,
  env,
  //  version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  service: 'chofer-cloud-ui',
  allowedTracingOrigins: [/https:\/\/.*\.4poc\.net/, /https:\/\/.*\.toyota\.com/]
});
export const DataDogWatcher = () => {
  const configApi = useApi(configApiRef);
  const datadogConfig = configApi.getConfig('app.datadogRum');
  const applicationId = datadogConfig.getOptionalString('applicationId');
  const clientToken = datadogConfig.getOptionalString('clientToken');
  const site = datadogConfig.getOptionalString('site');
  const env = datadogConfig.getOptionalString('env');
  const authref = useApi(microsoftAuthApiRef);
  authref.getAccessToken().then((token: any) => {
    fetch("https://graph.microsoft.com/beta/me/profile/", {
      headers: {
        "Content-Type": "application/json",
        "ConsistencyLevel": "eventual",
        'Authorization': 'Bearer ' + token
      },
    }).then(response => response.json()).then(data =>
      datadogRum.setUser({
        id: data?.emails ? data?.emails[0]?.address : "",
        name: data?.names ? data?.names[0]?.displayName : "",
        email: data?.emails ? data?.emails[0]?.address : "",
        jobTitle: data?.positions ? data?.positions[0]?.detail?.jobTitle : "",
        department: data?.positions ? `${data?.names[0]?.displayName} - ${data?.positions[0]?.detail?.company?.department}` : "",
        plan: 'premium',
      })
    )
  });
  useEffectOnce(() => {
    if (applicationId && clientToken && applicationId?.toLowerCase() !== 'null') {
      initDataDog(
        applicationId,
        clientToken,
        site,
        env
      )
    }
  });
  return (<Fragment />);
};

import { createExternalRouteRef, createRouteRef } from '@backstage/core-plugin-api';

export const welcomePageRouteRef = createRouteRef({
    id: 'welcome',
});

export const portalOverviewExternalRouteRef = createExternalRouteRef({
    id: 'portal-overview',
});

export const blueprintsExternalRouteRef = createExternalRouteRef({
    id: 'blueprints',
});

export const docsExternalRouteRef = createExternalRouteRef({
    id: 'docs',
});

export const newsExternalRouteRef = createExternalRouteRef({
    id: 'news',
})

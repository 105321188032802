import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    container: {
        position: 'fixed',
        width: '52px',
        height: '52px',
        bottom: theme.spacing(4),
        right: theme.spacing(3),
        transform: 'rotate(-90deg)',
        color: theme.palette.tmnaColors.primary.white,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',

        '&:hover': {
            backgroundColor: theme.palette.tmnaColors.secondary.darkRed,
        }
    },
}));

import React from 'react';
import {
    Typography,
    Box,
    Grid,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {Link} from '../../../Link';
import {useStyles} from './styles';

type Props = {
    icon?: JSX.Element;
    title: string;
    items: {
        to: string;
        title: string;
    }[];
};

export const SupportMultipleLinkCard = ({
    icon,
    title,
    items,
}: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.linkCard}>
            {icon}
            <Box mx={2} flexGrow={1}>
                <Typography variant="h4" paragraph>
                    {title}
                </Typography>
                <Grid container direction="column" spacing={1}>
                    {items.map((item) => (
                        <Grid item>
                            <Link to={item.to} icon={<NavigateNextIcon />} target="_blank">
                                <Typography variant="body2" className={classes.link}>
                                    {item.title}
                                </Typography>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
};

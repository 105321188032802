import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAsyncFn } from 'react-use';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab';
import List from '@material-ui/core/List';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { appCatalogApiRef } from '../../api';
import { useApi } from '@backstage/core-plugin-api';
import { debounce } from 'lodash';

const useStyles = makeStyles(() => ({
  link: {
    color: 'navy',
    textDecoration: 'underline',
  },
  popper: {
    position: 'unset',
    width: 'unset !important',
  },
  option: {
    display: 'table-row',
    cursor: 'unset',
  },
  listbox: {
    padding: 0,
  },
  tag: {
    '& .MuiChip-label': {
      textTransform: 'none',
    },
  },
}));

type Option = {
  name: string;
  repoName: string;
  id: string;
  github_id: number;
  url: string;
};

type PaginateAutocompleteProps = {
  selectedRepos: Option[];
  setSelectedRepos: React.Dispatch<React.SetStateAction<Option[]>>;
};
export const PaginationAutocomplete: FC<PaginateAutocompleteProps> = props => {
  const { selectedRepos, setSelectedRepos } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  //const [selectedValue, setSelectedValue] = useState<Option[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const appCatalogApi = useApi(appCatalogApiRef);

  const [{ value, loading, error }, onSearch] = useAsyncFn(
    async ({ query = '', page = 1, prevList = [] }) => {
      const limit = 100;
      const params = {
        query: query,
        page: page,
        limit: limit,
      };
      const response = await appCatalogApi.searchRepo(params);
      // const response = await fetch(
      //   `http://localhost:8100/repository/search?${params.toString()}`,
      //   {
      //     method: 'GET',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   },
      // );
      //const [responseData, totalLength] = await response.json();
      const { results, total } = response;
      const totalPages = Math.ceil(total / limit);
      const hasMoreData = totalPages > page;
      const returnData = [...prevList, ...results];
      console.log('returnData: ', returnData.length);
      return {
        totalCount: total,
        data: returnData,
        hasMore: hasMoreData,
        currentPage: page,
      };
    },
    [],
  );

  console.log('error: ', error);

  useEffect(() => {
    onSearch({ page: 1, prevList: [] });
  }, [onSearch]);

  const { data, hasMore, currentPage } = useMemo(() => {
    return {
      data: value?.data ?? [],
      hasMore: value?.hasMore ?? false,
      currentPage: value?.currentPage ?? 1,
      totalCount: value?.totalCount ?? 0,
    };
  }, [value?.currentPage, value?.data, value?.hasMore, value?.totalCount]);

  const handleListScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const list = listRef.current;
    const scrollOffset = (event.target as HTMLDivElement).scrollTop;

    if (
      list &&
      list.clientHeight + scrollOffset >= list.scrollHeight - 1000 &&
      hasMore &&
      !loading
    ) {
      onSearch({ page: currentPage + 1, prevList: data });
    }
  };

  const onSearchDebounce = useMemo(
    () => debounce(param => onSearch(param), 300),
    [onSearch],
  );

  const onInputChange = (
    _event: React.ChangeEvent<{}>,
    newValue: string,
    reason: AutocompleteInputChangeReason,
  ): void => {
    if (reason === 'reset') return;
    setInputValue(newValue);
    onSearchDebounce({ query: newValue, page: 1, prevList: [] });
  };

  const ListBoxComponent = useCallback(
    listProps => {
      const children = React.Children.map(listProps.children, child =>
        React.cloneElement(child, { onClick: () => false }),
      );
      return (
        <List {...listProps} ref={listRef} disablePadding>
          <Table stickyHeader>
            <TableHead>
              <TableCell padding='checkbox'/>
              <TableCell>Repository Name</TableCell>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </List>
      );
    },
    [listRef],
  );

  const onCheck = (option: Option, selected: boolean) => {
    if (selected) {
      setSelectedRepos(prevState =>
        prevState.filter(({ github_id }) => github_id !== option.github_id),
      );
    } else {
      setSelectedRepos(prevState => [...prevState, option]);
    }
  };

  const renderOption = useCallback(
    (
      option: Option,
      { selected }: AutocompleteRenderOptionState,
    ): React.ReactNode => (
      <React.Fragment>
        <TableCell padding='checkbox'>
          <Checkbox
            checked={selected}
            onChange={() => onCheck(option, selected)}
          />
        </TableCell>
        <TableCell>
          <Link href={option.url} target="_blank" className={classes.link}>
            {option.repoName}
          </Link>
        </TableCell>
      </React.Fragment>
    ),
    [classes.link],
  );

  return (
    <Autocomplete
      freeSolo
      fullWidth
      disablePortal
      multiple
      open
      loading={loading}
      classes={{
        popper: classes.popper,
        option: classes.option,
        listbox: classes.listbox,
        tag: classes.tag,
      }}
      inputValue={inputValue}
      onInputChange={onInputChange}
      options={data}
      filterOptions={options => options}
      getOptionLabel={option => option.repoName}
      getOptionSelected={(options, selValue) =>
        selValue.github_id === options.github_id
      }
      ListboxProps={{
        ref: listRef,
        onScroll: handleListScroll,
      }}
      value={selectedRepos}
      onChange={(_event, newValue) => setSelectedRepos(newValue as Option[])}
      renderOption={renderOption}
      ListboxComponent={ListBoxComponent}
      renderInput={params => (
        <TextField
          {...params}
          label="Search Repositories"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

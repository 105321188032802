import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  InputLabel,
  Button,
  TableRowProps,
  TableCellProps,
  Icon,
  TableBody,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Content, TableColumn } from '@backstage/core-components';
import { EmptyDataMessage, PageLayout, Select } from 'tmna-ui-kit';
import { Table } from '@backstage/core-components';
import { useStyles } from './styles';
import {
  Components,
  MTableActions,
  MTableBody,
  MTableCell,
  MTableEditField,
} from 'material-table';
import { costCenterApiRef } from '@internal/plugin-cost-center/src/api';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { SelectProps } from 'tmna-ui-kit/lib/inputs/Select/Select';
import { EdgeQuickLinks } from '../components/EdgeQuickLinks/EdgeQuickLinks';
import { ICostEstimateRes, ICostLocationsRes } from 'tmna-types';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import _ from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
import { useLocation } from 'react-router';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import html2canvas from 'html2canvas';
import {
  Document,
  Page,
  View,
  Image,
  Text,
  pdf,
  StyleSheet,
  Font,
  Link,
} from '@react-pdf/renderer';
import ToyotaType from '../../assets/ToyotaType-Regular.ttf';
import dayjs from 'dayjs';

type ITableHeadData = {
  tableRow?: TableRowProps;
  tableCell: TableCellProps[];
}[];

type ICostEstimate = { [key: string]: ICostEstimateRes };

const Links = [
  {
    id: 'estimate-for-aws',
    title: 'Estimate for AWS Cloud Services',
    link: 'https://calculator.aws/#/',
    target: '_blank',
  },
  {
    id: 'datadog-monitoring',
    title: 'Datadog Monitoring Costs',
    link: '/docs/default/component/datadog-tech-docs/onboarding/',
    target: '_blank',
  },
  {
    id: 'nutanix-virtual',
    title: 'Nutanix Virtual Server Cost Estimator',
    link:
      'https://apps.powerapps.com/play/44c0ec46-890c-4427-b6e4-2a943a2f5fd0?tenantid=8c642d1d-d709-47b0-ab10-080af10798fb',
    target: '_blank',
  },
];

Font.register({ family: 'ToyotaType', format: 'truetype', src: ToyotaType });
const styles = StyleSheet.create({
  body: {
    padding: 35,
    fontFamily: 'ToyotaType',
  },
  title: {
    fontSize: 20,
    fontWeight: 800,
    marginBottom: 15,
  },
  description: {
    fontSize: 10,
    marginBottom: 10,
  },
  tableFooter: {
    fontSize: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: '#F7F7F7',
  },
});

const TableHeadData: ITableHeadData = [
  {
    tableCell: [
      {
        colSpan: 3,
        align: 'center',
        children: 'STANDARD INSTANCE CONFIGURATIONS AT TMNA',
      },
      {
        align: 'center',
        children: 'REQUESTED QUANTITY',
      },
      {
        rowSpan: 2,
        align: 'right',
        children: 'COST ($)',
      },
    ],
  },
  {
    tableCell: [
      {
        align: 'left',
        children: 'INSTANCE SIZE',
      },
      {
        align: 'right',
        children: 'VCPU',
      },
      {
        align: 'right',
        children: 'MEMORY (GB)',
      },
      {
        align: 'right',
        children: '# OF INSTANCE(S)',
      },
    ],
  },
];

const commaFormat = new Intl.NumberFormat('en-US');

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const totalFormat = (num = 0) => {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find(x => Math.abs(num) >= x.threshold);
  return found
    ? currencyFormat.format(num / found.threshold) + found.suffix
    : currencyFormat.format(num);
};

const getPdfImage = (divRefCurrent: HTMLElement) =>
  html2canvas(divRefCurrent, {
    scale: 2,
  })
    .then(canvas => canvas.toDataURL())
    .catch(() => null);

const validNumber = new RegExp(/^\d*\.?\d*$/);

const validate = (value: any) =>
  validNumber.test(value)
    ? { isValid: true, helperText: '' }
    : {
        isValid: false,
        helperText: 'Please enter a valid number, greater than 0',
      };

const storValidation = (value: any) =>
  value % 100 === 0
    ? { isValid: true, helperText: '' }
    : { isValid: false, helperText: '' };

const reqQtyColumn = {
  title: 'Request Quantity',
  field: 'reqQuantity',
  validate,
  cellStyle: { width: '25%' },
};

const PDFDocument: FC<{
  imageUrl: string;
  description: string;
  link: string;
}> = ({ imageUrl, description, link }) => {
  const date = dayjs(new Date()).format('DD-MMM-YYYY');

  return (
    <Document>
      <Page style={styles.body}>
        <View>
          <Text style={styles.title}>Chofer Edge Estimates</Text>
          <Text style={styles.description}>
            {description} as of {date}
          </Text>

          <Image src={imageUrl} />
          {/* TODO: this commented code can be used in next iteration */}
          {/* <View style={styles.tableFooter}>
            <Text>
              * Current available capacity is indicative as of {date} based on
              our data and subject to change
            </Text>
          </View> */}

          <Text style={styles.description}>
            You can further explore these estimates yourself using the Chofer
            Edge Estimates tool <Link src={link}>here</Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const FormControlSelect: FC<SelectProps & { className?: string }> = ({
  label,
  className,
  ...rest
}) => {
  return (
    <FormControl fullWidth className={className}>
      <InputLabel>{label ?? ''} </InputLabel>
      <Select label="" {...rest} />
    </FormControl>
  );
};

const RenderHeader: FC<{
  tableHeadData: ITableHeadData;
}> = ({ tableHeadData }) => {
  const renderTableRows = useMemo(
    () =>
      tableHeadData.map(({ tableRow, tableCell }, rowIndex) => (
        <TableRow
          key={`row-${rowIndex}`}
          {...tableRow}
          children={tableCell.map((tableCellProps, cellIndex) => (
            <TableCell
              key={`cell-${rowIndex}-${cellIndex}`}
              {...tableCellProps}
            />
          ))}
        />
      )),
    [tableHeadData],
  );

  return <TableHead children={renderTableRows} />;
};

const EditViewDiv: Components['Cell'] = props => (
  <div
    className="borderDiv"
    children={
      props?.value ? commaFormat.format(props.value) : <span>&nbsp;</span>
    }
  />
);

const TableEditField: Components['EditField'] = props => {
  const onChange = (v: string) => {
    const value = v.replace(/^(?:0+(?=[1-9])|0+(?=0$))/gm, '');
    const { isValid } = props.columnDef.validate(value);
    if (isValid) props.onChange(value);
  };

  return <MTableEditField {...{ ...props, onChange }} />;
};

const CustomEditableCell: Components['Cell'] = props => {
  const classes = useStyles();
  const [cellProps, setCellProps] = useState({
    isEditableCell: false,
    oldValue: props.value,
    value: props.value,
    onEditableCellClick: (isEditableCell: boolean) =>
      setCellProps(prevState => ({
        ...prevState,
        isEditableCell,
        oldValue: prevState.oldValue,
        value: prevState.oldValue,
        ...(isEditableCell === false ? { error: false } : {}),
      })),
    onEditableApproveClick: function onApprove() {
      const currentValue = (this as typeof cellProps).value;
      const isValue = _.isEmpty(currentValue) || isNaN(Number(currentValue));
      const value = !isValue ? currentValue : '';
      setCellProps(prevState => ({
        ...prevState,
        oldValue: value,
        value,
        isEditableCell: false,
      }));
      props.onApprove(value);
    },
    columnDef: props.columnDef,
    onKeyDown: function onKeyDown(e: { keyCode: number }) {
      const copiedThis = this as typeof cellProps;
      const onEditableApproveClick = copiedThis.onEditableApproveClick.bind(
        this,
      );
      const onEditableCellClick = copiedThis.onEditableCellClick.bind(this);
      const error = copiedThis.error;

      if (!error && e.keyCode === 13) {
        onEditableApproveClick();
      } else if (e.keyCode === 27) {
        onEditableCellClick(false);
      }
    },
    onChange: function onChange(value: number) {
      const columnDef = (this as typeof cellProps).columnDef;
      const validation = props?.mainValidation
        ? props.mainValidation
        : columnDef.validate;
      const { isValid, helperText } = validation(value);
      setCellProps(prevState => ({
        ...prevState,
        value,
        error: !isValid,
        helperText,
      }));
    },
    error: false,
    helperText: '',
    disabled: false,
    autoFocus: true,
  });

  const tableActionsProps = useMemo(
    () => ({
      components: props.components,
      size: 'small',
      actions: [
        {
          icon: () => <Icon children={<CheckIcon />} />,
          onClick: () => cellProps.onEditableApproveClick(),
          tooltip: 'Save',
          disabled: cellProps.error,
        },
        {
          icon: () => <Icon children={<ClearIcon />} />,
          onClick: () => cellProps.onEditableCellClick(false),
          tooltip: 'Cancel',
          disabled: false,
        },
      ],
    }),
    [cellProps, props.components],
  );

  useEffect(
    () => props?.showError(cellProps.isEditableCell ? cellProps.error : false),
    [cellProps.error, cellProps.isEditableCell, props],
  );

  return !cellProps.isEditableCell ? (
    <TableCell
      className={clsx(classes.editableCell, 'borderTopGray')}
      onClick={() => cellProps.onEditableCellClick(true)}
      children={<EditViewDiv value={cellProps.oldValue} />}
    />
  ) : (
    <TableCell className={clsx(classes.editableCell, 'borderTopGray')}>
      <div className="customEditContainer">
        <div className="customEditDiv">
          <TableEditField
            {...cellProps}
            onKeyDown={cellProps.onKeyDown.bind(cellProps)}
          />
        </div>
        <MTableActions {...tableActionsProps} />
      </div>
    </TableCell>
  );
};

const LinuxTableRow: FC<{
  totalStorageValue?: number;
  onTotalStorageApprove: (value: number) => void;
  totalStorageCostValue: string;
}> = props => {
  const classes = useStyles();
  const [totalStorError, setTotalStorError] = useState(false);
  const {
    totalStorageValue,
    onTotalStorageApprove,
    totalStorageCostValue,
    ...restProps
  } = props;
  return (
    <TableRow className={classes.totalStorageRow}>
      <TableCell className="borderTopGray" colSpan={3} align="right">
        <b>Total EBS Storage (GB) required</b>
        <br />
        <span className={clsx({ [classes.totalStorageError]: totalStorError })}>
          Specify as multiples of 100
        </span>
        <br />
        Note: Default Capacity of each Linux servers is 400 GB
      </TableCell>
      <CustomEditableCell
        {...restProps}
        showError={setTotalStorError}
        columnDef={{
          ...reqQtyColumn,
        }}
        value={totalStorageValue}
        onApprove={onTotalStorageApprove}
        mainValidation={storValidation}
      />
      <TableCell
        className="borderTopDynamic"
        colSpan={1}
        align="right"
        children={totalStorageCostValue}
      />
    </TableRow>
  );
};

const CustomActions: Components['Actions'] = props => {
  if (props?.reset) props?.actions?.[1]?.onClick();
  return <MTableActions {...props} />;
};

export const EdgeCost = () => {
  const classes = useStyles();
  const location = useLocation();

  const costCenterApi = useApi(costCenterApiRef);
  const alertApi = useApi(alertApiRef);

  const divRef = useRef<HTMLDivElement>(null);

  const [pdfImgPromise, setPdfImgPromise] = useState<Promise<string | null>>(
    Promise.resolve(null),
  );

  const [reqQtyValue, setReqQtyValue] = useState<{
    [key: string]: string | number;
  }>({});

  const [costLocation, setCostLocation] = useState<ICostLocationsRes[]>([]);
  const [costEstimate, setCostEstimate] = useState<Partial<ICostEstimate>>({});
  const [selectValues, setSelectValues] = useState({
    OLValue: '',
    OSValue: '',
  });
  const [triggerCostClick, setTriggerCostClick] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [costBtnLoading, setCostBtnLoading] = useState(false);
  const [pdfBtnLoading, setPdfBtnLoading] = useState(false);

  const [totalStorValues, setTotalStorValues] = useState<number | string>();

  // TODO: Code for share dropdown
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const menuOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const queryParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const isLinux = useMemo(
    () => selectValues.OSValue.toLowerCase() === 'linux',
    [selectValues.OSValue],
  );

  const disableCostBtn = useMemo(
    () => !!!(selectValues.OLValue && selectValues.OSValue),
    [selectValues.OLValue, selectValues.OSValue],
  );

  const message = useMemo(
    () =>
      disableCostBtn
        ? 'Select Outpost Location and Operating System'
        : undefined,
    [disableCostBtn],
  );

  const OLOptions = useMemo(
    () =>
      costLocation?.map(({ id, name }) => ({
        label: name,
        value: id,
      })) ?? [],
    [costLocation],
  );

  const { osOptions: OSOptions, instances: selInstances } = useMemo(() => {
    const selectedItem = costLocation.find(
      ({ id }) => id === selectValues.OLValue,
    );
    const osOptions =
      selectedItem?.opSys?.map(os => ({
        label: os,
        value: os,
      })) ?? [];

    const instances = !disableCostBtn ? selectedItem?.instances ?? [] : [];

    return { osOptions, instances };
  }, [disableCostBtn, costLocation, selectValues.OLValue]);

  const {
    instances: instanceList,
    totalCost: totalCostValue,
    totalStorage: totalStorageValue,
    totalStorageCost: totalStorageCostValue,
  } = useMemo(() => {
    const costEstimateValue = costEstimate[selectValues.OLValue];
    const totalStorage = totalStorValues;
    const instancesCost = costEstimateValue?.instancesCost?.instances ?? [];
    const totalCost =
      costEstimateValue?.hasOwnProperty('totalCost') && !disableCostBtn
        ? totalFormat(costEstimateValue?.totalCost)
        : '';
    const totalStorageCost =
      costEstimateValue?.hasOwnProperty('totalStorageCost') &&
      costEstimateValue?.totalStorageCost !== 0
        ? currencyFormat.format(costEstimateValue?.totalStorageCost)
        : '';

    const getTotalCost = (ids: string) => {
      const data = instancesCost.find(({ id }) => id === ids);
      const isTotalCost = !!(
        data?.hasOwnProperty('totalCost') &&
        !isNaN(data?.totalCost) &&
        data?.totalCost !== 0
      );
      return isTotalCost ? currencyFormat.format(data?.totalCost ?? 0) : '';
    };

    const instances = selInstances.map(inst => ({
      ...inst,
      reqQuantity: reqQtyValue?.[inst.name],
      totalCost: getTotalCost(inst.id),
    }));

    return { instances, totalCost, totalStorage, totalStorageCost };
  }, [
    costEstimate,
    disableCostBtn,
    reqQtyValue,
    selInstances,
    selectValues.OLValue,
    totalStorValues,
  ]);

  const EdgeTableHead = useCallback(
    () => <RenderHeader tableHeadData={TableHeadData} />,
    [],
  );

  const columns: TableColumn<typeof instanceList[0]>[] = useMemo(
    () => [
      {
        title: 'Instance Size',
        field: 'name',
        cellStyle: { width: '20%' },
      },
      {
        title: 'CPU',
        field: 'maxCpu',
        type: 'numeric',
        cellStyle: { width: '11%' },
      },
      {
        title: 'Memory',
        field: 'maxMemory',
        type: 'numeric',
        cellStyle: { width: '19%' },
      },
      { ...reqQtyColumn },
      {
        title: 'Cost',
        field: 'totalCost',
        type: 'numeric',
        cellStyle: { width: '25%' },
      },
    ],
    [],
  );

  const onOLChange: SelectProps['onChange'] = (OLValue: any) => {
    const OSValueOptions =
      costLocation.find(({ id }) => id === OLValue)?.opSys ?? [];

    const OSValue = OSValueOptions.includes(selectValues.OSValue)
      ? selectValues.OSValue
      : OSValueOptions.at(0) ?? '';

    setSelectValues(prevState => ({ ...prevState, OSValue, OLValue }));
    setCostEstimate({});
  };
  const onOSChange: SelectProps['onChange'] = (OSValue: any) => {
    setSelectValues(prevState => ({ ...prevState, OSValue }));
    setCostEstimate({});
  };
  const onTotalStorageApprove = (value: number) => {
    setTotalStorValues(value);
  };

  const getOLValue = useCallback(
    () =>
      OLOptions.find(({ value }) => value === selectValues.OLValue)?.label ??
      '',
    [OLOptions, selectValues.OLValue],
  );

  const getCopyDetails = (): { text: string; url: string } => {
    const copyQuery = {
      os: selectValues.OSValue,
      ol: selectValues.OLValue,
      ...(totalStorValues && isLinux
        ? { eds: totalStorValues.toString() }
        : {}),
    };
    const searchParams = new URLSearchParams(copyQuery);
    instanceList.forEach(
      ({ name, reqQuantity }) =>
        reqQuantity &&
        searchParams.append('instances', `${name}=${reqQuantity}`),
    );
    const text = `Here's a rough estimate for EC2 Outpost for ${
      selectValues.OSValue
    } OS at ${getOLValue()} location`;
    const url = `${window.location.href}?${searchParams.toString()}`;

    return { text, url };
  };

  const dataDogRumAction = useCallback(
    (actionName: string) =>
      datadogRum.addUserAction(actionName, {
        outpost_location: getOLValue(),
        operating_system: selectValues.OSValue,
      }),
    [getOLValue, selectValues.OSValue],
  );

  const onCopyToClipboard = async () => {
    const { text, url } = getCopyDetails();
    const copyText = `${text} : ${url}`;
    try {
      await navigator.clipboard.writeText(copyText);

      // DataDog Copy To Clipboard Click
      dataDogRumAction('copyToClipboardClick');

      alertApi.post({
        message: 'URL copied to clipboard',
        severity: 'success',
        display: 'transient',
      });
    } catch (err) {
      alertApi.post({
        message: 'Something went wrong, please try again!',
        severity: 'error',
        display: 'transient',
      });
    }
  };

  // TODO: Code for share dropdown
  // const onTeamsShare = () => {
  //   const { text, url } = getCopyDetails();
  //   const urlParams = {
  //     msgText: text,
  //     href: url,
  //   };
  //   const searchParams = new URLSearchParams(urlParams);
  //   const shareUrl = new URL('https://teams.microsoft.com/share');
  //   shareUrl.search = searchParams.toString();
  //   window.open(shareUrl, '_blank', 'popup');
  // };

  // const onOutlookShare = () => {
  //   const { text, url } = getCopyDetails();
  //   const urlParams = {
  //     subject: text,
  //     body: `${text} : ${url}`,
  //   };
  //   const searchParams = new URLSearchParams(urlParams);
  //   const shareUrl = new URL(
  //     'https://outlook.office.com/mail/deeplink/compose',
  //   );
  //   shareUrl.search = searchParams.toString();
  //   window.open(shareUrl.toString(), '_blank', 'popup');
  // };

  const onCostClick = useCallback(async () => {
    // DataDog Calculate Cost Click
    dataDogRumAction('calculateCostClick');
    
    const instances = instanceList.map(({ id, reqQuantity }) => ({
      id,
      count: reqQuantity ? Number(reqQuantity) : 0,
    }));
    const totalStorage = totalStorValues ? Number(totalStorValues) : 0;
    const storage = isLinux ? totalStorage : 0;
    const postBody = {
      instances,
      opSys: selectValues.OSValue,
      storage,
    };
    const id = selectValues.OLValue;
    try {
      setCostBtnLoading(true);
      const costLocationsRes = await costCenterApi.fetchCostEstimate(
        id,
        postBody,
      );

      setCostEstimate(prevState => ({ ...prevState, [id]: costLocationsRes }));
    } catch (e) {
      alertApi.post({
        message: 'Something went wrong, please try again!',
        severity: 'error',
        display: 'transient',
      });
    } finally {
      setCostBtnLoading(false);
      setPdfImgPromise(getPdfImage(divRef?.current as HTMLElement));
    }
  }, [
    alertApi,
    costCenterApi,
    dataDogRumAction,
    instanceList,
    isLinux,
    selectValues.OLValue,
    selectValues.OSValue,
    totalStorValues,
  ]);

  const setParamValues = useCallback(
    (costLocationsRes: ICostLocationsRes[]) => {
      const OLParam = queryParams.get('ol');
      const OSParam = queryParams.get('os');
      const edsValue = queryParams.get('eds');
      const instanceParam = queryParams.getAll('instances');
      const instanceArray =
        instanceParam?.map((instance: string) => instance.split('=')) ?? [];
      const instanceValues = Object.fromEntries(instanceArray);
      const OLData = costLocationsRes.find(({ id }) => id === OLParam);
      const OLValue = OLData?.id ?? '';
      const OSValue =
        OSParam && OLData?.opSys?.includes(OSParam) ? OSParam : '';
      const instanceListData = OLData?.instances ?? [];
      const instanceKeys = Object.keys(instanceValues);
      const reqQuantity = instanceListData
        .map(({ name }) =>
          instanceKeys.includes(name) ? { [name]: instanceValues[name] } : null,
        )
        .filter(Boolean);
      setReqQtyValue(Object.assign({}, ...reqQuantity));
      setSelectValues({ OLValue, OSValue });
      if (edsValue) setTotalStorValues(edsValue);
      if (OLValue && OSValue) setTriggerCostClick(true);

      const newUrl = window.location.pathname;
      window.history.replaceState(null, '', newUrl);
    },
    [queryParams],
  );

  const getCostLocations = useCallback(async () => {
    try {
      setListLoading(true);
      const costLocationsRes = await costCenterApi.fetchCostLocations();
      setCostLocation(costLocationsRes);
      setParamValues(costLocationsRes);
    } catch (e) {
      alertApi.post({
        message: 'Something went wrong, please try again!',
        severity: 'error',
        display: 'transient',
      });
    } finally {
      setListLoading(false);
    }
  }, [alertApi, costCenterApi, setParamValues]);

  // TODO: Code for share dropdown
  // const onMenuClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const onMenuClose = () => {
  //   setAnchorEl(null);
  // };

  const generatePDF = async () => {
    setPdfBtnLoading(true);
    pdfImgPromise
      .then(data => data ?? Promise.reject())
      .then(imageUrl => {
        const { text, url } = getCopyDetails();
        return pdf(
          <PDFDocument imageUrl={imageUrl} description={text} link={url} />,
        ).toBlob();
      })
      .then(blob => {
        const dataURL = window.URL.createObjectURL(blob);
        const anchorElement = document.createElement('a');
        anchorElement.href = dataURL;
        anchorElement.setAttribute('download', 'Edge Estimates');
        anchorElement.click();
        // Remove the temporary anchor element
        anchorElement.remove();

        // DataDog Pdf Download Click
        dataDogRumAction('pdfDownloadClick');
      })
      .catch(() =>
        alertApi.post({
          message: 'Something went wrong, please try again!',
          severity: 'error',
          display: 'transient',
        }),
      )
      .finally(() => {
        setPdfBtnLoading(false);
      });
  };

  useEffect(() => {
    getCostLocations();
  }, [getCostLocations]);

  useEffect(() => {
    if (triggerCostClick) {
      onCostClick();
      setTriggerCostClick(false);
    }
  }, [onCostClick, triggerCostClick]);

  return (
    <PageLayout
      title="Chofer Edge"
      subtitle="Estimate costs for hardware capacity (EC2) to support private cloud needs"
      headerBackgroundImg="/assets//welcome_bg.jpg"
      displayButton
    >
      <Content>
        <Grid container>
          <Grid item className={classes.topItem} xs={11} md={8}>
            <Grid container className="topContainer" spacing={1}>
              <Grid item xs={12} md={6}>
                <FormControlSelect
                  className={classes.selectFormControl}
                  onChange={onOLChange}
                  label="Outpost Location :"
                  items={OLOptions}
                  selected={selectValues.OLValue}
                  isLoading={listLoading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlSelect
                  className={classes.selectFormControl}
                  onChange={onOSChange}
                  label="Operating System :"
                  items={OSOptions}
                  selected={selectValues.OSValue}
                  isLoading={listLoading}
                  isDisabled={_.isEmpty(selectValues.OLValue)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.topItem} xs={1} md={4}>
            <Grid container className="topContainer" spacing={1}>
              <Tooltip
                arrow
                title="Copy to clipboard"
                children={
                  <IconButton
                    color="secondary"
                    disabled={_.isEmpty(costEstimate)}
                    onClick={onCopyToClipboard}
                    children={<FileCopyIcon />}
                  />
                }
              />
              <Tooltip
                arrow
                title="Download as PDF"
                children={
                  <IconButton
                    className={classes.downloadIcon}
                    color="secondary"
                    disabled={_.isEmpty(costEstimate) || pdfBtnLoading}
                    onClick={generatePDF}
                    children={<GetAppIcon />}
                  />
                }
              />

              {/* TODO: Code for share dropdown */}
              {/* <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={onMenuClose}
                  >
                    <MenuItem onClick={onCopyToClipboard}>
                      Copy to Clipboard
                    </MenuItem>
                    <MenuItem onClick={onTeamsShare}>Share via Teams</MenuItem>                    
                    <MenuItem onClick={onOutlookShare}>Share to Outlook</MenuItem>
                  </Menu> */}
            </Grid>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={1}>
              <Grid ref={divRef} className={classes.edgeTableDiv} item xs={12}>
                <Table
                  isLoading={listLoading || costBtnLoading}
                  options={{ search: false, toolbar: false, paging: false }}
                  columns={columns}
                  localization={{
                    body: {
                      emptyDataSourceMessage: (
                        <EmptyDataMessage message={message} />
                      ),
                    },
                  }}
                  data={instanceList}
                  cellEditable={{
                    onCellEditApproved: (newValue, _oldValue, rowData) => {
                      const field = rowData.name;
                      const isValue =
                        _.isEmpty(newValue) || isNaN(Number(newValue));
                      setReqQtyValue(prevState => ({
                        ...prevState,
                        [field]: !isValue ? newValue : '',
                      }));
                      return Promise.resolve();
                    },
                    isCellEditable: (_rowData, columnDef) =>
                      columnDef.field === 'reqQuantity',
                  }}
                  components={{
                    Header: () => <EdgeTableHead />,
                    EditField: TableEditField,
                    Actions: props => (
                      <CustomActions {...props} reset={costBtnLoading} />
                    ),

                    Cell: props =>
                      props.cellEditable ? (
                        <TableCell
                          style={{
                            ...props.columnDef.cellStyle,
                          }}
                          className={classes.editableCell}
                          onClick={() =>
                            props.onCellEditStarted(
                              props.rowData,
                              props.columnDef,
                            )
                          }
                          children={<EditViewDiv {...props} />}
                        />
                      ) : (
                        <MTableCell {...props} />
                      ),
                    Body: props => (
                      <>
                        <MTableBody {...props} />

                        <TableBody
                          className={clsx(classes.customMuiTableBody, {
                            even:
                              props.data.length % 2 || props.data.length === 0,
                          })}
                        >
                          {isLinux && (
                            <LinuxTableRow
                              {...{
                                ...props,
                                totalStorageValue,
                                onTotalStorageApprove,
                                totalStorageCostValue,
                              }}
                            />
                          )}
                          {/* TODO: this commented code can be used in next iteration */}
                          {/* <TableRow>
                            <TableCell colSpan={3} align="right">
                              Available Capacity at &lt;&lt;location&gt;&gt;
                            </TableCell>
                            <TableCell align="right">50,000 GB</TableCell>
                            <TableCell colSpan={1} />
                          </TableRow> */}
                        </TableBody>

                        <TableHead className={classes.tableHeaderStyle}>
                          <TableRow>
                            <TableCell
                              className="noBorderRight"
                              align="right"
                              colSpan={4}
                            >
                              <b>
                                Total Monthly Cost
                                <br />
                                <span style={{color:"#003F5F"}}>This estimate only pertains to outpost EC2
                                hardware capacity</span>
                              </b>
                            </TableCell>
                            <TableCell className="noBorderRight" align="right">
                              {totalCostValue}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </>
                    ),
                  }}
                />
              </Grid>
              <Grid container item>
                {/* TODO: this commented code can be used in next iteration */}
                {/* <Grid item xs={8} className={classes.disclaimerText}>
                  <div>* </div> Current available capacity is indicative based
                  on our data and subject to change
                </Grid> */}
                <Grid item xs className={classes.calculateButtonDiv}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    data-testid="calculate-cost-btn"
                    disabled={disableCostBtn || costBtnLoading}
                    className="calculateButton"
                    onClick={() => onCostClick()}
                  >
                    Calculate Cost
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className={classes.quickLinkContainer}>
            <EdgeQuickLinks title="Quick links" links={Links} />
          </Grid>
        </Grid>
      </Content>
    </PageLayout>
  );
};

import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(5, 0),
    },
    item: {
        flex: 1,
        maxWidth: '310px',
    },
    itemRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

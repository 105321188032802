import React from 'react';
import {useAsync} from 'react-use';
import {Grid, Typography,} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/NavigateNext';
import Alert from '@material-ui/lab/Alert';
import {EmptyDataMessage, Link} from 'tmna-ui-kit';
import {Notification} from 'tmna-types';
import {notificationApiRef} from '../../api';
import {notificationRouteRef} from '../../routeRefs';

import {NOTIFICATION_TYPES_MAP} from '../NotificationPage/constants';
import {COLUMNS} from './columns';
import {getRowStyles} from './utils';
import {useStyles} from './styles';

import { identityApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
import { InfoCard, Table } from '@backstage/core-components';

const tableOptions = {
    paging: false,
    search: false,
    sorting: false,
    draggable: false,
    toolbar: false,
    rowStyle: ({read, type}: Notification, index: number) => (
        getRowStyles(NOTIFICATION_TYPES_MAP[type]?.status, index, read)
    ),
};

export const NotificationsWidget = () => {
    const classes = useStyles();
    const notificationsApi = useApi(notificationApiRef);
    const identityApi = useApi(identityApiRef);
    const getNotificationsPageRoute = useRouteRef(notificationRouteRef);

    const { value: notifications, loading: isLoading, error } = useAsync(async () => {
        const response = await notificationsApi.fetchNotifications({
            email: (await identityApi.getProfileInfo()).email,
        });

        return response.data.slice(0, 6);
    }, [notificationsApi, identityApi]);

    const header = React.useMemo(() => (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography variant="h3">
                    Notifications
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    to={getNotificationsPageRoute()}
                    icon={<ArrowIcon color="error" />}
                    spacing={0}
                >
                    <Typography variant="button" component="div">
                        VIEW ALL
                    </Typography>
                </Link>
            </Grid>
        </Grid>
    ), []);

    if (error) {
        return <Alert severity="error">{error.message}</Alert>;
    }

    return (
        <InfoCard
            title={header}
            cardClassName={classes.contentContainer}
            headerStyle={{paddingLeft: '24px'}}
        >
        {notifications?.length!==0 &&
            <Table
                options={tableOptions}
                data={notifications || []}
                columns={COLUMNS}
                isLoading={isLoading}
                style={{boxShadow: 'none', width: '100%'}}
                localization={
                    {
                        body: {
                            emptyDataSourceMessage: <EmptyDataMessage />
                        }
                    }
                }
            />
        }
        </InfoCard>
    );
};

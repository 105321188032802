import {
    configApiRef,
    identityApiRef,
    createApiFactory,
    createPlugin,
    createRoutableExtension,
  } from '@backstage/core-plugin-api';
  
  import { rootRouteRef } from './routes';
  import { InsightsApi, insightsApiRef } from './api';
  
  export const insightsPlugin = createPlugin({
    id: 'insights',
    routes: {
      root: rootRouteRef,
    },
    apis: [
      createApiFactory({
        api: insightsApiRef,
        deps: {
          configApi: configApiRef,
          identityApi: identityApiRef,
        },
        factory: ({ configApi, identityApi }) =>
          new InsightsApi({ identityApi, configApi }),
      }),
    ],
  });
  
  export const InsightsPage = insightsPlugin.provide(
    createRoutableExtension({
      name: 'InsightsPage',
      component: () =>
        import('./components/InsightsWrapperComponent').then(m => m.InsightsWrapperComponent),
      mountPoint: rootRouteRef,
    }), 
  );
  
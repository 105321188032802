import React from 'react';
import {Grid} from '@material-ui/core';
import cn from 'classnames';
import {getLinkTarget} from 'tmna-utils';
import {useStyles} from './styles';

import { Link as BackstageLink } from '@backstage/core-components';

type GridSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type Props = {
    to: string;
    icon?: React.ReactNode;
    iconPosition?: 'left' | 'right';
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    target?: '_blank' | '_self';
    type?: 'text' | 'primary' | 'secondary' | 'tertiary'
    isDisabled?: boolean;
    spacing?: GridSpacing;
    className?: string;
    children: React.ReactNode;
    ref?: React.Ref<any>;
}

export const Link = ({
    to,
    icon,
    iconPosition = 'right',
    onClick,
    target,
    type = 'primary',
    isDisabled,
    spacing = 0,
    className,
    children,
    ref,
}: Props) => {
    const classes = useStyles();

    return (
        <BackstageLink
            to={to}
            ref={ref}
            color="inherit"
            underline="none"
            onClick={onClick}
            className={cn(className, {
                [classes.textLink]: type === 'text',
                [classes.primaryLink]: type === 'primary',
                [classes.secondaryLink]: type === 'secondary',
                [classes.tertiaryLink]: type === 'tertiary',
                [classes.disabled]: isDisabled,
            })}
            target={target ? target : getLinkTarget(to)}
        >
            <Grid
                container
                spacing={spacing}
                alignItems="center"
                wrap="nowrap"
                component="span"
                direction={iconPosition === 'right' ? 'row' : 'row-reverse'}
            >
                <Grid item className={classes.text} component="span">
                    {children}
                </Grid>
                {icon && (
                    <Grid item className={classes.icon} component="span">{icon}</Grid>
                )}
            </Grid>
        </BackstageLink>
    );
};

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    card: {
        minWidth: '25%',
        position: 'relative',
        color: theme.palette.tmnaColors.primary.white,

        '&:hover $overlay': {
            backgroundImage: 'linear-gradient(180deg, rgba(88, 89, 91, 0) 0%, #58595B 100%)',
        }
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundImage: 'linear-gradient(180deg, rgba(26, 33, 34, 0) 0%, #1A2122 100%)',
    },
    image: {
        width: '100%',
        display: 'block',
    },
    content: {
        position: 'absolute',
        bottom: '0px',
        padding: '0 16px 16px',
        width: '100%',
        backgroundImage: 'linear-gradient(to bottom, rgba(39, 39, 39, 0) 1%, rgba(39, 39, 39, 0.7) 72%)',
    },
    title: {
        ...theme.mixins.lineClamp(2),
        marginBottom: theme.spacing(1),
    },
    subtitle: {
        letterSpacing: '1px',
        ...theme.mixins.lineClamp(2),
    },
    icon: {
        color: theme.palette.tmnaColors.primary.red,
        width: '16px',
        height: '16px',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.tmnaColors.primary.black,
        width: '24px',
        height: '24px',
    },
}));


import React from 'react';
import {useAsync} from 'react-use';
import {Box, Grid, Typography} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/NavigateNext';
import {useApi, useRouteRef} from '@backstage/core-plugin-api';
import {Link, ContentGuard} from 'tmna-ui-kit';
import {blogsMainPageRouteRef} from '../../routeRefs';
import {blogsApiRef} from '../../api';
import {BlogCard} from '../BlogCard';
import {useStyles} from './styles';

export const LatestPosts = () => {
    const classes = useStyles();
    const blogMainPageRoute = useRouteRef(blogsMainPageRouteRef)();
    const blogLatestPostApiRef = useApi(blogsApiRef);

    const { value: items, loading, error } = useAsync(async () => {
        return await blogLatestPostApiRef.getLatestPostsMetadata();
    }, []);

    return (
        <ContentGuard isFetching={loading} error={error}>
            {items && items.length > 0 && (
                <>
                    <Box mb={4}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h3">
                                    Latest Posts
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link
                                    to={blogMainPageRoute}
                                    icon={<ArrowIcon />}
                                >
                                    <Typography variant="button">
                                        View all
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={2} wrap="nowrap">
                        {items.map((item) =>
                            <Grid item xs key={item.id}>
                                <BlogCard
                                    metadata={item}
                                    cardClassName={classes.card}
                                />
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </ContentGuard>
    );
};
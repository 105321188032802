import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

const lineClamp = (max: number) => ({
    display: '-webkit-box',
    WebkitLineClamp: max,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
} as const);

export const useStyles = makeStyles<TMNATheme>(theme => ({
    videoCard: {
        display: 'flex',
        width: '100%',
        height: '240px',
        backgroundColor: theme.palette.tmnaColors.secondary.slateGray,
        cursor: 'pointer',
        position: 'relative',
        padding: theme.spacing(2),
        // TODO: avoid important
        paddingBottom: `${theme.spacing(2)}px !important`,

        '&:hover $overlay': {
            backgroundImage: 'linear-gradient(180deg, rgba(88, 89, 91, 0) 0%, #58595B 100%)',
        }
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundImage: 'linear-gradient(180deg, rgba(26, 33, 34, 0) 0%, #1A2122 100%)',
    },
    title: {
        ...lineClamp(2),
    },
    subtitle: {
        ...lineClamp(2),
        letterSpacing: '1px',
    },
    videoSize: {
        width: 800,
        height: 600,
        border: 'none',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        position: 'relative',
        color: theme.palette.tmnaColors.primary.white,
    },
    videocamIcon: {
        color: theme.palette.tmnaColors.primary.red,
        width: '16px',
        height: '16px',
    },
    timeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.tmnaColors.primary.black,
        minWidth: '24px',
        height: '24px',
    },
}));
import { ANNOTATION_ORIGIN_LOCATION, Entity } from '@backstage/catalog-model';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { DialogActions, Button } from '@material-ui/core';
import React, { FC, JSX, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useAsync } from 'react-use';
import { PageLayout } from 'tmna-ui-kit';

export const EntityPageHeader: FC<{
  title: string | JSX.Element;
  headerAdditionalControls?: JSX.Element;
  confirmationDialogOpen: boolean;
  setConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  entity: Entity;
}> = ({
  children,
  title,
  headerAdditionalControls,
  confirmationDialogOpen,
  setConfirmationDialogOpen,
  entity,
}) => {
  const [dialogCloseBtn, setDialogCloseBtn] = useState({
    open: false,
    element: document.body,
  });
  const getMainPageRoute = useRouteRef(catalogPlugin.routes.catalogIndex);
  const catalogApi = useApi(catalogApiRef);
  const locationRef = entity?.metadata?.annotations?.[ANNOTATION_ORIGIN_LOCATION];
  const isBootstrap = locationRef === 'bootstrap:bootstrap';

  const backToLink = React.useMemo(
    () => ({
      label: 'Back to App Catalog',
      to: getMainPageRoute(),
    }),
    [getMainPageRoute],
  );

  const { value: location } = useAsync(async () => {
    const locationPromise = catalogApi.getLocationByRef(locationRef!);
    return locationPromise;
  }, [catalogApi]);

  const showCancelBtn = useMemo(() => {
    switch (true) {
      case isBootstrap || !location:
        return false;
      default:
        return true;
    }
  }, [isBootstrap, location]);

  useEffect(() => {
    if (!confirmationDialogOpen) return;
    setTimeout(() => {
      const childElement = document.getElementById('responsive-dialog-title');
      const parentElement = childElement?.parentNode;

      if (parentElement) {
        // const dialogActionsElements = parentElement.querySelectorAll(
        //   '.MuiDialogActions-root',
        // );
        
        // dialogActionsElements.forEach(element => {
        //   element.style?.display = 'none';
        // });
        setDialogCloseBtn(prevState => ({
          ...prevState,
          open: showCancelBtn,
          element: parentElement as HTMLElement,
        }));
      }
    }, 0);
  }, [confirmationDialogOpen, showCancelBtn]);

  return (
    <PageLayout
      type="catalog"
      title={title}
      headerBackgroundImg="/assets/service-catalog_bg.png"
      backToLink={backToLink}
      headerAdditionalControls={headerAdditionalControls}
      displayButton
    >
      {children}
      {dialogCloseBtn.open &&
        createPortal(
          <DialogActions>
            <Button
              onClick={() => setConfirmationDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>,
          dialogCloseBtn.element,
        )}
    </PageLayout>
  );
};

import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useStyles } from './styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const ErrorCompMessage = ({ errorCompShow, setErrorCompShow }: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState([]);
  React.useEffect(() => {
    setErrorMessages(errorCompShow.errorMsg.split(/\.|\n/));
  }, [errorCompShow]);

  const handleCloseError = (_event?: React.SyntheticEvent) => {
    setOpen(false);
    setErrorCompShow((preVal: any) => ({ ...preVal, isError: false }));
  };
  return (
    <div className={classes.errorMsg}>
      <Snackbar
        open={open}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          style={{ alignItems: 'center', backgroundColor: '#fdebed' }}
        >
          <ul style={{padding:'0px'}}>
            {errorMessages.map((msg: any) => (
              <li style={{ listStyleType: 'none' }}>{msg}</li>
            ))}
          </ul>
        </Alert>
      </Snackbar>
    </div>
  );
};


import { blogsArticlePageRouteRef } from '../routeRefs';

import { useRouteRef } from '@backstage/core-plugin-api';

interface Params {
    articleId: string;
}

export const useBlogLink = (params: Params) => {
    const getArticlePageRoute = useRouteRef(blogsArticlePageRouteRef);

    return getArticlePageRoute({
        articleId: params.articleId
    });
};
import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { createApiFactory, configApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { NewsLetterApi, newsLetterApiRef } from './api';

export const newsLetterPlugin = createPlugin({
  id: 'news-letter',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: newsLetterApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({configApi, identityApi}) =>
          new NewsLetterApi({ configApi, identityApi }),
    }),
  ],
});

export const NewsLetterPage = newsLetterPlugin.provide(
  createRoutableExtension({
    name: 'NewsLetterPage',
    component: () =>
      import('./components/latestnewsletter').then(m => m.LatestNewsLetterComponent),
    mountPoint: rootRouteRef,
  }),
);

//import { AccountInfoAuditHistory } from '../../types';
import { Table } from '@backstage/core-components';
import { TableColumn } from '@backstage/core-components';
import React, { FC, ReactElement } from 'react';
import { FilterField } from '../components/FilterField';

/**Declarations */
type noteProps = {
  rowData: any;
  isLoading:boolean;
};
//const PAGE_SIZE_OPTIONS = [10, 20, 30];
const DEFAULT_VALUE = 'N/A';

const tableColumns: Array<TableColumn<any>> = [
  {
    field: 'name',
    title: 'Name',
    emptyValue: DEFAULT_VALUE,
    filterComponent: props => (
      <FilterField onChange={v => props.onFilterChanged('0', v)} />
    ),
  },
  {
    field: 'comments',
    title: 'TEXT FROM NOTE/COMMENT APPLIED',
    emptyValue: DEFAULT_VALUE,
    filterComponent: props => (
      <FilterField onChange={v => props.onFilterChanged('1', v)} />
    ),
  },
  {
    field: 'date',
    title: 'DATE',
    emptyValue: DEFAULT_VALUE,
    filterComponent: props => (
      <FilterField onChange={v => props.onFilterChanged('2', v)} />
    ),
  }
];

/**View Audit history table */
const NotesTable: FC<noteProps> = ({ rowData,isLoading }): ReactElement => {
    // const customData = rowData && rowData.map((item:any)=>{
    //     return {comments:item.comments,date:'01.01.1111'}
    // })
    const customData = [{name:'N/A',comments:rowData?.comments ? rowData?.comments :"N/A",date:'N/A'}]

  return (
    <Table<any>
      options={{
        paging: true,
        padding: 'dense',
        //pageSize: PAGE_SIZE_OPTIONS[0],
        //pageSizeOptions: PAGE_SIZE_OPTIONS,
        emptyRowsWhenPaging: false,
        //filtering: true,
        search: false,
        toolbar: false,
      }}
      isLoading={isLoading}
      columns={tableColumns}
      data={customData}
    />
  );
};
NotesTable.displayName = 'Notes Table';
export default NotesTable;

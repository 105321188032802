import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>(theme => ({
    container: {
        cursor: 'pointer',
    },
    containerLight: {
        '&:hover': {
            boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.2)',
        },
    },
    containerDark: {
        backgroundColor: theme.palette.tmnaColors.secondary.silverGray,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: 'rgba(196, 196, 196, 0.5)',
        },
    },
    content: {
        padding: theme.spacing(3),
        display: 'flex',
        flexWrap: 'nowrap'
    },
    iconContainer: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.tmnaColors.secondary.lightGray,
        borderRadius: '50%',
        marginRight: theme.spacing(3),
    }
}));

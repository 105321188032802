import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    container: {
        gridArea: 'pageHeader',
    },
    fixedHeight: {
        //height : '195px',

    },
    'container--catalog': {
        backgroundColor: '#E0E0E0',
        backgroundSize: 'cover',
        color: '#ffffff',
    },
    content: {
        minHeight: '142px',
        //height : '170px',
        paddingBottom:'1%',
        paddingTop: theme.spacing(3),
    },
    extendedContent: {
        paddingTop: theme.spacing(4),
    },
    subtitle: {
        marginTop: theme.spacing(1.5),
    },
    additionalControls: {
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
    },
}));

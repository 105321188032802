import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    linkCard: {
        display: 'flex',
        padding: theme.spacing(3),
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.tmnaColors.secondary.lightGray,

        '&:hover': {
            backgroundColor: theme.palette.tmnaColors.secondary.silverGray,
            transition: 'background-color 300ms ease 0s',
        }
    },
    link: {
        //fontWeight: theme.typography.fontWeightBold as any,
    },
}));
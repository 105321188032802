import React from 'react';
import { useStyles } from './styles';
import { MenuItem, Menu } from '@material-ui/core';
import MoodIcon from '@material-ui/icons/Mood';


export const SurveyButton = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [setOpenMsg] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className={classes.MenuIcon}>
      <a className={classes.buttonCustom} onClick={handleClick}>
        <MoodIcon className={classes.filterIcon} /> Submit Feedback
      </a>
      <SurveyMenuOption
        anchorEl={anchorEl}
        handleClose={handleClose}
        setOpenMsg={setOpenMsg}
      />
  
    </div>
  );
};

export const SurveyMenuOption = ({
  anchorEl,
  handleClose,
}: any) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >

      <MenuItem>
        <a
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=HS1kjAnXsEerEAgK8QeY-yU8rf44V9FGpWalO9NJA5NUN1ZSTkhJRTJaQlY0ODFaVExROU1COVFSQSQlQCN0PWcu&origin=Invitation&channel=1"
          onClick={handleClose}
          target="_blank"
        >
          Chofer NPS Survey
        </a>
      </MenuItem>
      <MenuItem>
        <a
          href="https://forms.office.com/pages/responsepage.aspx?id=HS1kjAnXsEerEAgK8QeY-yU8rf44V9FGpWalO9NJA5NUQ1pDUUpQRUtVWTFCS0MzWkIzVEFYOTRLTSQlQCN0PWcu&route=shorturl"
          onClick={handleClose}
          target="_blank"
        >
          Blueprint Feedback Form
        </a>
      </MenuItem>
    </Menu>
  );
};

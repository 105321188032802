import React from 'react';

import Alert from '@material-ui/lab/Alert';
import { Progress } from '@backstage/core-components';

interface Props {
    error?: Error;
    isFetching?: boolean;
    children: React.ReactNode;
}

export const ContentGuard: React.FC<Props> = ({
    isFetching,
    error,
    children
}) => {
    if (isFetching) {
        return <Progress />;
    }

    if (error) {
        return <Alert severity="error">{error.message}</Alert>;
    }

    return <>{children}</>;
}

/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity } from '@backstage/catalog-model';
import {
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import {
  EntityTagFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';

const useStyles = makeStyles<Theme>(theme => ({
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  filterBoxTitle: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold as any,
    flex: 1,
  },
  title: {
    padding: theme.spacing(2, 0),
    fontSize: 12,
  },
  checkbox: {
    padding: theme.spacing(0, 1, 0, 1),
  },
}));

/**
 * The additional results filter in the sidebar.
 */
export const CustomEntityTagPicker = () => {
  const classes = useStyles();

  const { updateFilters, backendEntities, filters } = useEntityList();
  const availableTags = React.useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .flatMap((e: Entity) => e.metadata.tags)
            .filter(Boolean) as string[],
        ),
      ].sort(),
    [backendEntities],
  );

  const onChange = React.useCallback(
    (tags: string[]) => {
      updateFilters({
        tags: tags.length ? new EntityTagFilter(tags) : undefined,
      });
    },
    [updateFilters],
  );

  if (!availableTags.length) return null;

  return (
    <>
      <div className={classes.filterBox}>
        <Typography variant="body2" className={classes.filterBoxTitle}>
          Refine Results
        </Typography>
        <Button
          onClick={() => onChange([])}
          disabled={!filters.tags}
          color="secondary"
        >
          Clear
        </Button>
      </div>
      <Divider />
      <Typography variant="button" component="div" className={classes.title}>
        Tags
      </Typography>
      <List disablePadding dense>
        {availableTags.map(t => {
          const labelId = `checkbox-list-label-${t}`;
          return (
            <ListItem
              key={t}
              dense
              button
              onClick={() =>
                onChange(
                  filters.tags?.values.includes(t)
                    ? filters.tags?.values.filter((s: any) => s !== t)
                    : [...(filters.tags?.values || []), t],
                )
              }
            >
              <Checkbox
                edge="start"
                color="primary"
                checked={!!filters.tags?.values.includes(t)}
                tabIndex={-1}
                disableRipple
                className={classes.checkbox}
                inputProps={{ 'aria-labelledby': labelId }}
              />
              <ListItemText id={labelId} primary={t} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

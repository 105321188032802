import React from 'react';

import {Entity} from '@backstage/catalog-model';
import {Card, CardContent, Grid, makeStyles, Typography} from '@material-ui/core';
import {HorizontalScrollGrid} from 'tmna-ui-kit';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {getTechdocUrl} from './utils';
import { Link } from '@backstage/core-components';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: theme.typography.fontWeightMedium as any,
    },
    itemsContainer: {
        '& > div > div:nth-child(2)': {
            backgroundImage: 'linear-gradient(to left, rgba(4, 24, 30, 0), #04181e)'
        },
        '& > div > div:nth-child(3)': {
            backgroundImage: 'linear-gradient(to right, rgba(4, 24, 30, 0), #04181e)'
        }
    },
    itemContainer: {
        margin: theme.spacing(1),
    },
    item: {
        width: '280px',
        height: '240px',
    },
    itemTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    arrowIcon: {
        marginLeft: theme.spacing(0.5),
    }
}));

type Props = {
    techdocs?: Entity[];
}

export const FeaturedTechDocs = ({
    techdocs,
}: Props) => {
    const classes = useStyles();

    if (!techdocs) {
        return null;
    }

    return (
        <Grid container spacing={4} direction="column" wrap="nowrap">
            <Grid item>
                <Typography variant="h3" className={classes.title}>
                    Featured
                </Typography>
            </Grid>
            <Grid item className={classes.itemsContainer}>
                <HorizontalScrollGrid theme='dark'>
                    {techdocs.map(techdoc => (
                        <Link
                            to={getTechdocUrl(techdoc.metadata.namespace, techdoc.kind, techdoc.metadata.name)}
                            className={classes.itemContainer}
                            underline="none"
                            key={techdoc.metadata.uid}
                        >
                            <Card className={classes.item}>
                                <CardContent>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <Typography variant="h4" className={classes.itemTitle}>
                                                {techdoc.metadata.name}
                                                <NavigateNextIcon
                                                    color="secondary"
                                                    className={classes.arrowIcon}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                {techdoc.metadata.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Link>
                    ))}
                </HorizontalScrollGrid>
            </Grid>
        </Grid>
    );
};

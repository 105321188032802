import React from 'react';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SchoolIcon from '@material-ui/icons/School';
import {
    Typography,
    Grid,
    // Collapse,
    Box,
} from '@material-ui/core';
import {Link} from 'tmna-ui-kit';
// import {TrainingRequestForm} from '../TrainingRequestForm';

import {RoundIcon} from '../RoundIcon';
import {TRAINING_REQUEST_LINK} from '../../constants';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export const TrainingRequest = () => {
    // const [isFormVisible, setIsFormVisible] = React.useState(false);
    // const onButtonClick = React.useCallback(() => (
    //     setIsFormVisible(!isFormVisible)
    // ), [isFormVisible, setIsFormVisible]);

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <RoundIcon icon={<SchoolIcon fontSize="large"/>}/>
                <Box mt={3}>
                    <Typography variant="h3" paragraph>
                        Don't see the training you are looking for?
                    </Typography>
                    <Typography variant="body2">
                        If you can't find the training you need,
                        let the Chofer team know by posting in our discussion board.
                    </Typography>
                </Box>
            </Grid>
            <Box flexGrow={1}/>
            <Grid item>
                <Link
                    to={TRAINING_REQUEST_LINK}
                    target="_blank"
                    icon={<NavigateNextIcon />}
                    spacing={0}
                >
                    <Typography variant="button">
                        REQUEST TRAINING
                    </Typography>
                </Link>
                {/*<div onClick={onButtonClick} className={classes.button}>*/}
                {/*    <Typography variant="button">*/}
                {/*        REQUEST TRAINING*/}
                {/*    </Typography>*/}
                {/*    <ExpandMoreIcon className={cn(classes.buttonIcon, {[classes['buttonIcon--active']]: isFormVisible})} />*/}
                {/*</div>*/}
            </Grid>
            {/*<Collapse in={isFormVisible} timeout="auto" unmountOnExit>*/}
            {/*    <div className={classes.form}>*/}
            {/*        <TrainingRequestForm />*/}
            {/*    </div>*/}
            {/*</Collapse>*/}
        </Grid>
    );
};

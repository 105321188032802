import { createRoutableExtension } from '@backstage/core-plugin-api';
import { platformChargebackApiRef, PlatformChargebackApi } from './api'
import { rootRouteRef } from './routes';
import { createPlugin, createApiFactory, configApiRef, identityApiRef } from '@backstage/core-plugin-api';

export const platformChargebacksPlugin = createPlugin({
  id: 'platform-chargebacks',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: platformChargebackApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({configApi, identityApi}) =>
          new PlatformChargebackApi({ configApi, identityApi }),
    }),
  ],
});

export const PlatformChargebacksPage = platformChargebacksPlugin.provide(
  createRoutableExtension({
    name: 'PlatformChargebacksPage',
    component: () =>
      import('./components/PlatformChargebackPage/PlatformChargebackPage').then(m => m.PlatformChargeback),
    mountPoint: rootRouteRef,
  }),
);

import React from 'react';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import InfoIcon from '@material-ui/icons/Info';
import ReportIcon from '@material-ui/icons/Report';

import {NotificationStatus} from '../../types';
import {useStyles} from './styles';

type Props = {
    status: NotificationStatus;
}

export const NotificationIcon = ({
    status,
}: Props) => {
    const classes = useStyles();

    const icon = React.useMemo(() => {
        switch (status) {
            case NotificationStatus.INFO: {
                return <InfoIcon  color="primary" />
            }
            case NotificationStatus.WARNING: {
                return <ReportIcon className={classes.warning} />
            }
            case NotificationStatus.URGENT: {
                return <ReportProblemIcon color="secondary"  />
            }
            default: return null;
        }
    }, [status]);

    return (
        <span className={classes.container}>
            {icon}
        </span>
    );
};

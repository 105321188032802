import { createRouteRef } from '@backstage/core-plugin-api';

export const blogsMainPageRouteRef = createRouteRef({
    id: 'blogs-main-page',
});

export const blogsArticlePageRouteRef = createRouteRef({
    id: 'blogs-article-page',
    params: ['articleId'],
});

import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles((theme: TMNATheme) => ({
    textContainer: {
        width: '340px',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginRight: theme.spacing(3),
    },
    text: {
        marginBottom: theme.spacing(2),
    },
    cardContainer: {
        margin: 0,
    },
    container: {
        marginRight: theme.spacing(0),
        marginBottom: theme.spacing(2),
    },
    link: {
        color: theme.palette.tmnaColors.primary.white,
    },
}));


import React from 'react';
import {LocationTime} from 'tmna-ui-kit';
import {LOCATIONS} from './constants';

const TIME_INTERVAL_DELAY_MS = 60000;

type Props = {
    items?: {
        label: string;
        timeZone: string;
    }[];
}

export const LocationTimes = ({
    items = LOCATIONS,
}: Props) => {
    const [date, setDate] = React.useState<Date>(new Date());
    const [isTimeSynchronized, setIsTimeSynchronized] = React.useState<boolean>(false);

    React.useEffect(() => {
        const updateCurrentDate = () => {
            setDate(new Date());
        }

        if (!isTimeSynchronized) {
            const secondsDiff = 60 - date.getSeconds();
            const timerId = setTimeout(
                () => {
                    updateCurrentDate();
                    setIsTimeSynchronized(true);
                },
                secondsDiff * 1000
            );
            return () => clearTimeout(timerId);
        } else {
            const timeIntervalId = setInterval(updateCurrentDate, TIME_INTERVAL_DELAY_MS);
            return () => clearInterval(timeIntervalId);
        }
    }, [setDate, isTimeSynchronized, setIsTimeSynchronized]);

    return (
        <>
            {items.map(item => (
                <LocationTime
                    key={item.label}
                    label={item.label}
                    value={date.toLocaleString(
                        'en-US',
                        {
                            hour: 'numeric',
                            minute: 'numeric',
                            timeZone: item.timeZone,
                        }
                    )}
                />
            ))}
        </>
    );
}
import {plugin} from './plugin';

import {
    blogsArticlePageRouteRef,
    blogsMainPageRouteRef,
} from './routeRefs';
import { createRoutableExtension } from '@backstage/core-plugin-api';

export const BlogsMainPage = plugin.provide(
    createRoutableExtension({
        component: () => import('./pages').then(m => m.BlogsMainPage),
        mountPoint: blogsMainPageRouteRef,
    }),
);

export const BlogsArticlePage = plugin.provide(
    createRoutableExtension({
        component: () => import('./pages').then(m => m.BlogsArticlePage),
        mountPoint: blogsArticlePageRouteRef,
    }),
);

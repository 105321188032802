import React, { useCallback } from 'react';
import { Grid, List, ListSubheader, makeStyles } from '@material-ui/core';

import { CatalogResultListItem } from './CatalogResultListItem';
import { PageLayout } from 'tmna-ui-kit';
import {
  //SearchFilter,
  //SearchBar,
  //SearchFilter,
  SearchResult,
  SearchResultPager,
  useSearch,
} from '@backstage/plugin-search-react';
import { Content } from '@backstage/core-components';
import { SearchResultsFilter } from './SearchResultFilter';
import { useSearchParams } from 'react-router-dom';
// import results from './SearchPageResults.json'
import _ from 'lodash';
import clsx from 'clsx';

const useStyles = makeStyles({
  containerClass: {
    outline: 'none!important',
  },
  paddingLeft:{
    paddingLeft:'4rem'
  },
  subHeader:{
    marginLeft:'-4rem',
    fontSize: '1.25rem'
  }
});

const SearchPage = () => {
  const classes = useStyles();
  const searchContext = useSearch();
  let [searchParams, _setSearchParams] = useSearchParams();
  const isQueryEmpty: boolean = searchParams.get('query') === '';
  
  const groupList = useCallback(
    (resultArray: any) =>
      _.chain(resultArray)
        .groupBy(data => data.document.location.split('#').at(0))
        .map(data => {
          const firstDoc = data.at(0).document;
          return { data, title: firstDoc?.parentTitle ?? firstDoc.title };
        })
        .value(),
    [],
  );

 const noResultsFoundView = () => {
    return (
      <div>
      <article>
        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-9">
            <div className="MuiGrid-root jss1000 MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-align-items-xs-flex-start MuiGrid-justify-content-xs-space-around">
              <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6">
                <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-direction-xs-column">
                  <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-true">
                    <h5 className="MuiTypography-root MuiTypography-h5">
                      Sorry, no results were found
                    </h5>
                  </div>
                  <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-true">
                    <p className="MuiTypography-root MuiTypography-body1"></p>
                  </div>
                  <div className="MuiGrid-root jss1001 MuiGrid-item MuiGrid-grid-xs-true"></div>
                </div>
              </div>
              <div className="MuiGrid-root jss1002 MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6">
                <img
                  src="/static/noBuild.1715fa71..svg"
                  alt="no Build"
                  className="jss1003"
                />
              </div>
            </div>
          </div>
        </div>
      </article>
      </div>
    );
  };

  return (
    <PageLayout
      title="Search Chofer"
      subtitle="All content across Chofer"
      headerBackgroundImg="assets/cost_center_bg.png"
    >
      <Content className={classes.containerClass}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <SearchResultsFilter isQueryEmpty={isQueryEmpty} />
          </Grid>
          <Grid item xs={9}>
           {searchContext.term === '' || isQueryEmpty
                    ? noResultsFoundView()
                    : 
            (<><SearchResult>
              {({ results }) => (
              <List >
              {groupList(results).map(({data, title})=>(
                <List className={clsx({[classes.paddingLeft] : data.length > 1})}      
                subheader={
                  data.length > 1 ?
                  <ListSubheader className={classes.subHeader} disableSticky>
                   {title}
                  </ListSubheader>
                  : undefined
                }>                        
                  {data.map(({ type, document }) => {
                        switch (type) {
                          case 'service-catalog':
                            return;
                          default:
                            return (
                              <CatalogResultListItem
                                key={document.location}
                                result={document}
                                singleData={data.length === 1}
                              />
                            );
                        }
                      })}
                </List>))}
                </List>
              )}
            </SearchResult>
            <SearchResultPager /></>)
          }
          </Grid>
        </Grid>
      </Content>
    </PageLayout>
  );
};

export const searchPage = <SearchPage />;

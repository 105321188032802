import React from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';

import {Box, Chip, Grid, Typography} from '@material-ui/core';
import {Author} from 'tmna-types';

import {useStyles} from './styles';
import {BlogCardAvatar} from './BlogCardAvatar';

export interface Props {
    title?: string;
    titleComponent?: React.ReactNode;
    date: string;
    author: Author;
    description?: string;
    tags?: string[];
    className?: string;
    isHoverStylesApplied?: boolean;
    visibleTagsCount?: number;
}

export const BlogCardContent: React.FC<Props> = ({
    title,
    titleComponent,
    date,
    author,
    description,
    tags = [],
    className,
    isHoverStylesApplied = false,
    visibleTagsCount,
}) => {
    const classes = useStyles();
    const visibleTags = Number.isFinite(visibleTagsCount)
        ? tags.slice(0, visibleTagsCount)
        : tags;

    const publishedDate = dayjs(date).format("DD MMM, YYYY")

    return (
        <div className={cn(className, classes.card, {[classes.clickableCard]: isHoverStylesApplied})}>
            <Box mb={2}>
                <Grid container spacing={0} alignItems="center">
                    <Box mr={1}>
                        <BlogCardAvatar
                            icon={author.icon}
                            fullName={author.fullName}
                        />
                    </Box>
                    <Typography variant="body2" className={classes.authorName}>
                        {author.fullName}
                    </Typography>
                </Grid>
            </Box>

            {title && (
                <Typography variant="h4" className={classes.title}>
                    {title}
                </Typography>
            )}
            {titleComponent && (
                <div className={classes.titleComponent}>
                    {titleComponent}
                </div>
            )}

            {description && (
                <Box mt={2} mb={1}>
                    <Typography variant="body1" className={classes.description}>
                        {description}
                    </Typography>
                </Box>
            )}

            <div className={classes.spacing} />

            <Box mt={2}>
                <Typography variant="subtitle2" className={classes.date}>
                    {publishedDate}
                </Typography>
            </Box>

            {tags && tags.length > 0 && (
                <Box mt={3} mb={-1} className={classes.tagsContainer}>
                    {visibleTags.map(tag => (
                        <Chip id={tag} label={tag} key={tag} size="small"/>
                    ))}
                    {Number.isFinite(visibleTagsCount) && tags.length > (visibleTagsCount as number) && (
                        <span className={classes.restTagsCount}>
                            + {tags.length - (visibleTagsCount as number)} more
                        </span>
                    )}
                </Box>
            )}
        </div>
    );
}
